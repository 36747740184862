section.about__container {
    text-align: justify;
    .one_core_value{
        text-align: justify;
        display: flex;
        border-radius: 0;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        flex-direction: column;
        h4{ text-align: center;}
        img{margin: 0 auto;}
    }
    .our_leadership,.one_team_pl{ border-radius: 0;box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;}
    .one_team_pl{border: 1px solid #dce9f3; .team_imag{border-radius: 0;} 
    }
  
}
.modal-content{border-radius: none;}