@import './root';
@import './typography';
@import './variables';
@import './mixins';


.carousel .control-dots .dot{
    height: 4px;
    width: 35px;
    border-radius: 0;
}

.product_pricing{
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    h4{width: 50%; text-align: left;}
    a{width: 50%; text-align: right;}
}
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    content: "";
    background: #0000;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    line-height: 30px;
    text-align: center;
}
.carousel .control-next.control-arrow:before {
    border-left: 0px solid transparent;
    content: "\f105";
    font-family: 'FontAwesome';
}
.carousel .control-prev.control-arrow:before {
    border-right: 0px solid transparent;
    content: "\f104";
    font-family: 'FontAwesome';
}
.carousel.carousel-slider .control-arrow {
    font-size: 40px;
}
.main_menu ul li a.active{color: #ed1556 !important;}
.main_header.fixed_header .main_menu ul li a.active{
    color: #ed1556 !important;
}
.main_menu ul li.product_dropdown_list .sub_menu_dropdown .spanHeadSecond a.active{
    color: #ed1556 !important; 
}
.main-wrap {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    // background-image: linear-gradient(120deg, #ffffff 0%, #ebedee 100%);
    background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
    position: absolute;
    z-index: 99;
    @include sm{
        height: 100vh;
        bottom: auto;
        top: 0;
        &._landingsec + footer {
            display: none;
        }
    }
}
.section {
    padding: 80px 0;
    @include sm{
        padding: 0;
    margin-top: -90px;
    }
}
.content-wrap.error-area {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
h1.extra-ft {
    @include sm{
        width: 16rem;
    }
   
}
.tracking-normal{letter-spacing: 0em;}
.tracking-wide{letter-spacing: 0.025em;}
.tracking-wider{letter-spacing: 0.05em;}
.tracking-widest{letter-spacing: 0.1em;}
.ganla{font-family: siri;color: #161b4e;}
.tracking-widest{font-family: siri; color:#58595b!important;}