// baseurl

$assetPath : "../../public/images";

$xs:0.3rem;
$sm:0.6rem;
$md:1rem;
$xl:2.25rem;
$xxl:4.5rem;

// letter-spacing

//font-family
@font-face {
    font-family: "siri";
    src:url('../fonts/Siri-Regular.otf');
    font-style: normal;
}
$font-family-siri:siri;
@mixin yuvatext{
    margin: 0;
    color: var(--bg-white);
    width: 75%;
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
}
@mixin yuvah4{
    font-family: $font-family-siri;
    background: linear-gradient(to right, #edafa3, #eb8875, #cf23cf, #f5b6f5);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    line-height: 1.2;
    font-size: 35px;
    width: 75%;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
}
@mixin yuvah2{
    font-size: 4.5rem;
    font-family: siri;
    background: linear-gradient(179deg, rgb(255, 255, 255), rgb(241, 131, 232), rgb(224, 42, 248));
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: bold;
    line-height: 1.2;
}
@mixin wrapper{
    width: 100%;
    padding-left: 4%;
    padding-right: 4%;
}
@mixin yuvasecondgradient{
        font-family: $font-family-siri;
        background: linear-gradient(to right, #edafa3, #eb8875, #cf23cf, #f5b6f5);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
}