@mixin sm {
    @media (max-width: 599px) { @content; }
  }
  @mixin md {
    @media (min-width: 600px) and (max-width: 900px) { @content; }
  }
  @mixin lg {
    @media (min-width: 1024px) { @content; }
  }
  @mixin xl {
    @media (min-width: 1280px) { @content; }
  }
  @mixin xxl {
    @media (min-width: 1536px) { @content; }
  }