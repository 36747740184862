:root {
    --main-bg-color: #ed1556;
    --bg-white:#ffffff;
    --bg-primary-dark: #c5063f;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-content: #444;
    --bs-content-dark: #222;
    --bs-content-black: #000;
    --tw-gradient-from:#e7effd;
    --tw-gradient-to:#ffe4e6;

  }