body{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif;
  background: #f9f9f9;
}
.gg-box{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(220px,1fr));
  grid-auto-rows: 200px;
  grid-gap: 8px;
}
.gg-element img{
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.02);
  border-radius: 10px;
}
.gg-element img:hover{
  opacity: 0.98;
}
#gg-screen{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  top:0;
  left: 0;
  display: none;
  background: rgba(255,255,255,0.85);
  z-index: 9999;
  text-align: center;
}
#gg-screen .gg-image{
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
#gg-screen .gg-image img{
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
.gg-bt{
  width: 38px;
  height: 38px;
  background: rgba(255,255,255,0.6);
  color: #222;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  font-size: 25px;
  border: 1px solid rgba(0,0,0,0.05);
  box-sizing: border-box;
  padding-left: 2px;
}
.gg-bt:hover{
  background: rgba(255,255,255,0.8);
  border: 1px solid rgba(0,0,0,0.5);
}
.gg-close{
  position: fixed;
  top:0.5em;
}
.gg-close,.gg-nxt{
  right: 0.5em;
}
.gg-prev{
  left: 0.5em;
}
.gg-prev,.gg-nxt{
  position: fixed;
  bottom: 50%;
}
@media (min-width:478px){
  .gg-element:nth-child(3n+0){
    grid-row-end: span 2;
  }
}
@media(max-width:768px){
    .gg-box{
        grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
        grid-auto-rows: 150px;
        grid-gap: 6px;
    }
}
@media(max-width: 450px){
    .gg-box{
        grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
        grid-auto-rows: 100px;
        grid-gap: 4px;
    }
}
