ul{padding: 0 !important;}
.btn-primary:hover {
    color: #fff;
    background-color: #212529 !important;
    border-color: #212529;
}
.banner img.wd100{border-radius: 0px;}
.logo img{width: 100px;}
.selec_coun_li{
    margin-left: 15px;
}
.search_bar_dropdown::before{     border-bottom:10px solid #ea1957;
    content: ' ';
    width: 0;
    height: 0;
    /* left: 108px; */
    right: 7px;
    position: absolute;
    top: -9px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;}
.search_con_input{
    width: 100%;line-height: 20px;
    background: white;
    padding: 8px 8px;    border: none;
    font-size: 14px;border-radius:0px;
}
.last_li.r_border{border-left: 2px solid #666;
    padding-right: 0px;
    margin-left: 15px;}

body{overflow-x: hidden;background: #f5f5f7 !important;}
 @font-face {
    font-family: "Siri-Light";
    src: url("../fonts/Siri-Light.otf");
    font-weight: 600; 
  }

  
@font-face {
    font-family: "siri";
    src: url("../fonts/Siri-Regular.otf");
    font-weight: normal;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: "siri_semi";
    src: url("../fonts/Siri-SemiBold.otf");
    font-weight: 600; 
  }

 
  
  @font-face {
    font-family: "siri_bold";
    src: url("../fonts/Siri-Bold.otf");
    font-weight: bold; 
  }

  @font-face {
    font-family: "siri_black";
    src: url("../fonts/Siri-Black.otf");
    font-weight: Black; 
  }


* {padding: 0;margin: 0;outline:0;}
.flt{float:left;}
.clr{clear:both;}
ol, ul{margin:0; padding:0;}
a{transition: 0.5s all ease; color: #000 !important;}
a:hover{text-decoration:none !important;transition: 0.5s all ease;}
a:focus{text-decoration:none;transition: 0.5s all ease;}
ul li{list-style:none;}
.ml20 {
    margin-left: 20px !important;
}

body, html {
    height: 100%;
    margin: 0;  
    font-size: 15px;
    line-height: 24px; 
    color: #000;  
	font-family: 'siri', sans-serif !important;
} 

.pr0px{padding-right: 0px;}
.pl0{padding-left: 0px;}
.pl0px{padding-left: 0px;}
.pt0px{padding-top: 0px;}
.pb0px{padding-bottom: 0px;}
.pt5px{padding-top: 5px !important;}
.pt10px{padding-top: 10px !important;}
.pt15px{padding-top: 15px !important;}
.pt20px{padding-top: 20px !important;}
.pt25px{padding-top: 25px !important;}
.pt22px{padding-top: 22px !important;}
.prl5{padding-right: 5px;    padding-left: 5px;}
.pr5px{padding-right: 5px;}
.pl5px{padding-left: 5px;} 
.mt20{margin-top:20px;	}
.mt25{margin-top:25px;	}
.uppercase{text-transform: uppercase;}
.text-uper{text-transform: uppercase !important;}
.center{text-align: center;}
.mt0px{margin-top: 0px !important;}
.mb0px{margin-bottom: 0px !important;} 
.pl0{    padding-left: 0;}
.pr0{    padding-left: 0;}
.pl5{    padding-left: 5px;}
.pr5{    padding-right: 5px;}
.p0{    padding: 0;}
.mb30 {
    margin-bottom: 30px;
}

.mb10 {
    margin-bottom: 10px !important;
}

section {padding:30px 0;}
.mt50{    margin-top: 50px !important;}
.mt30{    margin-top:30px !important;}
.mb50{    margin-bottom: 50px !important;}  
.mb20{margin-bottom: 20px !important;}
img{max-width:100%} 

.full_container {
    /*max-width: 95%;
    width: 1845px; */ 
    max-width: 92%;
    width: 1400px;
    margin: 0 auto;
}
.hot_pro_list.hot_pro_list_home{margin-bottom: 0px !important;padding-bottom: 0px !important;}

/*
.container {
    max-width: 95%;
    width: 1845px;
}

*/

header {
    position: absolute;
    z-index: 99;
    left: 0;padding: 0px 0px;
    width: 100%;background: #fff;
    transition: 0.5s;height: 58px;
}



.selec_coun i{font-size: 22px;color: #666;padding: 10px 0px;cursor: pointer;}
.selec_coun i:hover{ color: #ed1556;}
.search_con_input{
    
}
.selec_coun select {
    background: no-repeat;
    border: none;    color: #ed1556;
    padding-right: 5px;
}
.top_menu .search_icon {
    color: #000;
    margin-left: 10px;
    font-weight: normal;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.product_head_1 .row{    align-items: center;}
.top_menu .search_icon input{    border: none;
    font-size: 15px;    margin-right: 15px;
    padding: 3px 5px;}

.top_menu .search_icon i{color: #ed1556;cursor: pointer;font-size: 20px;}
.main_menu ul li {
    display: inline-block;  
}
.icon-block{margin-left: 10px;    align-items: center;
    display: flex;}
.last_li{    margin-left: 0px;}
.last_li .selec_coun span{    margin-right: 5px;font-size: 16px;color: #ed1556;}
.last_li .selec_coun{ display: flex;
    margin-left: 8px;align-items: center;
    padding: 0px 0px;
    justify-content: space-around;}
.main_menu ul li a {
    text-transform: uppercase;
    color: #58595b !important;   
    font-family: 'siri';
    padding: 0 16px;
    display: block;
    padding-bottom: 14px;
    padding-top: 18px;
    
}
.main_menu ul li.product_dropdown_list .sub_menu_dropdown .spanHeadSecond a{
    color: #000 !important; 
}
.last_li{width:45px;}



.selec_coun select {
    font-size: 14px;cursor: pointer;font-weight: 500;letter-spacing: .5px;
}
.selec_coun{    display: flex;
    align-items: center;
    justify-content: end;}
.selec_coun small {
    font-size: 14px;font-weight: 500;
    letter-spacing: .5px;margin-right: 5px;
}

.top_menu {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: end;
}


.main_menu ul li:last-child a {
    padding-right: 0;   
}

.main_menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.owl-prev {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    left: 20px;
    display: block !important;
    border:0px solid black;
}

.owl-next {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    right: 25px;
    display: block !important;
    border:0px solid black;
}
.tripal-camera .one_detail_content p{font-size: 50px;text-transform: initial !important;}
.owl-prev i, .owl-next i {transform: scale(1,1.2);
    color: #ccc;
    font-size: 60px;
    color: #000;
    font-weight: normal;
    opacity: .5;
    transition: all ease .5;
}

.owl-prev:hover i, .owl-next:hover  i  { opacity: 1; transition: all ease .5;}

.owl-dot {
    width: 12px;
    height: 12px; 
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    border: 1px solid #000;
}

.owl-dot.active { 
    background: #080808; 
}



.owl-dots {
    text-align: center;
}

.banner_slider  .owl-dots { 
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
}


.banner_slider .owl-dot { 
    border: 1px solid #fff;
}
.banner_slider  .owl-dot.active { 
    background: #fff; 
} 
.fa-input {
    font-family: FontAwesome; 
  }

  footer {
    padding: 30px 0px;padding-bottom: 15px;
    background-color: #fff;
    border-top:1px solid #ddd;
}
.carousel .control-dots .dot.selected{background: #ea1957 !important;}
.carousel .control-dots .dot{box-shadow: none !important;background: #fff;}
h4.footer_title {
    font-size: 15px;    font-family: 'siri_semi';
    text-transform: uppercase;
    color: #ea1957;
}

.footer_middle_links li a {
    font-size: 14px;
    color: #383838;
    line-height: 1;font-weight: 500 !important;
    margin-bottom: 6px;
    display: inline-block;
}

.footer_middle_links li {
    line-height: 1.4;
}

.footer-logo a {
    display: block;
    color: #383838;font-weight: 500 !important;
    margin: 5px 0;
}

.footer-logo img {
    margin-bottom: 25px; width: 120px;
}

.bot_sele_country label {
    display: block;
    margin: 0;
    font-size: 14px;
    color: #ea1957;
}

.bot_sele_country {
    display: block;
    margin-top: 5px;
}



.bot_sele_country select {
    border: none;
    width: 58%;
    padding: 5px 7px;
}

.Subscribe p {
    font-size: 14px;line-height: 20px;
    color: #383838;font-weight: 500 !important;
}

.Subscribe form {
    background: #fff;
    border: 1px solid #ccc;
    position: relative;
    display: flex;
    align-items: center;
}
.Subscribe form input{border: none;
    padding: 5px;}

.Subscribe form input[type="text"] {
    display: block;
    width: 100%;
    border: none;
    padding: 10px 15px;
}

.Subscribe form input.btn.fa-input {
    position: absolute;
    top: 1px;
    right: 0px;
    color: #ea1957;
}

.footer_social_links {
    margin-top: 15px;
}

.footer_social_links ul {
    display: flex; 

}

.footer_social_links ul li {
    display: inline-block;
    width: 18%;
}

.footer_social_links ul li a {
    color: #151515;
    padding: 10px 0;
    display: block;
    font-size: 22px;
}
.footer_social_links ul li a:hover i{    color: #ea1957;}
.footer_copyright {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 11px 0;
    margin-top: 35px;
}

.footer_copyright p {
    margin: 0;
    font-size: 13px;font-weight: 500 !important;
}
.ip_content p{font-size: 15px;letter-spacing: .5px;}
.footer_copyright p a {
    color: #383838;
}
.list-decimal{padding-left: 20px;    margin-bottom: 20px;}
.list-decimal li{margin-bottom: 5px;
    font-size: 15px;
    letter-spacing: .5px;}
.ip_content .list li{margin-bottom: 8px;list-style: disc;
    margin-left: 20px;font-size: 15px;letter-spacing: .5px;
    line-height: 20px;}


.footer_desclaimer {
    font-size: 13px;
    color: #696969;
    margin-top: 25px;
}

.footer_desclaimer p {
    margin: 0;font-weight: 500 !important;color: #000;font-size: 13px;
}

.footer_desclaimer p span{ 
    color: #000;
}

.one_sec_box {
    background: #fff !important;
    float: left;
    padding: 36px 20px 0px;
    border-radius: 5px;
    text-align: center;
    transition: 0.5s ease-in !important;
}
.one_sec_box h5 {
    text-transform: uppercase;
    font-size: 18px;
    margin: 5px 0;
}

.one_sec_box h3 {
    font-size: 25px;
}
.one_sec_box:hover a{    border: 1px solid #ea1957;
    background: #ea1957;
    color: #fff !important;}
.one_sec_box a {
    display: block;font-weight: 500;
    width: 165px;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 12px;
    border: 1px solid #000;
    color: #000;
    border-radius: 3px;
}



.one_sec_box h3 {
    font-size: 25px;margin-bottom: 10px;
    font-weight: 600;
    font-family: siri_semi;
}

.one_produ_1{position: relative;overflow: hidden;}
.one_produ_1 .produc_img img{width: 70%;    transform: scale(1);transition: 0.5s; text-align: center;}
.one_produ_1:hover .produc_img img{transform: scale(1.1);transition: 1s;}
.box_img_bttm:nth-child(1) img{padding-bottom: 20px;}
.box_img_bttm {
    padding:15px 8px;position: relative;overflow: hidden;margin-top:10px;padding-bottom: 20px;
}
.one_sec_box img {
    width: 100%;    transform: scale(1);transition: 0.5s;
}
.one_sec_box:hover img {transform: scale(1.1);transition: 1s;}

section.featur_pro_list{padding-top: 0px;}
.featur_pro_list .row .col-md-4:nth-child(2) .one_sec_box{background: #f9f0de;}
.featur_pro_list .row .col-md-4:nth-child(3) .one_sec_box{background: #e8f2ed;}


.one_hot_produ {
    width: 100%;
}

.hot_pro_row .hot_6 {
    padding-right: 0;
    max-width: 49%;
    flex: 0 0 49%;
}

.hot_pro_row .hot_4 {
    max-width: 30%;
    flex: 0 0 30%;
}
.carousel .carousel-status{display: none;}
.carousel.carousel-slider .control-arrow:hover{background: none;}
.hot_pro_row .hot_3 {
    flex: 0 0 21%;
    max-width: 21%;
    padding-left: 0;
}
.hot_pro_row .hot_3 .one_sec_box{height: 46.7%;}
.one_hot_produ .one_hot_img {
    width: 50%;
    float: left;
    text-align: left;
}

.one_hot_img h6 {
    font-size: 16px;
    margin-bottom: 0px;
}

.one_sec_box.one_hot_produ {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 36px 20px;
    background: #e7ebee;
    
}

.one_hot_produ.hot_prod2 {
    background: #f2f2f2;    padding: 45px 20px;
}



.one_host_detila {
    padding: 0 34px;
    width: 50%;
}

.one_hot_img img {
    width: 100%;
}

.hot_prod2 h6 {
    text-align: right;
    padding-top: 20px;
}

.one_hot_power_bank.hot_prod3 {
    padding-bottom: 15px;
    background: #f5e9e2;
    
}



.one_hot_power_bank .box_img_bttm img {
    max-width: 100%;object-fit: contain;
    padding: 16px 35px;    max-height: 458px;
}

.hot_prod4 {
    margin-bottom: 10px;
    background: #f2f2f2;
}
.one_hot_carger.hot_prod5 {
    background: #e7ebee;
}
.one_hot_carger .box_img_bttm {
    margin: 20px 0;
}

.hot_prod1 img {
    width: 72%;
}

.main_menu ul li a:hover {
    color: #ed1556;
}


/* .one_sec_box a:hover {
    background: #000;
    color: #fff;
} */

.footer_middle_links li a:hover {
    color: #ed1556;
}
.detailpage_breadcum .full_container{    display: flex;align-items: center;}
.detailpage_breadcum .full_container .left{width: 50%;float: left;}
.detailpage_breadcum .full_container .right{width: 50%;text-align: right;padding-top: 3px;}
.detailpage_breadcum .full_container .right a:hover{color: #ed1556 !important;}
.detailpage_breadcum .full_container .right a{
    padding: 0px 5px;font-size: 16px;
    margin-left: 5px;color: #000 !important;}
.detailpage_breadcum .full_container .right a:first-child{color: #ed1556 !important;}
.footer-logo a:hover {
    color: #ed1556;
}

.Subscribe form input.btn.fa-input:focus {
    outline: none;
    box-shadow: none;
}

.footer_copyright p a:hover {
    color: #ed1556;
}
.main_header.fixed_header  .main_menu ul li a{padding-bottom:16px;}
.country_list ul li a {padding-bottom: 0 !important;}
.main_header.fixed_header {
    bbox-shadow: 1px 1px 4px 0 #ccc;
    position: fixed !important;
    background: #ffffff;
    top: 0;
    transition: 0.5s;
}

.main_header.fixed_header .logo img {
    max-width: 120px;
}


/* .main_header.fixed_header .top_menu {
    display: none;
} */


.oone_produ_1:hover {transform: translateY(-15px)}
.one_produ_1 {
    margin-bottom:"40px";
     transition: all 0.9s ease;
     position: relative;
    }


.one_produ_1 .produc_img{padding:20px; text-align:center}





header.main_header.adding_header {
    position: fixed;
    top: -150px;
}

h3.title {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'siri_semi';
}

a.view_all_btn {
    text-align: center;
    display: inline-block;
    border: 1px solid #000;
    padding: 5px 10px;
    vertical-align: middle;
    color: #000;text-transform: uppercase;
    border-radius: 5px;
}

a.view_all_btn:hover {
    background:#ea1957;border-color: #ea1957;
    color: #fff !important;
}

a.view_all_btn:hover img{}
a.view_all_btn:hover img {
    filter: brightness(0) invert(1);
}

.view_all_btn img {
    max-width: 20px;
    margin-left: 2px;
    vertical-align: middle;
}

.chat_fixed {
    position: fixed;
    bottom: 4%;
    right: 2%;
    width: 50px;
    z-index: 9999;
}

.chat_fixed a {
    display: block;
}

.chat_fixed a img {
    width: 88%;
}

.chat_fixed a {
    display: block;
    margin-bottom: 12px;
}

header.main_header.inner_header {
    background: #fff;
    position: initial;
}

header.main_header.inner_header .logo {
    margin-top: 42px;
}

header.main_header.inner_header .main_menu { 
   /* padding-bottom: 20px;*/
}
.innerpage_toppadding{margin-top: 56px;}
section.breadcurms {
    background: #eaeaea;
    padding: 10px 0;display: none;
    background: #fff;
} 


.breadcurms ul li {
    display: inline-block;
    color: #ed1556;
}

.breadcurms ul li a {
    display: inline-block;
    color: #707070;
} 

.breadcurms ul li a{}

.breadcurms ul li a:after {
    content: '/';
    padding: 0 2px 0 5px;


    vertical-align: unset;
}

.breadcurms ul li:last-child:after {
    display: none;
}

.page_title h1 {
    text-transform: uppercase;
    margin: 0;
    font-size: 25px;
} 


.product_head_1 {
    padding: 5px 0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0 ;
}

.product_head_1 h4 {
    font-size: 16px;
    letter-spacing: .5px;
    float: left;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: inherit;
}



.product_head_1 .selec_coun select {
    background: #fff;
    padding: 3px 8px;
    border-radius: 5px;
    padding-left: 0;
}

.product_content h1 {
    text-align: center;
    font-size:20px;
    padding: 5px 0;
    margin-bottom: 0;    color: #ed1556;
}
.product_pricing {
    border-radius: 10px;
    overflow: hidden;padding-top: 0px;
    padding: 7px 15px;
}
.product_content{border-top: 1px solid #e0e0e0;}
.one_produ_1:hover .product_pricing a{color: #ed1556 !important;}
.one_produ_1:hover{border-top:4px solid #ed1556 !important;}
.one_produ_1 {
    border-radius:5px;
    overflow: hidden;
    border-top:4px solid #fff;
    background-image: linear-gradient(to top, rgba(223, 233, 243, 0.36) 0%, white 100%);
    padding: 0;}

    .one_produ_1:hover .product_content h1{
        background: #ed1556;
        color: #fff;;
    }
.product_pricing h4 {
    float: left;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    color: #ed1556;
    font-size: 14px;
    padding-top: 6px;
}
.product_pricing a:hover{color: #ed1556;}
.product_pricing a {
    display: inline-block;
    float: right;font-weight: 500;
    color: #000;
    font-size: 13px;
} 
.product_pricing a img {
    margin-right: 4px;
    width: 21px;
}


section.product_list {
    padding-top: 5px;
}

.product_pricing h4:hover {
    color: #000;
}

 
.gallery {
}
.gallery .previews, .gallery .full, .gallery .desc {
float: left;
margin-right: 10px;
}
.gallery .desc, .gallery .full {
width: 300px;
font-size: 12px;
}
.gallery .desc h2 {
margin-top: 0;
}
.gallery .previews a {
display: block;
margin-bottom: 10px;
}
.gallery .full  {
     
    }

     .gallery .full img {
        
        }
.gallery .previews a  {
    border: 2px solid #eee;
    border-radius: 5px;
    height: 60px;
    overflow: hidden;
    margin-right: 4px;
    margin-bottom: 5px;
}
.gallery .previews a:hover, .gallery .previews a.selected {
    border: 2px solid #ed1556;
    border-radius: 5px;
    height:60px;
    overflow: hidden;
}
.gallery .full img:hover {
cursor: pointer;
}
 
#quick_view .modal-dialog {
    max-width: 60%;
}

.quick_vie_img {
    width: 50%;
    float: left;
}

.quick_vie_img {
    width: 57%;
    float: left;
}

.quickbody_content {
    float: left;
    width: 43%;
}

.quick_view_body {
    float: left;
    width: 100%;
    padding: 38px 20px;
    position: relative;
}

.quick_view_body button.close {
    position: absolute;
    right: 16px;justify-content: space-evenly;
    padding-top: 2px;    display: flex;
    background: #000;
    opacity: 1;
    color: #fff;
    font-weight: normal;
    width: 25px;
    height: 25px;
    overflow: hidden;
    border-radius: 50%;
    font-size: 17px;
    top: 6px;
}

.quickbody_content li:before {
    content: '';
    width: 7px;
    height: 7px;
    background: #ed1556;
    position: absolute;
    top: 8px;
    border-radius: 50%;
    left: 0;
}

.quickbody_content li {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
    padding-left: 12px;
    position: relative;
}
.quickbody_content a.view_more {
    font-family: 'siri_semi';
    font-size: 14px;
}
.quickbody_content h4 {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 600;
}

.quickbody_content ul {
    padding-top:0px;
}

.quick_vi_pric {
    margin:5px 0;
    font-size: 20px;
    font-weight: 600;
    color: #ed1556;
}

a.view_more {
    border: 1px solid #000;
    padding: 8px 35px;
    border-radius: 7px;
    color: #000;
    font-weight: 600;
    display: inline-block;
}

.quickbody_content a.view_more {
    margin-top: 20px;
}
.quickbody_content a.view_more:hover{background: #ed1556;border-color: #ed1556;color: #fff !important;}
.gallery .previews a:hover img, .gallery .previews a.selected img {
    width: 100%;
}

.gallery .previews {
    width: 70px;
    max-height: 300px;
    overflow-y: scroll;
    margin-right: 0px;
}
.gallery .previews::-webkit-scrollbar {
    width: 4px;
}
.gallery .previews::-webkit-scrollbar-thumb {
    background: #ed1556;
}

#quick_view .modal-dialog .modal-content {
    border-radius: 10px;
}
.customer_padding{padding: 50px 0px;}
.three_days_list{padding-right: 2%;}
.three_days_list ul{display: flex;
    justify-content: space-between;}
.three_days_list ul li{text-align: center;}
.three_days_list ul li h6{font-size: 22px;    margin-bottom: 0;line-height: 28px;
    margin-top: 8px;}
    .three_days_list ul li span{font-size: 18px;}
.three_days_list ul li:first-child span{margin-left: -4px;}
.gallery .full {
    text-align: center;
}

.one_produ_1 {
    margin-bottom: 40px;
}

.inner_breadcum h4 {
    float: left;
    width: 60%;margin-bottom: 0px;
    font-size: 25px;
    text-transform: capitalize;
}

.inner_breadcum ul li {
    display: inline-block;
}

.inner_breadcum ul li {
    display: inline-block;
    color: #ed1556;
    padding-left: 15px;
}

.inner_breadcum ul li a {
    color: #000;
}

section.page_title.inner_breadcum ul {
    padding: 3px 0;
}

section.page_title.inner_breadcum {
    width: 100%;
    margin-bottom: 30px;    background: #fff;
    padding: 10px 0;    margin-top:58px;
    overflow: hidden;
    border-top: 1px solid #dddddd85;
}

.spectification_details ul{margin-bottom: 0;}
.spectification_title h5{    color: #ed1556;
    font-size:20px;
    text-transform: uppercase;}

    .one_features {
        padding: 42px 0;
    }

    .spectification_details ul li {
        padding-left: 18px;
        display: flex;
        padding-bottom: 10px;
    }
    .summery{width: 100%;margin: 20px 0px;}
    .spectification_details ul li p b{color: #ed1556;font-weight: 500;}
    .spectification_details ul li strong {
        font-family: 'siri';
    display: block;
    font-weight: 500;
    margin-right:2%;
    width: 50%;min-width: 50%;
    }

    .spectification_details ul li p {
        margin: 0;
    }

    .one_features {
        padding: 70px 0;
        float: left;
        width: 100%;
    }

.clear{clear: both;}    
.breaker {
    background: url(../../public/images/breaker.jpg);
    width: 100%;
    height: 10px;
    float: left;
    margin: 15px 0;
    background-size: inherit;
}

.gray{color: #838383;}

.main_header.fixed_header.inner_header .logo img {
    max-width: 180px;
}

.main_header.fixed_header .logo {
    margin-top: 0px !important;
}

.menu_in .row{align-items: center;}
.menu_in .row.head_second_row{align-items: flex-start;}
.detailpage_breadcum{margin-top:56px;margin-bottom: 0px !important;}
.product_details.one .one_detail1 {
    top: 100px;padding-left: 10%;
    left: 0;
    right: 0;align-items: flex-start;
    margin: 0 auto;
    display: flex;
    color: #000;
    text-align: center;
}
.product_details.one .one_detail1 .one_detail_content{width: 50%;position: absolute;
    left: 10%;
    top:80px;}
.product_details.one .one_detail1 .right{    width: 50%;}
.one_detail_content h1{font-size:90px;margin-bottom: 0;color: #1e1f1f;}
.one_detail_content h3{font-size: 45px;
    color: #1e1f1f;
    font-family: 'siri';}


.one_detail_content h4 {
    font-size:35px;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #1e1f1f;
}
.one_detail_content h4 span{     background: #1e1f1f;
    color: #ffffff;
    padding: 0px 10px;}
.one_detail_content p {
    font-size:60px;
    line-height:78px;
}
.one_detail_content p{color: #2d2d2d;text-transform: uppercase;margin-bottom: 0;}



.one_detail_content2 {
    position: absolute;
    top: 5%; 
    width: 100%;
    color: #fff; 
    text-align: center;
}

.one_detail2 {
    position: relative;
}

.one_detail_content2 h4 {
    font-size: 43px;
    font-family: 'siri_semi';
    margin: 0;
}

.one_detail_content2 p {
    font-size: 14px;
}




.one_detail_content3 {
    position: absolute;
    bottom: 10%; 
    width: 100%;
    color: #fff; 
    text-align: center;
}

.one_detail3 {
    position: relative;
}

.one_detail_content3 h4 {
    font-size: 43px;
    font-family: 'siri_semi';
    margin: 0;
}

.one_detail_content3 p {
    font-size: 14px;
}


p.polish {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Siri-Light';
}



.one_detail_content3 {
    position: absolute;
    bottom: 10%; 
    width: 100%;
    color: #fff; 
    text-align: center;
}

.one_detail4 {
    position: relative;
}


.one_detail_content4 {
    position: absolute;
    top: 10%; 
    width: 100%;
    color: #fff; 
    text-align: center;
}

.one_detail_content4 h4 {
    font-size: 43px;
    font-family: 'siri_semi'; 
}

.one_detail_content4 p {
    font-size: 14px;
}


p.polish {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Siri-Light';
}

p.story_p {
    color: #828282;
    text-transform: uppercase;
    letter-spacing: 1px;
}

p.story_p strong {
    color: #fff;
}



.one_detail5 {
    position: relative;
}

/*
.one_detail_content5 {
    position: absolute;
    top: 10%; 
    width: 100%;
    color: #fff; 
    text-align: center;
}*/

.one_detail_content5 h4 {
    font-size: 43px;
    font-family: 'siri_semi'; 
}

.one_detail_content5 p {
    font-size: 14px;
}


p.polish {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Siri-Light';
}
 



.one_detail5 {
    position: relative;
    background: #000;
    color: #ffff;
    padding: 90px 100px;
    text-align: center;
} 

.one_detail_img5 {
    margin: 45px 0;
}

.one_mood h6 {
    text-transform: capitalize;
    font-size: 18px;
    text-align: center;
    font-family: "Siri-Light";
    color: #dc7921;
}

   
.one_detail_img5 .owl-item {
    -webkit-transition: 0.3s;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(0.80);
    -ms-transform: scale(0.80);
    transform: scale(0.80);
}
  

.one_detail_img5 .owl-item.active.center { 
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}



.one_mood {
    filter: blur(1px);
}

.one_detail_img5 .owl-item.active.center .one_mood {
    filter: blur(0px);
}


.one_detail_img5 .owl-prev i, .one_detail_img5  .owl-next i {
    color: #fff;
    opacity: 1;
}


.one_detail_img5 .owl-nav .owl-prev{    left: -26px;}
.one_detail_img5 .owl-nav .owl-next{    right: -26px;}







.one_detail6 {
    position: relative;
}
 

.one_detail_content6 h4 {
    font-size: 43px;
    font-family: 'siri_semi'; 
}

.one_detail_content6 p {
    font-size: 14px;
}

.one_detail7 {
    position: relative;
    background: #000;
    text-align: center;
    color: #fff;
}


.one_detail_content7 {
    position: absolute;
    top: 10%;
    width: 53%;
    color: #fff;
    text-align: center;
    text-align: left;
    left: 7%;
}


.one_detail_content7 h4 {
    font-size: 43px;
    font-family: 'siri_semi'; 
    margin-bottom: 43%;
}

.one_detail_content7 p {
    font-size: 14px;
}





.one_detail8 {
    position: relative;
}


.one_detail_content8 {
    position: absolute;
    top: 10%; 
    width: 100%;
    color: #fff; 
    text-align: center;
}

.one_detail_content8 h4 {
    font-size: 43px;
    font-family: 'siri_semi'; 
}

.one_detail_content8 p {
    font-size: 14px;
}

 
.one_detail_content8a {
    position: absolute;
    bottom: 4%;
    width: 100%;
    color: #fff;
    text-align: center;
} 

.one_detail_content8a .story_p{color: #fff;     font-size: 16px;}

.one_detail9 .col-md-6:first-child {
    padding-right: 0;
}

.one_detail9 .col-md-6:last-child {
    padding-left: 0;
}



.one_detail10 {
    position: relative;
    background: #000;
    text-align: center;
    color: #fff;
}


.one_detail_content8 {
    position: absolute;
    top: 10%;
    width: 100%;
    color: #fff;
    text-align: center; 
    
}


.one_detail_content8 h4 {
    font-size: 43px;
    font-family: 'siri_semi';  
}

.one_detail11 {}

.one_detail11 h4 {
    font-size: 43px;
    font-family: 'siri_semi';
    text-transform: uppercase;  
}

.one_detail_content8 p {
    font-size: 14px;
}


#gallery-wrapper{
      text-align: center;
}
.kSlideshowWrapper, .kSlideshowItemDiv{ 
}
.kThumbnailsInnerWrapper{
    height: 80px;
    width: 100%;
}
.kThumbnailsWrapper{
    width: 100%;
    height: 80px;
    margin: 10px auto 0 auto;
}
.kThumbnailsPage{
    width: 100%;
    text-align: left;
}
.kThumbnailsPage img{
    padding: 1px;
    border: 1px solid black;
    margin: 2px;
    max-width: 80px;
}
.kThumbnailsPage .selectedThumbnail{
    border-color: #aaaaff;
}
.kSlideshowItemDiv img{
    z-index: 400;
}

.one_detail11 {
    text-align: center;
    padding: 50px 0;
    margin-bottom: 50px;
} 

#gallery-wrapper {
    /* height: 400px; */
    width: 100%;
    text-align: center;
    margin: 50px 0 0;
}

.kSlideshowWrapper, .kSlideshowItemDiv {
    width: 100%;
}

.kThumbnailsWrapper {
    position: relative;
    margin: 20px auto 0 !important;
}

.kSlideshowNext {
    width: 35px !important;
    height: 60px !important; 
    top: 40% !important;
    left: initial !important;
    right: 5% !important;
}

 

.kSlideshowPrev {
    width: 35px !important;
    height: 60px !important;
    top: 40% !important;
    left:  5% !important;
    
    
}


.kSlideshowPause {
    display: none;
}

.kThumbnailsWrapper .kThumbnailsNextPage, .kThumbnailsWrapper .kThumbnailsPrevPage {
    display: none;
}

.one_detail11 .carousel {
    margin-bottom: 35px;
}

#custCarousel .carousel-inner img {
    width: 100%;
    height: 100%;
}

#custCarousel .carousel-indicators {
  position: static;
  margin-top:50px;
  height: 0;
} 

#custCarousel .carousel-indicators li img {
    width: 70%;
}

#custCarousel .carousel-indicators > li {
width:100px;
}

#custCarousel .carousel-indicators li img {
  display: block;
  opacity: 0.5;
}

#custCarousel .carousel-indicators li.active img {
  opacity: 1;
}

#custCarousel .carousel-indicators li:hover img {
  opacity: 0.75;
}

#custCarousel  .carousel-item img{ 
    max-width:25%;
  max-height: 500px;
  width: initial;

}

#custCarousel .carousel-indicators > li {
    display: block;
    border: 1px solid #ccc;
    margin: 0 5px !important;
    border-radius: 4px;
    overflow: hidden;
    height: 80px;
    width: 80px;
}

#custCarousel .carousel-indicators > li a {
    display: block; 
    height: 100%;
  }



#custCarousel .carousel-indicators > li {
    margin: 0;
    float: left;
    /* height: initial; */
}
 
#custCarousel .carousel-indicators > li a {
    display: block;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home_screen .logo img {
    /* filter: brightness(0) invert(1); */
}

.location_map_sec {
    margin-top: 15px;
    margin-bottom: 15px;
}

.one_location p {
    color: #767676;
    font-size: 14px;
    margin: 0;
}

.one_location {
    margin-bottom: 20px;
}

.one_location a {
    color: #767676;
}

.location_details_map iframe {
    width: 100%;
    height: 320px;
}

.location_details_map {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border-radius: 15px !important;
    overflow: hidden;
}


.media_cnter{margin-top: 15px;}
.media_cnter ul li {width: 24%;
    border: 1px solid #e0e0e0;
    padding: 20px 10px;    display: inline-block;
    border-radius: 10px;
    margin-left: 0.5%;margin-right: 0.5%;
    margin-bottom: 10px;
    text-align: center;
}
.m_zero{margin: 0px !important;}
.media_cnter ul li h4 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 5px;
    color: #ea1957;
}

.form-group label{font-weight: 500;}
.contact_1.press_media{background: #fff;
    padding: 20px;}
.media_cnter ul li a {
    color: #ed1556;font-size: 15px;font-weight: 500;
}

.section_title h4 {
    color: #ed1556;font-size: 30px;
    text-transform: uppercase;
}
.one_sub_menu .spanHeadSecond.black a{padding-bottom: 0px !important;}
.btn-primary {
    padding: 5px 40px;
    background: #ed1556 !important;
    border-color: #ed1556 !important;
    margin-top: 10px !important;
    text-transform: uppercase;
}

/* .btn-primary:hover {
    padding: 5px 40px;
    background:#c3003b;
    border-color:#c3003b;
    margin-top: 10px;
} */

.submit_tabs a.nav-link.active {
    background: #ed1556;
    border-color: #ed1556;color: #fff !important;
}
.submit_tabs a.nav-link{cursor: pointer;}
.submit_tabs a.nav-link {font-weight: 500;
    border-radius: 10px 10px 0 0;
    border: 1px solid #ccc;
    color: #000;
    text-transform: uppercase;
    padding: 9px 30px;
    border-color: #ccc;
}
.submit_tabs a.nav-link.active {
    background: #ed1556;
}

.submit_tabs ul.nav.nav-pills li.nav-item {
    margin-right: 12px;
}
ol.list-decimal ul li{    margin-bottom: 0;
    font-size: 15px;}
.form-control{box-shadow: none !important;}
.submit_tabs .tab-content {
    border-top: 1px solid #e0e0e0;
    border-radius: 0px;background: #fff;
    padding: 18px 18px;
    margin-top:-1px;
}

span.required {
    color: red;
}

.gray{color: #767676;}

.search_form_dealer select.form-control {
    border-radius: 0;box-shadow: none;font-weight: 500;
}
.one_sub_menu .spanHeadSecond a{padding: 0;}
.search_form_dealer button.btn.btn-primary {
    border-radius: 0;margin-top: 0px !important;
}
.content_righ_team a{padding: 0px !important;}
.one_vendor {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 30px;
}

.one_vendor h5 {
    text-transform: uppercase;
    font-weight: 500;
}

.one_vendor p {
    font-size: 14px;
    color: #000;
}
.one_vendor p:last-child{margin-bottom: 0;}
.one_vendor p strong {
    color: #000;
}

.new_image {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
}

.new_image img {
    width: 100%;
}

.news_content2 small.dates {
    color: #ed1556;
    font-size: 16px;
}

.news_content2 h4 {
    margin: 10px 0;
    font-size: 22px;
    line-height: 1.4;
}

.news_content2 p {
    color: #767676;
}

a.read_more {
    display: inline-block;
    border: 1px solid #000;
    padding: 5px 20px;
    color: #000;
    margin-bottom: 10px;
}

a.read_more:hover {
    color: #ed1556;
    border: 1px solid #ed1556;
}

.one_new_vendor {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.one_new_vendor h5 {
    font-size: 16px;
    color: #ed1556;
}

.one_new_vendor a.read_more {
    border: none;
    padding: 0;margin-bottom: 0px !important;
    text-transform: uppercase;
    font-size: 13px;
}

.one_new_vendor a.read_more i.fa {
    padding-left: 6px;
}

.newdtesila_s {
    color: #6b6b6b;
}

.newdtesila_s {
    height:95px;
    margin: 10px 0;
    overflow: hidden;
}

.new_short_lists {
   /* margin-top: 50px; */
}

.new_short_lists .row .col-md-3:nth-last-child(-n+4) .one_new_vendor{
    margin-bottom: 0;
}
    

.news_content2 {
    min-height: 225px;
}

.new_short_lists {
    /* margin-top: 50px; */
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}


   
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #e0e0e0;
   
  }
   
body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid #e0e0e0;
  
  
}


.scrollbar::-webkit-scrollbar {
    width: 5px;
  }
   
.scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #e0e0e0;
   
  }
   
.scrollbar::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid #e0e0e0;
  
  }

  .listof_news.scrollbar {
    margin: 22px 0;
}


.listof_vendor_sc {
    margin: 15px 0;
}

.listof_vendor_sc h4 {
    font-size: 18px;
}



.listof_vendor {
    margin-top: 15px;
}

.media_cnter.helpline ul li{    width: 19%;    margin-bottom: 15px;}


.media_cnter.helpline {
    width: 33%;
    margin: 0 auto;
}

.media_cnter.helpline ul li {
    width:100%;
    margin-bottom: 15px;
    margin: 20px 4%;
    text-align: center;
}

.media_cnter.helpline ul li img {
    max-width: 40px;
    margin-bottom: 12px;
}


/* about us page*/
.inner_banner {
    position: relative;
    color: #fff;margin-top: 95px;overflow: hidden;
}
.section.about__container,.innerpage_toppadding{text-align: justify;}




.inner_banner_content h2 {
    font-size: 30px;font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.inner_banner_content {
    position: absolute;
    top: 40%;
    text-align: center;
    left: 0;
    right: 0;
}


.inner_banner_content p {
    font-size: 24px;
    font-weight: 500 !important;
}
.one_core_value {
    text-align: center;
    background: #f9f0de;
    border-radius: 30px;
    padding: 35px 20px;
    margin-bottom: 20px;
    height: calc(100% - 20px);
}

.one_core_value img {
    max-width: 45%;
    margin: 22px 0;
}

.core_balue_s {
    margin-top: 20px;
}

.core_balue_s .col-md-4:nth-child(2) .one_core_value{    background: #e8f2ed;}
.core_balue_s .col-md-4:nth-child(3)  .one_core_value{    background: #f6e2e2;}

.our_leadership {
    background: #ebeff2;
    padding: 40px 55px 0 55px;
    border-radius: 20px;
    margin: 20px 0;
}

.our_leadship_content h6 {
    font-size: 16px;
    color: #ed1556;
    margin-bottom: 20px;
}

.our_leadship_content h4 {
    font-size: 37px;
    font-weight: bold;
    margin-bottom: 5px;
}

.one_team_pl {
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 0px;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.team_imag {
    overflow: hidden;
    border-radius: 15px;
}

.team_imag img {
    width: 100%;
}

.team_imag {
    overflow: hidden;
    border-radius: 15px;
    height: 300px;
}

.content_le_team {
    width: 80%;
    float: left;
}

.content_righ_team {
    float: right;
    width: 20%;
    text-align: center;
}

.team_content2 {
    float: left;
    width: 100%;
    margin-top: 15px;
}



.content_le_team h4 {
    font-weight: bold;
    color: #000;
    font-size: 20px;
}

.content_righ_team a {
    display: inline-block;
    width: 35px;
    height: 35px;
    text-align: center;
    background: #ed1556;
    padding: 4px;
    font-size: 20px;
    border-radius: 50%;
    color: #fff;
}

.about_impect_s {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0;
}

.one_team_impect {
    text-align: center;
}



.one_team_impect {
    text-align: center;
    width: 20%;
}


.one_team_impect h6 {
    font-size:32px;
    margin: 5px 0 5px;
    font-weight: bold;
    color: #ed1556;
}



.one_team_impect img {
    max-width: 95px;
    background: white;
    border-radius: 50%;
    padding: 5px;
    object-fit: cover;
}

ul.listing li {
    display: inline-block;
    width: 16%;
    color: #767676;
    position: relative;
    padding-left: 16px;
}

ul.listing {
    margin-bottom: 30px;
}

.content_le_team h6 {
    color: #414141;
}

ul.listing li:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    top: 8px;
    border-radius: 50%;
    background: #ed1556;
    left: 0;
}



.sub_menu_dropdown {
    border-top: 1px solid #cccccc45 !important;
    background: #fff !important;
    padding: 10px 0;
    position: absolute;-webkit-box-shadow: 0 10px 8px 0 rgba(0,0,0,.15);
    box-shadow: 0 10px 8px 0 rgba(0,0,0,.15);
    top: 50px !important;
    width: 100%;
    z-index: 99;
    left: 0;
}

.one_sub_menu .spanHeadSecond.black {
    text-transform: uppercase;
    margin-bottom: 5px;
}

.one_sub_menu a {
    color: #8f8f8f;
}

.one_sub_menu a:hover {
    color: #ed1556;
}

.menu_in .col-lg-10.col-md-10 {
    position: initial;
}

.one_sub_menu {
    text-align: left;
    height: 100%;
    float: left;
}
.row.head_second_row .col-md-3:last-child .one_sub_menu{border-right: none;}
.one_sub_menu::-webkit-scrollbar {
    width: 5px;    background: white;
    border-radius: 20px;
}
.one_sub_menu::-webkit-scrollbar-thumb {
    background-color: #ea1957;
    border-radius: 20px;
}



.one_sub_menu li {
    display: block !important;
    width: 50%;
    float: left;
}

.one_sub_menu li a {
    color: #000 !important;
    font-weight: 500 !important;
    padding: 0 !important;
    text-transform: initial !important;
}

.one_sub_menu li a:hover {
    color: #ed1556 !important;
}
.main_menu ul li.product_dropdown_list .sub_menu_dropdown{opacity: 0;}
.main_menu ul li.product_dropdown_list:hover .sub_menu_dropdown {
    display: block;
    width: 100%;
    margin: 0 auto;opacity: 1;
    left: 0;
    right: 0;
}
.selected_country{color: #ea1957;}
.selected_country a{color: #ea1957 !important;}
.search_bar_dropdown{
    display: block;
    margin: 0 auto;
    right: 4%;
    top: 48px !important;
    width: 31%;
    float: right;    box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
    background: #ea1957;
    padding: 2px;
    border-radius:0px;
    position: absolute;
}
.cum-container-width{width: 70% !important;padding: 0px !important;}
.one_sub_menu ul li a{font-size: 14px !important;    font-family: 'siri';}
.sub_menu_dropdown{display: none;}
.viall_all_head a{ margin-top: 5px;
    color: #ea1957 !important;
    font-size: 14px !important;font-family: 'siri' !important;
    padding: 0px !important;
    border-radius: 5px;}
    .viall_all_head a:hover{color: #ea1957 !important;}
.main_menu ul li a {
    padding-bottom: 15px;font-weight: 400 !important;
    display: block;font-size: 16px;    letter-spacing: .5px;
}

.main_menu ul li a:hover{color: #ed1556 !important;}

.main_header.fixed_header .sub_menu_dropdown {
    top: 80px;
}
#standard-warranty-1year{margin-top: 15px;}
.full-wrap.terms.cancelattion{    margin-top: 20px;}
.full-wrap.terms.cancelattion h5{    margin-bottom:5;    margin-top: 10px;}
.full-wrap.terms.cancelattion p{    margin-bottom: 5px;
    letter-spacing: .5px;
    font-size: 15px;}
.popup_width{max-width:700px}

.teampopup .modal-title{color: #ed1556;}

.modal-header .close:focus {
    outline: none;
}

.profile_team_popup .team_profile {
    display: block;
    border-radius: 7px;
    overflow: hidden;
}

.container .one_detail1:nth-child(3) .one_detail_content{position: absolute;top: 10%;left: 0%;margin: 0 auto;
    right: 0;width: 60%;color: #000;text-align: center;}
.container .one_detail1:nth-child(3) .one_detail_content h1{font-weight: 600;margin-bottom: 0;}
.container .one_detail1:nth-child(3) .one_detail_content p p{    letter-spacing: 0;
    font-size: 19px;font-weight: 600;}
.container .one_detail1:nth-child(3) .one_detail_content ul{      margin-top: 35px;  overflow: hidden;}
.container .one_detail1:nth-child(3) .one_detail_content ul li{width: 25%;float: left;padding: 0px 20px;}
.container .one_detail1:nth-child(3) .one_detail_content ul li h3{ font-size: 26px;
    margin-bottom: 0;
    font-weight: 600;
    color: #038180;
    letter-spacing: 1px;}
.container .one_detail1:nth-child(3) .one_detail_content ul li p{font-weight: 100;line-height: 18px;
    font-size: 14px; margin: 0;}



    .container .one_detail1:nth-child(4) .one_detail_content{position: absolute;top: 10%;left: 0%;margin: 0 auto;
        right: 0;width: 60%;color: #000;text-align: center;}
    .container .one_detail1:nth-child(4) .one_detail_content h1{font-weight: 600;margin-bottom: 0;}
    .container .one_detail1:nth-child(4) .one_detail_content h2{font-size: 28px;margin-bottom: 5px;}
    .container .one_detail1:nth-child(4) .one_detail_content p p{letter-spacing: 0;font-size: 19px;}

.container .one_detail1:nth-child(5) .one_detail_content{    top: 20%;
    left: 8%;
    width: 45%;
    color: #fff;
    text-align: left;}
.container .one_detail1:nth-child(5) .one_detail_content p .beauty_mode h1{color: #36f5c2;font-weight: 600;font-size: 45px;}
.container .one_detail1:nth-child(5) .one_detail_content p .beauty_mode p{letter-spacing: 2px;font-size:18px;}

.container .one_detail1:nth-child(6) .one_detail_content{    text-align: center;left: 40%;top: 18%;}
.container .one_detail1:nth-child(6) .one_detail_content p h1{font-size: 52px;color: #479375;font-weight: 600;margin-bottom: 12px;text-transform: uppercase;line-height: 38px;text-align: left;}
.container .one_detail1:nth-child(6) .one_detail_content p h1 span{font-size: 38px;}
.container .one_detail1:nth-child(6) .one_detail_content p p{letter-spacing: 1px;text-align: left;font-size: 25px;}



.container .one_detail1:nth-child(7) .one_detail_content{    text-align: center;left: 40%;top: 18%;}
.container .one_detail1:nth-child(7) .one_detail_content p h1{font-size: 45px;color: #2a2529;text-transform: uppercase;line-height: 38px;margin-bottom: 12px;}
.container .one_detail1:nth-child(7) .one_detail_content p h1 span{text-transform: capitalize;}
.container .one_detail1:nth-child(7) .one_detail_content p p{line-height:26px;letter-spacing: 0px;text-align: center;font-size: 19px;}

.container .one_detail1:nth-child(8) .one_detail_content{right: 2%;left: auto;top: 25%;}
.container .one_detail1:nth-child(8) .one_detail_content .macro-camera h1{color: #fff;font-size: 45px;margin-bottom: 0;}
.container .one_detail1:nth-child(8) .one_detail_content .macro-camera label{background: #000;padding: 5px 20px;color: #fff;font-size: 18px;}
.container .one_detail1:nth-child(8) .one_detail_content .macro-camera h2{    text-transform: uppercase;font-size: 30px;color: #fff;margin-top: 10px;}


.container .one_detail1:nth-child(9) .one_detail_content{right: 0%;left: 0;top: 8%;margin: 0 auto;width: 100%;}
.container .one_detail1:nth-child(9) .one_detail_content h1{color: #fff;font-size: 48px;margin-bottom: 10px;font-weight: 600;}
.container .one_detail1:nth-child(9) .one_detail_content h3{color: #77f2f7;font-size: 24px;}

.container .one_detail1:nth-child(10) .one_detail_content{right: 0%;left: auto;top: 8%;margin: 0 auto;width: 50%;text-align: left;}
.container .one_detail1:nth-child(10) .one_detail_content p h1{color: #233326;font-size:70px;margin-bottom:0px;font-weight: 600;}
.container .one_detail1:nth-child(10) .one_detail_content p h2{font-size: 28px;font-weight: 600;}
.container .one_detail1:nth-child(10) .one_detail_content p h3{    font-style: italic;color: #5f625e;font-size: 22px;    margin-top: 5px;}

.container .one_detail1:nth-child(11) .one_detail_content{    right: 0%;
    left: 0;
    top: 15%;
    margin: 0 auto;
    width: 100%;
    text-align: center;}
.container .one_detail1:nth-child(11) .one_detail_content p p{     color: #233326;
    font-size: 28px;
    margin-bottom: 0px;
    letter-spacing: 0;
    line-height: 38px;}



.blazepro_section{position: relative;padding: 0px 0;overflow: hidden;}
.blazepro_section img{width: 100%;border-radius:0px;}
.blazepro_section.one .img{height: 560px;}
.blazepro_section .container_one{position: absolute;
    bottom:0px;top:8%;
    right: 0;width: 70%;padding: 0px 0px;
    left: 0;
    margin: 0 auto;}
.blazepro_section .container_one .section-content{    width:243px;
    float: right;    position: absolute;top: 0;
    right: 0%;}
.blazepro_section .container_one .section-content h1{      color:#0d0d0d;  font-size:75px;
        margin-bottom: 0;font-weight: 600;line-height: 70px;}
.blazepro_section .container_one .section-content label{font-size: 22px;
    background: #0d0d0d;
    color: #fff;
    padding: 2px 15px;
    letter-spacing: .5px;
    padding-left: 5px;
    font-family: 'siri';
    margin-bottom: 4px;}
.section-logo{text-align: right;}
.section-logo img{width: 148px;}
.container_two ul li .text.media_tek{text-align: center;top: 25%;}
.container_two.two{    background: #f7ffff;
    margin: 0;
    padding: 60px;}
.blaze_pro_list .section-content.left_block{display: grid;}
.blaze_pro_list{display: flex;}
.blaze_pro_list ul li.pro_one{width: 50%;float: left;}
.blaze_pro_list ul li.pro_two{width: 50%;float: left;}

.container_two ul li{position: relative;padding:5px;}
.container_two ul li .text{position: absolute;
    top: 0;
    padding: 20px;}
.container_two ul li .flex-col{position: relative;display: flex;}
.container_two{    padding: 50px 60px;}

.container_two ul li .flex-col.lighting{margin-top:8px;}
.container_two ul li .flex-col.lighting.lf_f{margin-top:7px;}
.container_two ul li .text h2{  font-family: 'siri_semi';
    font-size: 28px;line-height: 30px;color: #3f3f3f;
    margin: 0;}
    .container_two ul li .text h2 span{font-weight: 500;    font-family: 'siri';}
.container_two ul li .text p{    margin-bottom: 0;color: #3f3f3f;
    font-size: 18px;font-weight: 500;}
.container_two ul li .text.ramplus{top: 24%;}
.container_two ul li .flex-col.lighting .text{top: 35%;}
.container_two ul li .flex-col.lighting .text.lava_blaze{top: 0%;}
.container_two ul li .flex-col.lighting .text.lf{top: 8%;}


.container_three {
    position: absolute;
    top: 84px;width: 70% !important;
    right: 0;
    left: 0;
    margin: 0 auto;
}
.container_three .section-content{text-align: center;}
.container_three .section-content h2{font-size: 75px;
    margin-bottom: 0;line-height: 70px;color: #0d0b0c;
    font-weight: 600;}

.container_three .section-content label{font-size: 25px;
    color: #0d0b0c;
    letter-spacing: .5px;
    font-family: 'siri';
    font-weight: 600;
    line-height: 28px;}

    .container_four {
        position: absolute;
        top: 115px;width: 70% !important;
        right: 0;
        left: 0;
        margin: 0 auto;
    }
    .container_four .section-content {
        text-align: center;
    }
    .container_four .section-content h2 {
        font-size: 58px;color: #0d0b0c;
        margin-bottom: 0;    line-height: 50px;
        font-weight: 600;
    }
    .container_four .section-content label {
        font-size:36px;
        color: #0d0b0c;
        letter-spacing: .5px;
        font-family: 'siri';
        line-height: 28px;
    }

.container_four .section-content p{
    text-align: left;margin-top:20px !important;color: #0d0b0c;margin: 0 auto;}
.container_five{position: relative;
    top: -65px;}
.c-mar-top{    margin-top: 30px;}
.container_five .row .col .gallery-img img{border-radius:25px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}
.five_section{background: #fff;overflow: inherit;display: none;}
.container-fluid.container_six{    position: absolute;
    top:292px;
    right: 0;width: 70%;
    left: 0;
    margin: 0 auto;}
.container_six .text img{    width: 75%;
    right: 0;
    position: absolute;
    top: -100px;}
.container-fluid.container_six h2{    font-size: 80px;
    margin-bottom: 0;color: #0d0b0c;font-weight: 600;
    line-height: 70px;}
.container-fluid.container_six label{    font-size: 29px;
    background: #5f5f5f;    padding: 2px 20px 2px 2px;font-family: 'siri';
    color: #fff;}
    .table-bordered th, .table-bordered td{padding: 5px;}

    .container.container_seven{     position: absolute;
        top: 90px;width: 70%;
        right: 0;
        text-align: center;
        left: 0;
        margin: 0 auto;}
    .container.container_seven h2{     font-size: 80px;
        margin-bottom: 0;
        line-height: 70px;color: #0d0b0c;
        font-weight: 600;}
    .container.container_seven p{    font-size: 21px;color: #0d0b0c;
        line-height: 30px;}
    .container.container_seven img{    margin-top: 90px;
        width: 85%;}
        .container.container_eight .section-content .text img{    width: 97%;
            margin-top: 40px;}
        .container.container_eight{     position: absolute;
            top: 90px;width: 70%;
            right: 0;
            text-align: left;
            left: 0;
            margin: 0 auto;}
        .container.container_eight h2{     font-size: 80px;
            margin-bottom: 0;color: #0d0b0c;
            line-height: 70px;
            font-weight: 600;}
        .container.container_eight h3{      font-size: 44px;
            margin-bottom: 20px;color: #0d0b0c;
            line-height: 23px;
            font-weight: 600;}
        .container.container_eight p{     font-size: 38px;
            margin: 0;color: #0d0b0c;
            line-height: 30px;}
        .container.container_eight p span{background: #002e2a;
            color: #fff;
            padding: 0px 15px;
            padding-left: 5px;
            font-family: 'siri';}
        .container.container_nine .section-content .text img{    width: 82%;
            margin-top: 209px;}
        .container.container_nine h2{    font-size: 183px;
            margin-left: -14px;
            -webkit-text-stroke: 1px #ffffff94;
            -webkit-text-fill-color: transparent;
            line-height: 148px;}
        .container.container_nine{    position: absolute;
            top: 238px;width: 70% !important;
            right: 0;
            text-align: left;
            left: 0;
            margin: 0 auto;
            padding: 0;}
        .container.container_nine h3{     font-size: 80px;
            margin-bottom: 8px;
            line-height: 70px;
            font-weight: 600;
            background: #ffffff;
            display: inline-block;
            color: #86b6ae;
            padding: 0px 5px;}
        .container.container_nine p{    font-size: 64px;
            margin: 0;
            margin-top: 10px;
            color: #fff;
            line-height: 30px;}
.container-fluid.container_ten .section-content .text .center img{    width: 85%;}
.container-fluid.container_ten .section-content .text .text-in{display: flex;justify-content: space-between;}
.container-fluid.container_ten .section-content .text .text-in .right img{width: 285px;}
 .container-fluid.container_ten{    position: absolute;
            top:110px;
            right: 0;
            text-align: left;
            left: 0;width:70%;
            margin: 0 auto;
            padding: 0px;}
.container-fluid.container_ten h2{  font-size: 85px;
    margin-bottom: 8px;text-transform: uppercase;
    line-height: 66px;display: inline-block;
    color: #0d0b0c;}
.container-fluid.container_ten p{ font-size: 48px;
    margin: 0;
    letter-spacing: 2px;
    margin-top: 10px;
    text-transform: uppercase;
    color: #fff;
    background: #0d0b0c;
    line-height: 45px;
    display: table;
    padding: 0px 10px;
    padding-left: 5px;
    font-family: 'siri';}
.container-fluid.container_eleven .section-content .text img{     position: absolute;
    right: 0px;
    top: 5px;
    width: 88%;}
.container-fluid.container_eleven{    position: absolute;
        top:75px;
        right: 0;
        text-align: left;
        left: 0;
        padding: 0;
        width:70%;}
.container-fluid.container_eleven h2{font-size: 65px;
    margin-bottom: 8px;
    line-height: 66px;margin-bottom: 20px;
    display: inline-block;
    color: #0d0b0c;
    text-transform: uppercase;
    font-weight: 600;}
.container-fluid.container_eleven p{     font-size: 40px;
    margin: 0;
    margin-top: 10px;
    color: #fff;
    line-height: 37px;
    text-transform: uppercase;
    display: inline-block;
    padding: 0px 0px;
    letter-spacing: 5px;}
.container-fluid.container_eleven h3{    text-transform: uppercase;
    background: #0d0b0c;
    color: #fff;
    padding: 0px 5px;letter-spacing: 3px;
    margin: 0;
    display: table;
    font-size: 41px;}
.container-fluid.container_eleven h4{     text-transform: uppercase;
    background: #cddcd5;
    color: #0d0b0c;
    padding: 0px 5px;
    font-family: 'siri';
    margin: 0;
    letter-spacing: 3px;
    display: table;
    font-size: 41px;
    margin-top: 5px;}

.prow_detalpage section{padding: 0px;position: relative;overflow: hidden;background: #fff;}

.prow_detalpage section .banner-height{height: 790px;}
.prow_detalpage section.one .right img{width:43%;position: absolute;bottom: 0;right: 0;}
.full-screen .one_detail_content {
    position: absolute;
    top: 10%;
    left: 0%;
    margin: 0 auto;
    right: 0;
    width: 60%;
    color: #0d0b0c;
    text-align: center;
}
.one_detail_img img{width: 100%;}
.full-screen .one_detail_content h1 {
    font-weight: 600;
    margin-bottom: 0;
}
.full-screen .one_detail_content p p {
    letter-spacing: 0;
    font-size: 19px;
    font-weight: 600;
}
.full-screen .one_detail_content ul {
    margin-top: 35px;
    overflow: hidden;
}
.full-screen .one_detail_content ul li {
    width: 25%;
    float: left;
    padding: 0px 20px;
}
.full-screen .one_detail_content ul li h3 {
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 600;
    color: #038180;
    letter-spacing: 1px;
}
.full-screen .one_detail_content ul li p {
    font-weight: 100;
    line-height: 18px;
    font-size: 14px;
    margin: 0;
}

.product_details.tripal-camera .one_detail1 .right{    position: absolute;
    bottom: 87px;
    right: 0;
    text-align: right;}
.product_details.blaze-dezzle .one_detail1{left:10%;
    right: 0;
    margin: 0 auto;
    position: absolute;
    top:0;
    height: 100%;}
.product_details.tripal-camera .one_detail1 .right img{width: 100%;}
.product_details.beauty-mode .one_detail1{position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    margin: 0 auto;
    top:8%;}
.product_details.beauty-mode .one_detail1 .center img{width:80%;position: absolute;
    right: 0;}
.product_details.blaze-dezzle .one_detail1 .center{position: absolute;bottom: 0px;right: 0;text-align: right;}
.product_details.blaze-dezzle .one_detail1 .center img{  width: 100%;
    position: relative;
    right: 0;
    top:385px;}

.product_details.blaze-octa-processor .one_detail1{position: absolute;top:5%;right: 0;text-align: center;left: 0;margin: 0 auto;}

.product_details.blaze-macro-camera .one_detail1{bottom: -30%;position: absolute;right: 0;text-align: right;left: 0;
    margin: 0 auto;height: 100%;}
.product_details.blaze-macro-camera .one_detail1 .center img{width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;}

.product_details.blaze-ram-group .one_detail1{position: absolute;bottom:30px;right: 0;left: 0;margin: 0 auto;}
.product_details.blaze-ram-group .one_detail1 .left img{width:42%;}

.product_details.blaze-clean-ui .one_detail1{    position: absolute;top:20%;right: 0;left: 0;margin: 0 auto;height: 100%;}
.product_details.blaze-clean-ui .one_detail1 .left img{width:100%;margin-left:0px;}

.product_details.blaze-enjoy .one_detail1{position: absolute;top:20%;right: 0;left: 0;margin: 0 auto;}
.product_details.blaze-enjoy .one_detail1 .center img{    width: 100%;
    margin-left: 0px;}

.product_details.blaze-clean-ui .one_detail1 .right{    position: absolute;
        right: 0;
        bottom: 100px;
        text-align: right;}
.product_details.blaze-clean-ui .one_detail1 .right img{width: 75%;}
.tripal-camera .one_detail_content .center img{    margin-top: 40px;}
.tripal-camera .one_detail_content {
    position: absolute;
    top:95px;
    left: 0%;
    margin: 0 auto;
    right: 0;
    color: #0d0b0c;
    text-align: center;
}

.tripal-camera .one_detail_content h3 {
    font-weight: 600;    color: #232322;
    margin-bottom: 0;font-size:90px;
}
.tripal-camera .one_detail_content h3 span{font-weight: 100;}
.tripal-camera .one_detail_content h2 {
    font-size: 65px;color: #232322;
    margin-bottom: 5px;
        font-family: 'siri_semi';
}
.tripal-camera .one_detail_content p p {
    letter-spacing: 0;
    font-size: 23px;
    font-family: 'siri';
}



.beauty-mode .one_detail_content {
    color: #fff;left: 10%;top: 45px;
    text-align:left;position: absolute;
}
.beauty-mode .one_detail_content .beauty_mode h2 {
    color: #3af5c3;
    font-weight: 600;margin-bottom: 0;
    font-size:90px;
}
.beauty-mode .one_detail_content .beauty_mode p {
    color: #fff;letter-spacing: 0px;
    text-transform: capitalize;
    font-family: 'siri';
}


.blaze-dezzle .one_detail_content {
    text-align: center;
    left:34%;position: absolute;
    top:10%;
}
.blaze-dezzle .one_detail_content h2 {
    font-size:90px;
    color: #499374;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    line-height:70px;
    text-align: left;
}
.blaze-dezzle .one_detail_content h2 span {
    font-size:75px;
}
.blaze-dezzle .one_detail_content p {
    letter-spacing: 1px;
    text-align: left;color: #2e3138;
    font-size: 38px;
}
.blaze-macro-camera .one_detail_content {
    text-align: center;
    left: 35%;
    top: -20%;
    position: absolute;
}
.blaze-macro-camera .one_detail_content h2{
    font-size: 90px;
    color: #232322;
    text-transform: uppercase;
    line-height: 80px;
    margin-bottom: 12px;
}
.blaze-macro-camera .one_detail_content h2 span {
    text-transform: capitalize;
}

.blaze-macro-camera .one_detail_content p {
    line-height: 48px;
    letter-spacing: 0px !important;    color: #242424;
    text-align: center;
    font-size: 32px !important;
    margin-top:15px;
}



.blaze-ram-group .one_detail_content {
    right: 0%;
    left: auto;text-align: center;
    position: absolute;
    top: 25%;
}
.blaze-ram-group .one_detail_content .macro-camera h2 {
    color: #fff;
    font-size:90px;
    margin-bottom: 0;
}
.blaze-ram-group .one_detail_content .macro-camera label {
       background: #000;
    padding: 2px 20px;
    color: #fff;
    font-family: 'siri_semi';
    font-size: 45px;
    line-height: 45px;
}
.blaze-ram-group .one_detail_content .macro-camera h2 {
    text-transform: uppercase;
    font-size:45px;
    color: #fff;
    margin-top: 10px;
}



.blaze-octa-processor .one_detail_content h2 {
    color: #e7f3fc;
    font-size: 90px;
    margin-bottom: 0px;
    font-weight: 600;
}
.blaze-octa-processor .one_detail_content h3 {
    color: #74f2fa;
    font-size:45px;
}


.blaze-clean-ui .one_detail_content {
    right: 0%;
    left: auto;
    top: -100px;position: absolute;
    margin: 0 auto;
    width:48%;
    text-align: left;
}
.blaze-clean-ui .one_detail_content h4 {
    color: #233326;
    font-size:90px;
    margin-bottom: 0px;
    font-weight: 600;
}
.blaze-enjoy .one_detail_content p{text-transform: inherit;
    letter-spacing: 0 !important;}
.one_detail1 .one_detail_content p{    font-size:28px;line-height: 30px;
    letter-spacing: 5px;}
.one_detail1 .one_detail_contentp{    text-transform: inherit;
    line-height: 35px;color: #1e2d1f;}
.blaze-clean-ui .one_detail_content h2 {
    font-size: 45px;
    margin-bottom: 0px;
    color: #000;
    font-family: 'siri_semi';
}
.blaze-clean-ui .one_detail_content h3 {
    font-style: italic;
    color: #5f625e;
    font-size: 35px;
    margin-top: 0px;
}


.blaze-enjoy .one_detail_content {
    right: 0%;
    left: 0;
    top: 15%;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}
.blaze-enjoy .one_detail_content p p {
    color: #233326;
    font-size: 28px;
    margin-bottom: 0px;
    letter-spacing: 0;
    line-height: 38px;
}

.pro-img img{width: 854px;}
.proul{text-align: center;}
.proul img{height: 756px;margin-top: 65px;}
.fiftympproduct img{width: 88%;}



.asuper{position: relative;}
.asuper .cum-container-width .d-flexx{display: flex;align-items: center;}
.asuper .container-fluid{position: absolute;top: 40px;margin: 0 auto;left: 0;right: 0;}
.asuper .container-fluid .left-text{text-align: center;margin-top: 40px;width: 50%;}
.asuper .container-fluid .left-text h1{font-size: 55px;font-weight: 600;}
.asuper .container-fluid .left-text img{width: 100%;margin-top: 50px;}
.asuper .container-fluid .right-text img{width: 85%;
    float: right;}


.super-mob-list ul{display: flex;justify-content: space-between;}
.super-mob-list ul li{position: relative;margin: 0px 5px;width: 20%;}
.super-mob-list ul li img{width: 100%;}
.super-mob-list ul li .text{position: absolute;top: 10px;width: 95%;text-align: center;margin: 0 auto;left: 0;right: 0;}
.super-mob-list ul li .text h4{margin-bottom: 0;color: #fff;font-size: 20px;line-height: 24px;min-height: 50px;display: grid;align-items: center;}

.design-prefection{position: relative;padding-bottom: 0px;}
.design-prefection .container-fluid{position: absolute;top: 100px;margin: 0 auto;left: 0;right: 0;}
.design-prefection .container-fluid .cols{display: flex;}
.design-prefection .container-fluid .cols .text{width: 50%;}
.design-prefection .container-fluid .cols .right{width: 50%;}
.design-prefection .container-fluid .cols .text h2{margin-bottom: 0;margin-top: 210px;color: #fff;line-height: 43px;font-weight: 600;line-height: 50px;margin-right: 50px;font-size: 55px;}
.design-prefection .container-fluid .cols img{width: 450px;}
.days-battery{padding: 0px;position: relative;overflow: hidden;}
.days-battery .container-fluid .cols{display: flex;justify-content: space-between;}
.days-battery .container-fluid .cols .left{width: 50%;}
.days-battery .container-fluid{position: absolute;top: 150px;margin: 0 auto;left: 0;right: 0;padding: 0px 70px;}
.days-battery .container-fluid .cols .text{display: flex;}
.days-battery .container-fluid .cols p{color: #03ffe9;font-size: 18px;padding-left: 62px;margin-bottom: 0;margin-top: 12px;letter-spacing: .5px;}
.days-battery .container-fluid .cols .text img{float: left;width: 135px;}
.days-battery .container-fluid .cols .text .right-text{margin-top: 110px;}
.days-battery .container-fluid .cols .text .right-text span{font-size: 240px;color: #fff;line-height: 175px;font-weight: 700;}
.days-battery .container-fluid .cols .text .right-text h2{font-size: 33px;color: #fff;line-height: 30px;margin: 0;font-weight: 700;}
.days-battery .container-fluid .cols .text .right-text h2 span{display: block;font-size: 26px;text-transform: uppercase;font-weight: 600;line-height: 36px;}

.days-battery .container-fluid .pro-img{text-align: center;width: 50%;}
.days-battery .container-fluid .pro-img img{width: 455px;}
.battery-pro-list{    display: flex;
    justify-content: space-between;
    width:70%;margin-top:90px !important;
    margin: 0 auto;}
.battery-pro-list li{    margin-left: 5px;
    margin-right: 5px;}
.battery-pro-list li .text{position: relative;}
.battery-pro-list li .text .poab{    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    padding: 5px;}
.battery-pro-list li .text .poab img{ width:80px;
    margin-bottom: 10px;
    height: 80px;
    margin-top: 5px;}
.battery-pro-list li .text .poab h5{color: #fff;margin-bottom: 0;font-size: 22px;}
.battery-pro-list li .text .poab p{color: #fff;font-size: 18px;}
.military-grade{position: relative;padding: 0px;}
.military-grade .container-fluid{position: absolute;top: 100px;margin: 0 auto;left: 0;right: 0;padding: 0px 70px;}
.military-grade .container-fluid .flex{display: flex;justify-content: flex-start;}
.military-grade .container-fluid .flex .left{width: 50%;}
.military-grade .container-fluid .flex .left h2{font-size: 70px;color: #fff;font-weight: 600;margin-bottom: 20px;}
.military-grade .container-fluid .flex .left p{font-size: 27px;color: #fff;margin-bottom: 0;line-height: 35px;}
.military-grade .container-fluid .flex .right{text-align: right;width: 50%;}
.military-grade .container-fluid .flex .right img{width:100%;}
.military-grade .container-fluid .flex .left p span{background: #fff;color: #000;padding: 0px 5px;}

.auto-call-recording{position: relative;padding: 0px;}
.auto-call-recording .container-fluid .flex{display: flex;}
.auto-call-recording .container-fluid{position: absolute;bottom: 130px;margin: 0 auto;left: 0;right: 0;}
.auto-call-recording .container-fluid .text{width: 50%;text-align: center;padding-right: 40px;}
.auto-call-recording .container-fluid .text h2{font-weight: 600;font-size: 68px;line-height:58px;color: #6d2789;}
.auto-call-recording .container-fluid .text p{margin: 0;font-size:32px;line-height: 36px; color: #c91a67;margin-top: 10px;}
.auto-call-recording .container-fluid .pro{text-align: center;width: 50%;}
.auto-call-recording .container-fluid .pro img{    width: 100%;}

.expand-moory{position: relative;padding: 0px;}
.expand-moory .container-fluid{position: absolute;top:50px;margin: 0 auto;left: 0;right: 0;padding: 0px 70px;}
.expand-moory .container-fluid .text .center img{width: 70%;
    margin-top:10px;}
.expand-moory .container-fluid .text h2{color: #08f7c7;
    font-size: 55px;
    text-transform: uppercase;
    line-height: 50px;
    font-weight: 600;}
.expand-moory .container-fluid .text p{    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 30px;
    margin-top: 12px;}


.bt-support{position: relative;padding: 0px;}
.bt-support .container-fluid{position: absolute;top:100px;margin: 0 auto;left: 0;right: 0;padding: 0px 70px;}
.bt-support .container-fluid .left{text-align: center;}
.bt-support .container-fluid .left img{width: 55%;margin-top: 50px;}
.bt-support .container-fluid .right{padding: 0px 0px 0px 60px;}
.bt-support .container-fluid .right h2{    font-weight: 600;
    font-size: 55px;
    line-height: 55px;
    color: #fff;}
.bt-support .container-fluid .right p{    font-size: 20px;
    color: #fff;margin-top: 30px;
    line-height: 26px;
    margin-bottom:30px;}

.smart-ai-battery{position: relative;padding: 0px;overflow: hidden;}
.smart-ai-battery .container-fluid .flex{display: flex;}
.smart-ai-battery .container-fluid .flex .left_one{    width: 50%;}
.smart-ai-battery .container-fluid .flex .right{    width: 50%;}
.smart-ai-battery .container-fluid{position: absolute;top:0px;margin: 0 auto;left: 0;right: 0;}  
.smart-ai-battery .container-fluid .flex .left .bg-color{     margin-bottom: 10px;    background: #2b3453;
    padding: 30px;
    padding-top: 246px;
    width: 310px;} 
.smart-ai-battery .container-fluid .flex .left .bg-color h2{    margin: 0;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 55px;
    line-height: 50px;
    text-align: center;}
.smart-ai-battery .container-fluid .flex .left h5{    font-size: 34px;
    background: #141e2b;
    color: #fff;
    padding: 0px 10px;
    display: initial;}
.smart-ai-battery .container-fluid .flex .left p{    margin: 0;
    font-size: 34px;
    margin-top: 10px;}
.smart-ai-battery .container-fluid .right img{ margin-top: 50px;}



.athreesuper{position: relative;padding: 0px;}
.athreesuper .container-fluid .d-flexx{display: flex;align-items: center;}
.athreesuper .container-fluid{position: absolute;bottom: 35px;margin: 0 auto;left: 0;right: 0;align-items: center;}
.athreesuper .container-fluid .left-text{text-align: center;margin-top: 40px;width: 50%;}
.athreesuper .container-fluid .left-text h1{font-size:72px;font-weight: 600;color: #fff;}
.athreesuper .container-fluid .left-text img{width:100%;margin-top: 25px;}
.athreesuper .container-fluid .left-text{text-align: center;}
.athreesuper .container-fluid .right-text{width: 50%;}
.athreesuper .container-fluid .right-text img{width:100%;}
.athreesuper .container-fluid .left-text{    margin-top: 100px;}

.music-lovers{position: relative;padding: 0px;}
.music-lovers .container-fluid{position: absolute;bottom: 0px;margin: 0 auto;left: 0;right: 0;align-items: center;}
.music-lovers .container-fluid .music-lovers-in .text h2{font-size:75px;font-weight: 600;color: #fff;margin-bottom: 0;line-height: 75px;}
.music-lovers .container-fluid .music-lovers-in .text img{width: 80%;margin-top: 50px;}
.music-lovers .container-fluid .music-lovers-in .text p{background: linear-gradient(45deg, #ffffff, #881e83);padding-right: 122px !important;color: #000;font-size: 32px;letter-spacing: 1px;text-transform: uppercase;padding: 4px;display: inline-block;}
.music-lovers .container-fluid .music-lovers-in .pro img{width: 100%;margin-top:100px;}


.big-speaker{position: relative;padding: 0px;}
.big-speaker .container-fluid{position: absolute;bottom: 0px;margin: 0 auto;left: 0;right: 0;align-items: center;}
.big-speaker .container-fluid .big-speaker-in .text{text-align: center;float: right;}
.big-speaker .container-fluid .big-speaker-in .text h2{font-size:90px;font-weight: 600;color: #162232;margin-bottom: 0;}
.big-speaker .container-fluid .big-speaker-in .text p{font-size:48px;line-height:58px;color: #575758;}
.big-speaker .container-fluid .big-speaker-in .text img{width: 80%;margin-top: 25px;}
.big-speaker .container-fluid .big-speaker-in .text{text-align: center;}
.big-speaker .container-fluid .big-speaker-in .pro img{width:85%;margin-top: 40px;}


.battery-life{position: relative;padding: 0px;}
.battery-life .container-fluid{position: absolute;bottom: 0px;margin: 0 auto;left: 0;right: 0;align-items: center;}
.battery-life .container-fluid .battery-life-in{padding: 0px 70px;}
.battery-life .container-fluid .battery-life-in .text{text-align: center;width: 65%;float: right;}
.battery-life .container-fluid .battery-life-in .text h1{font-size: 55px;font-weight: 600;color: #162232;margin-bottom: 0;}
.battery-life .container-fluid .battery-life-in .text p{font-size: 30px;margin-bottom: 0;color: #575758;}
.battery-life .container-fluid .battery-life-in .text img{width: 80%;margin-top: 25px;}
.battery-life .container-fluid .battery-life-in .text{text-align: center;}
.battery-life .container-fluid .battery-life-in .pro img{width: 72%;}


.btsuper-ai-battery{position: relative;padding: 0px;}
.btsuper-ai-battery .container-fluid{position: absolute;bottom: 0px;margin: 0 auto;left: 0;right: 0;align-items: center;}
.btsuper-ai-battery .container-fluid .battery-life-in .text h2{font-size:90px;font-weight: 600;color: #ffffff;margin-bottom: 10px;line-height: 90px;}
.btsuper-ai-battery .container-fluid .battery-life-in .text p{background: #6a7a26;font-size:45px;color: #fff;display: inline-block;padding: 5px;padding-right: 35px;margin-bottom: 3px;line-height: 50px;}
.btsuper-ai-battery .container-fluid .battery-life-in .pro img{width:65%;}
.btsuper-ai-battery .container-fluid .battery-life-in .pro{text-align: right;}


.battery-lifes{position: relative;padding: 0px;}
.battery-lifes .container-fluid{overflow: hidden;padding: 0;position: absolute;bottom: 0px;margin: 0 auto;left: 0;right: 0;align-items: center;}
.battery-lifes .container-fluid .battery-life-in{text-align: center;padding-right: 0;}
.battery-lifes .container-fluid .battery-life-in .pro img{width: 85%;float: right;}
.battery-lifes .container-fluid .battery-life-in .battery-text{margin-bottom:10px !important;width:770px;margin:0 auto;display: flex;color: #fff;    align-items: center;}
.battery-lifes .container-fluid .battery-life-in .text h5{color: #3c6ba7;font-size: 68px;    line-height: 95px;}
.battery-lifes .container-fluid .battery-life-in .text h5 span{font-weight: 500;color: #fff;background: #6a7a26;padding: 0px 30px 0px 10px;}
.battery-lifes .container-fluid .battery-life-in .text h1{font-size: 55px;font-weight: 600;color: #111e2f;margin-bottom: 0px;line-height: 48px;}
.battery-lifes .container-fluid .battery-life-in .text p{background: #16123b;font-size: 22px;color: #fff;display: inline-block;padding: 5px;padding-right: 20px;margin-bottom: 0px;}
.battery-life .container-fluid .battery-life-in .pro img{width: 45%;float: right;margin-right: 100px;margin-bottom: 180px;}
.ten-days{margin-right: 15px;}
.battery-lifes .container-fluid .battery-life-in .battery-text .life h2{margin-bottom: 0;text-align: left;font-size: 88px;text-transform: uppercase;font-weight: 600;line-height: 65px;}
.battery-lifes .container-fluid .battery-life-in .battery-text .life h2 span{font-size: 70px;font-weight: 500;}

.super-ai-battery{position: relative;padding: 0px;}
.super-ai-battery .container-fluid{position: absolute;top: 100px;margin: 0 auto;left: 0;right: 0;align-items: center;}
.super-ai-battery .container-fluid .battery-life-in .text{float: left;position: absolute;}
.super-ai-battery .container-fluid .battery-life-in .text h2{font-size:90px;font-weight: 600;color: #111e2f;margin-bottom: 0px;line-height:90px;}
.super-ai-battery .container-fluid .battery-life-in .text p{background: #16123b;font-size:30px;line-height:45px;color: #fff;display: inline-block;padding: 5px;padding-right: 40px;margin-bottom: 0px;}
.super-ai-battery .container-fluid .battery-life-in .pro img{width:60%;float: right;margin-top: 45px;}


.expand-memory{position: relative;padding: 0px;}
.expand-memory .container-fluid{position: absolute;top:100px;margin: 0 auto;left: 0;right: 0;align-items: center;}
.expand-memory .container-fluid .expand-memory-ins .pro{float: right;width: 56%;}
.expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text{text-align: left;}
.expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text h2{font-size: 90px;font-weight: 600;color: #fff;margin-bottom:24px;line-height: 84px;}
.expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text p{font-size: 50px;line-height: 48px;margin-bottom: 10px;color: #fff;background: #000;display: inline-block;padding: 5px 30px 5px 5px;}
.expand-memory .container-fluid .expand-memory-ins .expand-memory-in .pro img{position: absolute;top: 0;}
.expand-memory .container-fluid .expand-memory-ins .expand-memory-in img.memory{margin-top: 82px;float: left;width: 44%;}


.athree-autocall-recording{position: relative;padding: 0px;}
.athree-autocall-recording .container-fluid .athree-autocall-recording-in{text-align: center;}
.athree-autocall-recording .container-fluid{position: absolute;bottom: 0px;margin: 0 auto;left: 0;right: 0;align-items: center;}
.athree-autocall-recording .container-fluid .athree-autocall-recording-in .text{text-align: center;}
.athree-autocall-recording .container-fluid .athree-autocall-recording-in .text h2{font-size:90px;font-weight: 600;color: #fff;margin-bottom: 0;}
.athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p{font-size: 45px;margin-bottom: 0;color: #fff;background: linear-gradient(45deg, #ffffff00, #ad8a46, #ffffff00);display: inline-block;text-transform: uppercase;padding: 4px 30px;line-height: 55px;}
.athree-autocall-recording .container-fluid .athree-autocall-recording-in .text img{width: 80%;margin-top: 25px;}
.athree-autocall-recording .container-fluid .athree-autocall-recording-in .text{text-align: center;}

.zthree.zthree-bg-eleven{position: relative;padding: 0;}
.zthree.zthree-bg-eleven .text{padding:0px 40px;position: absolute;top: 0;text-align: center;width: 100%;}
.zthree.zthree-bg-eleven .text h2{color: #fff;font-size:75px;margin-top: 50px;line-height:70px;}
.zthree.zthree-bg-eleven .text_two{padding:0px 40px;text-align: center;position: absolute;bottom: 40px;width: 100%;}
.zthree.zthree-bg-eleven .text_two p{font-size:45px;color: #4cfbbb;    line-height:45px;}

.bottom_list ul{margin: 0;}
.bottom_list ul li img.col_icon{height:215px;width: 100%;}
.bottom_list ul li{width: 20%;float: left;padding: 5px;position: relative;}
.bottom_list ul li .texts{position: absolute;top: 0;padding: 20px;width: 100%;} 
.bottom_list ul li .texts p{text-align: left;font-size: 28px !important;margin-top: 26px;line-height: 36px !important;letter-spacing: 1px;margin-bottom: 0;}

.zthree.zthree-bg-ten{position: relative;padding: 0;}
.zthree.zthree-bg-ten .zthree-bg-ten-in .text{margin-top:70px;}
.zthree.zthree-bg-ten .zthree-bg-ten-in{height: 100%; position: absolute;top:0px;text-align: center;left: 0;right: 0;margin: 0 auto;}
.zthree.zthree-bg-ten .zthree-bg-ten-in h2{font-size:90px;text-transform: uppercase;color: #fff;font-weight: 600;margin-bottom: 0;}
.zthree.zthree-bg-ten .zthree-bg-ten-in p{font-size:45px;color: #fff;line-height: 45px;}
.zthree.zthree-bg-ten .zthree-bg-ten-in .text .center{margin-top:40px;}
.bottom_list{position: absolute;bottom:0px;width: 100%;}

.zthree.zthree-bg-eight{position: relative;padding: 0;}
.zthree.zthree-bg-eight .zthree-bg-eight-in{position: absolute;top:130px;left: 0;right: 0;margin: 0 auto;}
.zthree.zthree-bg-eight .zthree-bg-eight-in h2{line-height: 85px;font-size: 90px;text-transform: uppercase;color: #fff;font-weight: 600;margin-bottom: 25px;letter-spacing: 2px;}
.zthree.zthree-bg-eight .zthree-bg-eight-in p{font-size: 35px;color: #fff;line-height:50px;}
.zthree.zthree-bg-eight .zthree-bg-eight-in .pro-img{position: absolute;right: 0;top: 0;text-align: right;}
.zthree.zthree-bg-eight .zthree-bg-eight-in .pro-img img{width:90%;    padding-left: 10%;}

.zthree.zthree-bg-seven{position: relative;padding: 0;}
.zthree.zthree-bg-seven .zthree-bg-seven-in{padding-left: 80px !important;position: absolute;top: 65px;left: 0;right: 0;margin: 0 auto;}
.zthree.zthree-bg-seven .zthree-bg-seven-in h2{line-height: 90px;font-size: 100px;text-transform: uppercase;color: #fff;font-weight: 600;margin-bottom: 0px;margin-top: 20px;text-align: center;padding-right: 235px;}

.zthree.zthree-bg-six{position: relative;padding: 0;}
.zthree.zthree-bg-six .zthree-bg-six-in{position: absolute;top: 65px;left: 0;right: 0;margin: 0 auto;}
.zthree.zthree-bg-six .zthree-bg-six-in h2{margin-bottom: 25px; line-height:80px;font-size:90px;text-transform: uppercase;color: #fff;font-weight: 600;}
.zthree.zthree-bg-six .zthree-bg-six-in p{line-height:50px;font-size:50px;font-weight: 600; background: #fff;display: inline;padding: 0px 30px 0px 5px;text-transform: uppercase;}

.zthree.zthree-bg-five{position: relative;padding: 0;}
.zthree.zthree-bg-five .zthree-bg-five-in{text-align: center;position: absolute;top: 65px;right: 0;left: 0;margin: 0 auto;}
.zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul{overflow: hidden;width: 80%;margin: 0 auto;}
.zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul li{width: 33.33%;float: left;text-align: center;}
.zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul h6{color:#0b9ced;font-size:40px;font-weight: 600;margin-bottom: 0px;}
.zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul p{font-size:30px;margin: 0;}
.zthree.zthree-bg-five .zthree-bg-five-in h2{line-height:80px;font-size:80px;text-transform: uppercase;color: #fff;}
.zthree.zthree-bg-five .zthree-bg-five-in p{font-size:40px;color: #cccccc;line-height: 45px;}
.zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col{margin-top: 40px;}

.zthree.zthree-bg-four{position: relative;padding: 0;}
.zthree.zthree-bg-four .zthree-bg-four-in .text{position: absolute;right: 0;}
.zthree.zthree-bg-four .zthree-bg-four-in{text-align: left;position: absolute;top: 160px;right: 0;left: 0px;margin: 0 auto;}
.zthree.zthree-bg-four .zthree-bg-four-in h2{line-height:75px;font-size:80px;text-transform: uppercase;color: #fff;}
.zthree.zthree-bg-four .zthree-bg-four-in p{font-size:40px;color: #ffffff;line-height:45px;}
.zthree.zthree-bg-four .zthree-bg-four-in .bottom-img img{margin-top: 14%;}

.zthree.zthree-bg-one{position: relative;padding: 0;}
.zthree.zthree-bg-one .bg-height{height:855px;width: 100%;}
.zthree.zthree-bg-one .zthree-bg-one-in{top:0px;position: absolute;left:0;right: 0;margin: 0 auto; display: flex;align-items: center;}
.zthree.zthree-bg-one .zthree-bg-one-in .one-half{width: 50%;text-align: center;}
.zthree.zthree-bg-one .zthree-bg-one-in .one-half .text img{margin-top:140px;}
.zthree.zthree-bg-one .zthree-bg-one-in h1{font-size:90px;font-weight: 600;text-transform: uppercase;color: #162170;}
.zthree.zthree-bg-one .zthree-bg-one-in h2{font-size:60px;line-height:60px;}
.zthree.zthree-bg-one .zthree-bg-one-in p{font-size: 26px;color: #ffffff;line-height: 32px;}

.blazepro_section .section_two{    padding: 60px;}
.blazepro_section .section_two .section-content ul li{padding: 8px;}
.blazepro_section .section_two .section-content ul li .flex-col.lighting{margin-top: 10px;}
.blazepro_section .section_two .section-content .flex-col{position: relative;}
.blazepro_section .section_two .section-content .flex-col .text.two{ padding-top: 0;text-align:left;}
.blazepro_section .section_two .section-content .flex-col .text.two p{text-align: right;}
.blazepro_section .section_two .section-content .flex-col .text.three{ top: -20px;}
.blazepro_section .section_two .section-content .flex-col .text.three p{text-align: left;}
.blazepro_section .section_two .section-content .flex-col .text.four{ padding-top: 0;}
.blazepro_section .section_two .section-content .flex-col .text.four p{text-align: right;}
.blazepro_section .section_two .section-content .flex-col .text.five{ padding-top: 0;top:10px;}
.blazepro_section .section_two .section-content .flex-col .text{ width: 100%;position: absolute;top: 30px;padding: 30px;}
.blazepro_section .section_two .section-content .flex-col .text p{font-size: 20px;color: #fff;line-height: 20px;}

.herosixhundred_one{position: relative;padding: 0px;}
.herosixhundred_one .bg-height{height: 950px;width: 100%;}
.herosixhundred_one .herosixhundred-one{position: absolute;top: 130px;right: 0px;left: 0;margin: 0 auto;}
.herosixhundred_one .herosixhundred-one .herosixhundred_one_in{display: flex;align-items: center;}
.herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left{width: 50%;text-align: center;}
.herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left img{width:72%;}
.herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left.right img{width: 70%;}
.herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left h1{font-size:72px;color: #fff;font-weight: 600;margin-bottom: 40px;}

.super-mob-list .container-fluid ul li img{border-radius: 10px;}
.super-mob-list .container-fluid ul li .text p{margin: 0;font-size: 21px;color: #fff;line-height: 23px;margin-top: 20px;}
.super-mob-list .herosixhundred_two ul li .text p{margin: 0;font-size: 20px;color: #fff;line-height: 23px;margin-top: 10px;height: 50px;padding-left: 15px;display: flex;text-align: left;align-items: center;}

.herosixhundred_three{padding: 0;position: relative;}
.herosixhundred_three .herosixhundred_three_in{position: absolute;top: 175px;right: 0px;left: 0;margin: 0 auto;}
.herosixhundred_three .herosixhundred_three_in h2{font-size: 130px;letter-spacing: 5px;line-height:130px;color: #fff;}
.herosixhundred_three .herosixhundred_three_in h2 span{ font-size: 75px;}
.herosixhundred_three .herosixhundred_three_in p{color: #fff;font-size: 30px;line-height: 32px;padding: 5px;background: #2f5086;}


.herosixhundred_four{padding: 0;position: relative;}
.herosixhundred_four .herosixhundred_four_in .text{width: 50%;}
.herosixhundred_four .herosixhundred_four_in .rightimg{width: 50%;}
.herosixhundred_four .herosixhundred_four_in{position: absolute;top: 0;right: 0px;display: flex;align-items: center;width: 88%;}
.herosixhundred_four .herosixhundred_four_in .text h3{font-size: 130px;font-weight: 700;line-height: 115px;margin-bottom: 0;}
.herosixhundred_four .herosixhundred_four_in .text h3 span{font-size:100px;}
.herosixhundred_four .herosixhundred_four_in .text h2{font-size:90px;margin: 0;line-height:100px;}
.herosixhundred_four .herosixhundred_four_in .text p{    font-size: 35px;line-height: 45px;letter-spacing: 1px;margin-top: 15px;}
.herosixhundred_four .herosixhundred_four_in .text img{height: 58px;margin-top:15px;}
.herosixhundred_four .herosixhundred_four_in .rightimg img{width:100%;float: right;}

.herosixhundred_five{padding: 0;position: relative;}
.herosixhundred_five .herosixhundred_five_in{position: absolute;top:160px;right: 0px;left: 0;margin: 0 auto;}
.herosixhundred_five .herosixhundred_five_in .text{padding-left: 35%;}
.herosixhundred_five .herosixhundred_five_in .left img{width: 75%;position: absolute;margin-top: -110px;}
.herosixhundred_five .herosixhundred_five_in h2{font-size:115px;line-height:98px;font-weight: 600;text-transform: uppercase;}
.herosixhundred_five .herosixhundred_five_in h2 span{    font-size:115px;}
.herosixhundred_five .herosixhundred_five_in p{color: #fff;font-size:35px;line-height: 75px;padding: 0px 20px;background: linear-gradient(45deg, #2f5086, #2f508673);}


.herosixhundred_six{padding: 0;position: relative;}
.herosixhundred_six .herosixhundred_six_in{position: absolute;top: 220px;left: 0;right: 0;margin: 0 auto;display: flex;}
.herosixhundred_six .herosixhundred_six_in .left{width: 50%;}
.herosixhundred_six .herosixhundred_six_in .text{width: 50%;padding-left:60px;text-align: right;}
.herosixhundred_six .herosixhundred_six_in h2{margin-bottom: 20px; font-size:100px;line-height:88px;color: #fff;font-weight: 600;}
.herosixhundred_six .herosixhundred_six_in p{color: #fff;font-size:38px;line-height:50px;}

.herosixhundred_seven{padding: 0;position: relative;}
.herosixhundred_seven .herosixhundred_seven_in{display: flex;position: absolute;top: 100px;margin: 0 auto;left: 0;right: 0;}
.herosixhundred_seven .herosixhundred_seven_in .left{width: 50%;}
.herosixhundred_seven .herosixhundred_seven_in .left .text{position: relative;}
.herosixhundred_seven .herosixhundred_seven_in .left h2{font-weight: 600;font-size:90px;    line-height:100px;}
.herosixhundred_seven .herosixhundred_seven_in .left label{font-family: 'siri'; margin: 0;font-weight: 500;background: #87463d;color: #fff;font-size:70px;padding: 3px 30px 8px 8px;line-height:62px;}
.herosixhundred_seven .herosixhundred_seven_in .left p{font-size: 48px;margin-top: 22px;line-height: 50px;}
.herosixhundred_seven .herosixhundred_seven_in .left img{width:85%;margin-top: 35px;}
.herosixhundred_seven .herosixhundred_seven_in .right img{width:100%;}

.herosixhundred_eight{padding: 0;position: relative;}
.herosixhundred_eight .herosixhundred_eight_in{position: absolute;top:0px;height:100%;text-align: center;left: 0;right: 0;margin: 0 auto;}   
.herosixhundred_eight .herosixhundred_eight_in .text{margin-top: 130px;}
.herosixhundred_eight .herosixhundred_eight_in .center img{position: absolute;bottom: 0;left: 0;right: 0;margin: 0 auto;}
.herosixhundred_eight .herosixhundred_eight_in h3{font-size:125px;font-weight: 600;margin: 0;line-height:105px;}
.herosixhundred_eight .herosixhundred_eight_in h2{font-size:70px;margin: 0;background: linear-gradient(90deg, #0000, #ef1919, #2f508600);color: #fff;padding: 0px 220px;display: inline-block;line-height:75px;}
.herosixhundred_eight .herosixhundred_eight_in p{color: #dd0101;font-size:45px;margin-top:18px;}

.main_menu ul{margin-bottom: 0px !important;}
.country_list select{display: none;}
.country_list{position: absolute;width: 185px;border-top: 3px solid #ea1957;right: 7%;background: white;padding: 10px;border-radius: 0px;box-shadow: 0 0 5px #5a5a5a52;top: 55px;}
.country_list ul li{display: block;text-align: left;font-size: 14px;margin: 10px 0px;cursor: pointer;line-height: 10px;}
.country_list ul li a{font-weight: normal !important; padding: 0;font-family: inherit; text-transform: capitalize}
.country_list ul li:hover{color: #ea1957;}
.country_list ul li:nth-child(1){margin-top: 0;}
.country_list ul li:last-child{margin-bottom: 0;}
.country_list::before{ border-bottom: 10px solid #ea1957;content: ' ';width: 0;height: 0;right: 14px;position: absolute;top: -13px;border-left: 10px solid transparent;border-right: 10px solid transparent;}

.champion_one {position: relative;padding: 0px;}
.champion_one .bg-height{height: 950px;width: 100%;}
.champion_one .champion-one{position: absolute;bottom: 130px;right: 0px;left: 0;margin: 0 auto;text-align: left;}
.champion_one .champion_one_in {display: flex;align-items: center;}
.champion_one .champion_one_in .left {width: 50%;text-align: center;}
.champion_one .champion_one_in .left h1 {margin-bottom:90px;font-size:72px;color: #fff;font-weight: 600;}
.champion_one .champion_one_in .left img {width:100%;}
.champion_one .champion_one_in .left {width: 50%;text-align: center;}
.champion_one .champion_one_in .left.right img {width:100%;}

.champion_two ul li .text p{color: #f2a988 !important;}

.champion_three{position: relative;padding: 0px;}
.champion_three .champion_three_in {position: absolute;top: 75px;right: 0px;text-align: center;left: 0;margin: 0 auto;}
.champion_three .champion_three_in h2 {margin-bottom:150px;font-size:95px;color: #43291d;font-weight: 600;line-height:100px;}

.champion_four{position: relative;padding: 0px;}
.champion_four .champion_four_in {position: absolute;top: 75px;right: 0px;left: 0;margin: 0 auto;}
.champion_four .champion_four_in .in .text{display: flex;align-items: end;}
.champion_four .champion_four_in .in .text label{font-size:80px;font-weight: 600;background: #d5c0b6;padding: 20px 10px;margin: 0;height:230px;display: flex;align-items: center;}
.champion_four .champion_four_in h2 {margin-bottom: 0px;font-size:80px;color: #43291d;font-weight: 600;line-height: 80px;margin-left: 5px;}
.champion_four .champion_four_in .in p{font-size:40px;margin-top:20px;line-height: 55px;}
.champion_four .champion_four_in .in p span{background: #b0d6a1;color: #fff;line-height: 23px;padding: 3px 5px;margin-right: 2px;font-family: 'siri';display: inline-block;}
.champion_four .champion_four_in h2 span{color: #b0d6a1;}
.champion_four .champion_four_in .center{position: absolute;right: 0;left: 0;top: 80px;}
.champion_four .champion_four_in .center img{width:75%;margin-top: 250px;position: absolute;right: 0;}

.champion_five{position: relative;padding: 0px;}
.champion_five .champion_five_in {padding-left: 0px !important;position: absolute;top:22px;right: 0px;text-align: center;left: 0;margin: 0 auto;}
.champion_five .champion_five_in .text h2 {margin-bottom: 5px;font-size: 90px;color: #ffffff;font-weight: 600;line-height: 95px;}
.champion_five .champion_five_in .left-img img{width: 100%;position: relative;left: -20%;}
.champion_five .champion_five_in .left-img{text-align: left;}
.champion_five .champion_five_in .text{ position: absolute;right: 0;width: 50%;top: 130px;text-align:right;}
.champion_five .champion_five_in .text p{color: #fff;font-size: 40px;line-height: 40px;}


.champion_six{position: relative;padding: 0px;}
.champion_six .champion_six_in {display: flex; position: absolute;top:140px;right: 0px;align-items: end;left: 0;margin: 0 auto;}
.champion_six .champion_six_in .text{text-align: left;width: 50%;}
.champion_six .champion_six_in h2 {margin-bottom: 5px;font-size:100px;color: #573c30;font-weight: 600;line-height:100px;}
.champion_six .champion_six_in .left-img img{width:68%;}
.champion_six .champion_six_in .right{width: 50%;}
.champion_six .champion_six_in .left-img{text-align: left;}
.champion_six .champion_six_in p{font-size: 35px;line-height:45px;margin-top: 30px;margin-bottom: 100px;}
.champion_six .champion_six_in img{width:85%;}


.champion_seven{position: relative;padding: 0px;}
.champion_seven .champion_seven_in {position: absolute;top:80px;right: 0px;left: 0;margin: 0 auto;}
.champion_seven .champion_seven_in .text{text-align: left;position: absolute;}
.champion_seven .champion_seven_in img{width: 90%;margin-top: 88px;float: right;}
.champion_seven .champion_seven_in h2 {margin-bottom: 5px;font-size: 90px;color: #573c30;font-weight: 600;line-height: 90px;}
.champion_seven .champion_seven_in p{font-family: 'siri'; font-size:35px;line-height:60px;background: #1e4210;color:#b0d6a1;padding-left: 5px;padding-right: 30px;}


.champion_eight{position: relative;padding: 0px;}
.champion_eight .champion_eight_in {display: flex;position: absolute;top: 145px;right: 0px;left: 0;margin: 0 auto;}
.champion_eight .champion_eight_in .text{text-align: left;}
.champion_eight .champion_eight_in h2 {margin-bottom: 15px;font-size: 78px;color: #f5d855;font-weight: 600;line-height: 70px;}
.champion_eight .champion_eight_in p{margin-bottom: 160px;font-size:40px;line-height:55px;color: #fff;text-align: center;float: left;}


.champion_nine{position: relative;padding: 0px;}
.champion_nine .champion_nine_in {display: flex; position: absolute;top:80px;right: 0px;left: 0;margin: 0 auto;}
.champion_nine .champion_nine_in .text{text-align: left;}
.champion_nine .champion_nine_in .center img{position: absolute;left: 0;right: 0;margin: 0 auto;top: 65%;}
.champion_nine .champion_nine_in h2{margin-bottom: 10px;font-size:90px;color: #b0d6a1;font-weight: 600;line-height:90px;}
.champion_nine .champion_nine_in p{font-size: 35px;line-height: 50px;background: #1e4210;color: #fff;padding-left: 5px;padding-right: 28px;letter-spacing: 2px;}

.champion_ten{position: relative;padding: 0px;}
.champion_ten .champion_ten_in {display: flex;    justify-content: space-between;position: absolute;top:80px;right: 0px;left: 0;margin: 0 auto;}
.champion_ten .champion_ten_in img{width:65%;margin-top:100px;}
.champion_ten .champion_ten_in .text{text-align: right;}
.champion_ten .champion_ten_in h2 {margin-bottom: 10px;font-size:90px;color: #ec8838;font-weight: 600;line-height:90px;}
.champion_ten .champion_ten_in p{font-size: 37px;line-height: 45px;letter-spacing: 3px;color: #fff;}


.afive_one{position: relative;padding: 0px;}
.afive_one .afive_one_in{position: absolute;bottom:10%;margin: 0 auto;left: 0;right: 0;}
.afive_one .afive_one_in .flex{display: flex;align-items: center;}
.afive_one .afive_one_in .left-text{margin-top: 40px;width: 50%;}
.afive_one .afive_one_in .left-text h1{font-size:90px;font-weight: 600;text-align:center;margin-bottom:110px;color: #fff;}
.afive_one .afive_one_in .left-text img{width: 100%;margin-top: 0px;}
.afive_one .afive_one_in .right-text{width: 50%;}
.afive_one .afive_one_in .right-text img{width:100%;}

.super-mob-list.afive_two{padding: 40px 0px 60px 0px;}
.super-mob-list.afive_two ul li .text h4{text-align: left;padding: 20px;}

.afive_three{position: relative;padding: 0px;}
.afive_three .afive_three_in{align-items: center; position: absolute;bottom: 10px;margin: 0 auto;left: 0;right: 0;display: flex;}
.afive_three .afive_three_in .left h2{text-align: center;text-shadow: 2px 3px 1px #000;font-size:90px;font-weight: 600;line-height:95px;color: #fff;}
.afive_three .afive_three_in .right{text-align: center;}
.afive_three .afive_three_in .right img{width:100%;}

.afive_four{position: relative;padding: 0px;}
.afive_four .afive_four_in{position: absolute;bottom: 0px;margin: 0 auto;left: 0;right: 0;display: flex;}
.afive_four .afive_four_in .left{width: 50%;}
.afive_four .afive_four_in .left h2{font-size:90px;font-weight: 600;line-height: 88px;margin-top: 60px;color: #fff;}
.afive_four .afive_four_in .right{text-align: right;}
.afive_four .afive_four_in .left h2 span{color: #f4f8b3;}
.afive_four .afive_four_in .right img{width:100%;}


.afive_five{position: relative;padding: 0px;}
.afive_five .afive_five_in{position: absolute;top: 0px;margin: 0 auto;left: 0;right: 0;display: flex;}
.afive_five .afive_five_in .left h2{font-size:90px;font-weight: 600;line-height: 80px;margin-top: 150px;color: #fff;}
.afive_five .afive_five_in .left h2 span{color: #c4c880;}
.afive_five .afive_five_in .left h2 span:first-child{font-size: 160px;}
.afive_five .afive_five_in .left h2 span:nth-child(2){font-size: 70px;}
.afive_five .afive_five_in .right{text-align: right;}
.afive_five .afive_five_in .right img{    width: 70%;}



.afive_six{position: relative;padding: 0px;}
.afive_six .afive_six_in{position: absolute;bottom: 50px;margin: 0 auto;text-align: right;left:0;right: 0px;}
.afive_six .afive_six_in .left{text-align: left;}
.afive_six .afive_six_in .right h2{font-size:90px;font-weight: 600;line-height:80px;color: #e9d179;margin-top: 50px;}
.afive_six .afive_six_in .right p{font-size: 40px;line-height: 52px;color: #fff;letter-spacing: 5px;text-transform: uppercase;}
.afive_six .afive_six_in .right{text-align: right;position: absolute;right: 0;top:-33%;}

.afive_seven{position: relative;padding: 0px;}
.afive_seven .afive_seven_in{position: absolute;top: 0px;margin: 0 auto;text-align: right;left: 0;right: 0;}
.afive_seven .afive_seven_in .left{float: right;text-align: -webkit-right;}
.afive_seven .afive_seven_in .left h2{font-size:90px;font-weight: 600;line-height:90px;margin-top: 100px;color: #444459;margin-bottom: 10px;}
.afive_seven .afive_seven_in .left p{font-size: 40px;line-height: 50px;color: #fff;margin-bottom:5px;background: #8f23ae;display: table;text-transform: uppercase;padding-left: 72px;letter-spacing: 8px;}
.afive_seven .afive_seven_in .left img{margin-top: 20px;}

.afive_eight{position: relative;padding: 0px;}
.afive_eight .afive_eight_in{position: absolute;top:15%;left: 0;display: flex;right: 0;margin: 0 auto;}
.afive_eight .afive_eight_in .left{text-align: left;    width: 60%;}
.afive_eight .afive_eight_in .right{ width: 40%;}
.afive_eight .afive_eight_in .right img{width: 100%;}
.afive_eight .afive_eight_in .left h2{margin-bottom:20px;font-size:90px;font-weight: 600;line-height:90px;color: #ffffff;}
.afive_eight .afive_eight_in .left p{font-size: 40px;line-height: 60px;color: #fff;margin-bottom: 5px;letter-spacing:10px;background: #44455a;display: table;text-transform: uppercase;padding-left: 5px;padding-right: 20px;}
.afive_eight .afive_eight_in .left .m-image{text-align: center;    margin-top: 50px;}
.afive_eight .afive_eight_in .left .m-image img{width:78%;margin-top: 50px;}
.afive_eight .afive_eight_in .right{text-align: right;}



.afive_nine{position: relative;padding: 0px;}
.afive_nine .afive_nine_in{left: 0;right: 0;position: absolute;top: 20%;margin: 0 auto;display: flex;}
.afive_nine .afive_nine_in .left{width: 50%;}
.afive_nine .afive_nine_in .left .center{margin-top:50px;}
.afive_nine .afive_nine_in .left h2{font-size:90px;font-weight: 600;line-height: 80px;color: #ffffff;margin-bottom: 20px;}
.afive_nine .afive_nine_in .left p{font-size: 35px;line-height: 46px;color: #87212c;margin-bottom: 5px;padding-left: 5px;background: #ffffff;display: table;padding-right: 30px;text-transform: uppercase;}

.img-responsive{width: 100%;}

.afive_ten{position: relative;padding: 0px;}
.afive_ten .afive_ten_in{justify-content: space-between;display: flex;left: 0;right: 0;position: absolute;top: 175px;margin: 0 auto;align-items: flex-start;}
.afive_ten .afive_ten_in img{width:40%;}
.afive_ten .afive_ten_in .right{text-align: right;}
.afive_ten .afive_ten_in .right h2{font-size:90px;font-weight: 600;line-height: 90px;color: #ffffff;margin-bottom: 30px;}
.afive_ten .afive_ten_in .right p{font-size: 40px;line-height: 48px;color: #36424d;margin-bottom: 5px;background: #ffffff;padding:0px 5px;display: inline-block;padding-left: 30px;padding-right: 10px;text-align: right;text-transform: uppercase;}


.afive_eleven .afive_eleven_in .left{width: 50%;float: left;margin-top: 100px;}
.afive_eleven .afive_eleven_in .right{width: 50%;float: left;}
.afive_eleven{position: relative;padding: 0px;}
.afive_eleven .afive_eleven_in{left: 0;right: 0;position: absolute;bottom:50px;margin: 0 auto;}
.afive_eleven .afive_eleven_in img{width:80%;margin-left: 100px;}
.afive_eleven .afive_eleven_in .left h2{font-size:90px;font-weight: 600;line-height:80px;color: #242e37;margin-bottom:10px;}
.afive_eleven .afive_eleven_in .left p{font-size: 35px;line-height: 52px;color: #d2fca3;margin-bottom: 5px;background: #242e37;padding: 0px;display: inline-block;padding-left: 5px;padding-right: 65px;}


.container_two.two.blazefiveg_two ul li .text h1{font-size: 28px;line-height: 26px;margin-bottom: 5px;}
.container_two.two.blazefiveg_two ul li .text p{line-height: 20px;}

.blazefiveg_three{position: relative;padding: 0px 0px;overflow: hidden;}
.blazefiveg_three .blazefiveg_three_in{margin: 0 auto; position: absolute;top:70px;left: 0;right: 0;text-align: center;}
.blazefiveg_three .blazefiveg_three_in h1{margin-bottom: 0;font-size:90px;color: #fff;font-weight: 600;letter-spacing: .5px;}
.blazefiveg_three .blazefiveg_three_in p{font-size: 40px;line-height: 45px;margin-bottom:35px;color: #fff;}
.blazefiveg_three .blazefiveg_three_in img{width:85%;}

.blazefiveg_four{position: relative;padding: 0px 0px;}
.blazefiveg_four .blazefiveg_four_in img{width: 100%;left: -20%;margin-top: 122px;position: absolute;}
.blazefiveg_four .blazefiveg_four_in{margin: 0 auto; position: absolute;top: 100px;left: 0;right: 0;text-align: left;}
.blazefiveg_four .blazefiveg_four_in h2{margin-bottom: 0;font-size:90px;line-height:80px;color: #fff;font-weight: 600;}
.blazefiveg_four .blazefiveg_four_in h5{font-size:55px;letter-spacing: 3px;color: #fff;line-height: 50px;}
.blazefiveg_four .blazefiveg_four_in label{font-size: 45px;background: #fff;padding-right: 35px;line-height: 50px;letter-spacing: 3px;padding-left: 8px;}
.blazefiveg_four .blazefiveg_four_in label span{font-weight: 600;}
.blazefiveg_four .blazefiveg_four_in p{    font-size:35px;line-height:48px;color: #fff;background: #000;display: inline-block;padding: 5px;padding-right: 30px;padding-left: 8px;}


.blazefiveg_five{position: relative;padding: 0px 0px;}
.blazefiveg_five .blazefiveg_five_in{margin: 0 auto; position: absolute;top:80px;left: 0;right: 0;text-align: center;}
.blazefiveg_five .blazefiveg_five_in h2{margin-bottom:10px;font-size:90px;line-height:95px;color: #fff;font-weight: 600;text-transform: uppercase;}
.blazefiveg_five .blazefiveg_five_in label{font-size: 50px;color: #fff;line-height:55px;font-weight: 600;display: block;}
.blazefiveg_five .blazefiveg_five_in p{font-size: 50px;line-height:55px;color: #fff;background: linear-gradient(90deg, #fff0, #000000, #fff0);display: inherit;margin-bottom:60px;}

.w_hundered{width: 100%;}
.blazefiveg_six{position: relative;padding: 0px 0px;}
.blazefiveg_six .blazefiveg_six_in{margin: 0 auto; position: absolute;top:95px;left: 0;right: 0;text-align: left;}
.blazefiveg_six .blazefiveg_six_in .text{float: left;    padding-left:80px;}
.blazefiveg_six .blazefiveg_six_in h2{margin-bottom: 0;font-size:90px;line-height:70px;color: #2b2c2c;font-weight: 600;letter-spacing: 2px;text-transform: uppercase;}
.blazefiveg_six .blazefiveg_six_in p{font-size: 35px;line-height: 45px;font-weight: 600;margin-top: 15px;letter-spacing: 1px;color: #2b2c2c;display: inline-block;}
.blazefiveg_six .blazefiveg_six_in .pro-img img{width:71%;margin-top: 230px;right: 150px;position: absolute;}


.blazefiveg_seven{position: relative;padding: 0px 0px;}
.blazefiveg_seven .blazefiveg_seven_in{margin: 0 auto; position: absolute;top:60px;left: 0;right: 0;text-align: left;}
.blazefiveg_seven .blazefiveg_seven_in h2{margin-bottom:0px;font-size: 90px;line-height:80px;letter-spacing: 2px;color: #ffffff;font-weight: 600;text-transform: uppercase;}
.blazefiveg_seven .blazefiveg_seven_in label{display: block;font-size: 60px;color: #fff;line-height:60px;margin-bottom:5px;}
.blazefiveg_seven .blazefiveg_seven_in p{font-size: 50px;line-height:55px;font-weight: 600;color: #2b2c2c;background: #05ecfa;display: inline;padding: 0px 60px 3px 3px;}

.blazefiveg_eight{position: relative;padding: 0px 0px;}
.blazefiveg_eight .blaze_left_text{margin: 0 auto;position: absolute;bottom: 0px;left: 0;right: 0;height: 100%;}
.blazefiveg_eight .blaze_left_text .text{display: flex;align-items: center;margin-top: 15%;}
.blazefiveg_eight .blaze_left_text .text h2{font-size: 130px;font-weight: 600;line-height: 100px;text-transform: uppercase;}
.blazefiveg_eight .blaze_left_text .text p{line-height:60px;font-size:52px;}
.blazefiveg_eight .blaze_left_text .text p span{background: #ffc80b;padding-left: 5px;border-radius: 5px;padding-right: 15px;font-weight: 600;}
.blazefiveg_eight .blaze_left_text .text img{ height: 155px;margin-left: -42px;transform: rotate(0deg);}
.blazefiveg_eight .blaze_left_text .center img{position: absolute;right: 0;bottom: 0;width: 70%;}

.blazefiveg_eight{position: relative;padding: 0px 0px;}
.blazefiveg_eight .blazefiveg_eight_in{position: absolute;top: 98px;left: 0;right: 0;text-align: left;}
.blazefiveg_eight .blazefiveg_eight_in h1{margin-bottom: 0;font-size: 100px;line-height: 80px;color: #ffffff;font-weight: 600;text-transform: uppercase;}
.blazefiveg_eight .blazefiveg_eight_in label{display: block;font-size: 57px;color: #fff;line-height: 55px;}
.blazefiveg_eight .blazefiveg_eight_in p{font-size: 48px;line-height: 62px;font-weight: 600;color: #2b2c2c;background: #05ecfa;display: inline;padding: 0px 20px 3px 3px;}

.blazefiveg_nine{position: relative;padding: 0px 0px;}
.blazefiveg_nine .blazefiveg_nine_in{margin: 0 auto; position: absolute;top: 160px;left: 0;right: 0;text-align: left;}
.blazefiveg_nine .blazefiveg_nine_in h2{margin-bottom: 0;font-size: 75px;line-height: 75px;color: #ffffff;font-weight: 600;text-transform: uppercase;}
.blazefiveg_nine .blazefiveg_nine_in label{font-size: 40px;color: #010100;line-height:40px;background: #ffc80b;display: initial;font-weight: 600;padding:5px 12px;}
.blazefiveg_nine .blazefiveg_nine_in p{font-size: 35px;line-height: 45px;font-weight: 600;color: #b8e2fe;margin-top:30px;margin-bottom: 45px;}

.blazefiveg_ten{position: relative;padding: 0px 0px;}
.blazefiveg_ten .blazefiveg_ten_in{margin: 0 auto; position: absolute;top: 100px;left: 0;right: 0;text-align:center;}
.blazefiveg_ten .blazefiveg_ten_in h3{margin-bottom: 0;font-size:90px;line-height:85px;color: #fcd373;font-weight: 600;}
.blazefiveg_ten .blazefiveg_ten_in h2{font-size:50px;color: #fff;font-weight: 600;margin-top: 20px;}
.blazefiveg_ten .blazefiveg_ten_in label{font-size:45px;color: #ffffff;line-height: 25px;background: linear-gradient(90deg,#0000,#de3e65,#0000);display: initial;padding: 0px 115px;}
.blazefiveg_ten .blazefiveg_ten_in p{font-size:45px;font-weight: 600;color: #fcd373;margin-top: 18px;margin-bottom: 35px;}
.blazefiveg_ten .two.cum-container-width{margin: 0 auto;position: absolute;right: 0;left: 0;text-align: center;bottom:30px;z-index: 999;}
.blazefiveg_ten .two.cum-container-width p{color: #fcd373;font-size: 28px;line-height: 32px;}


.blazefiveg_eleven{position: relative;padding: 0px 0px;}
.blazefiveg_eleven .blazefiveg_eleven_in{position: absolute;bottom: 120px;right: 0;text-align: left;float: right;width: 60% !important;margin-right: 0;}
.blazefiveg_eleven .blazefiveg_eleven_in h2{margin-bottom: 0;font-size: 85px;line-height: 80px;color: #ffffff;font-weight: 600;text-transform: uppercase;}
.blazefiveg_eleven .blazefiveg_eleven_in label{font-size: 40px;color: #49c4f2;line-height: 50px;}
.blazefiveg_eleven .blazefiveg_eleven_in p{font-size:45px !important;line-height:50px;color: #b8e2fe;margin-top:20px;}
.blazefiveg_eleven .blazefiveg_eleven_in p.second{color: #fff;font-size:40px !important;}


.blazefiveg_twelve{position: relative;padding: 0px 0px;}
.blazefiveg_twelve .blazefiveg_twelve_in img{margin-top: 35px;width: 88%;}
.blazefiveg_twelve .blazefiveg_twelve_in{margin: 0 auto; position: absolute;top:70px;left: 0;right: 0;text-align: center;}
.blazefiveg_twelve .blazefiveg_twelve_in h2{margin-bottom: 0;font-size: 90px;line-height: 108px;color: #ffffff;font-weight: 600;}
.blazefiveg_twelve .blazefiveg_twelve_in label{font-size:28px;color: #49c4f2;line-height: 28px;}
.blazefiveg_twelve .blazefiveg_twelve_in p{font-size:45px;line-height: 50px;color: #ffffff;margin-top: 15px;}
.blazefiveg_twelve .two{right: 0;left: 0;  position: absolute;bottom:50px;}
.blazefiveg_twelve .two p{color: #fff;text-align: center;line-height:50px;font-size:45px;}
.produc_img img{width: 100%}
.breadcurms .full_container ul{margin-bottom: 0px;}

.container_two.container.zthree_two .blaze_pro_list ul li .flex-col .text p{color: #fff;}
.container_two.container.zthree_two .blaze_pro_list ul li .flex-col .text.ramplus{top: 0%;}
.container_two.container.zthree_two .blaze_pro_list ul li .flex-col.lighting .text{top: -5%;}
.container_two.container.zthree_two .blaze_pro_list .right_block ul li:nth-child(2) .flex-col .text{text-align: right;width: 100%;}



@media (max-width:1920px){
    .champion_seven .champion_seven_in p{line-height: 50px;}
    .champion_four .champion_four_in .in p span{line-height: 35px;}
    .product_details.blaze-enjoy .one_detail1{top: 30%;}
    .one_detail1 .one_detail_content p {font-size: 38px;line-height: 50px;margin-bottom: 20px;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 40px;letter-spacing: 0px;}
    .beauty-mode .one_detail_content .beauty_mode p{font-size: 40px;line-height: 54px;}
    .hot_pro_row .hot_3 .one_sec_box{height: 45.3%;}
    .music-lovers .container-fluid .music-lovers-in .text p{line-height: 50px;padding-right: 128px !important;    font-size: 46px;}
    .music-lovers .container-fluid .music-lovers-in .text h2{font-size: 90px;line-height:92px;}
    .athreesuper .bg-height{height: 950px;}
    .days-battery .container-fluid{top:100px;}
    .asuper .bg-height{height:830px;}
    .blazepro_section.ten .img {height: 1200px;}
    .blazepro_section.nine .img {height: 1375px;}
    .blazepro_section.eight .img {height: 1700px;}
    .blazepro_section.eleven .img {height: 960px;}
    .blazepro_section.six .img{height: 1100px;}
    .blazepro_section.four .img{height: 1200px;}
    .blazepro_section.three .img{height: 1170px;}
    .blazepro_section.one .img{height: 850px;}
    .last_li .selec_coun span{font-size: 14px;}
    header{padding: 0px 0px;}
    .country_list ul li{font-size: 14px;}
    .search_con_input {height: 41px;font-size: 16px;}
    .search_bar_dropdown{margin-top:0px;width: 21%;}
    .country_list{right: 6%;}
    .custom-a img{width: 100%;}
    .selec_coun i {font-size: 20px;    padding: 0px 0px;}
    .detailpage_breadcum {margin-top:56px;}
    .container-fluid.container_eleven h4{font-size:35px;line-height:50px;}
    .container_three{top: 100px;}
    .container_two.container{max-width:70%;padding: 50px 0px;}
    .container_four .section-content label{font-size: 62px;line-height: 55px;}
    .container.container_nine{top:135px;}
    .container-fluid.container_eleven {top: 135px;}
    .container_one .pro-img img{width:88%;}
    .blazepro_section .container_one .section-content{width:465px;top:50px;}
    .blazepro_section .container_one .section-content h1{font-size: 128px;line-height: 120px;}
    .blazepro_section .container_one .section-content label{font-size: 45px;line-height: 52px;}
    .section-logo img {width:275px;}
    .container_three .section-content h2 {font-size: 105px;line-height: 110px;}
    .container_three .section-content label {font-size: 40px;line-height: 45px;}
    .proul img{width:51%;height: auto;margin-top: 0px;}
    .container_four .section-content h2{font-size: 128px;line-height:115px;}
    .container_four .section-content p{line-height:52px;font-size: 40px;font-weight: 500;}
    .fiftympproduct img {width: 82%;margin-top: 30px;}
    .container_five{max-width: 89%;}
    .container.container_six h1{line-height: 110px;font-size: 110px;}
    .container.container_six label {font-size: 40px;line-height: 45px;}
    .container.container_seven h2 {font-size: 128px;line-height: 110px;}
    .container.container_seven p {font-size: 36px;line-height: 50px;}
    .container.container_seven{top:180px;max-width: 92%;}
    .container.container_eight h2 {font-size: 128px;line-height: 132px;}
    .container.container_nine h3 {font-size: 90px;line-height: 90px;}
    .container.container_nine p {font-size:52px;    line-height:55px;    text-transform: uppercase;}
    .container.container_nine .section-content .text img {width: 82%;    margin-top: 100px;}
    .container-fluid.container_ten .section-content .text .center img {width: 85%;}
    .container-fluid.container_eleven h2 {font-size:72px;line-height:62px;}
    .container-fluid.container_eleven p{font-size:32px;line-height:38px;}
    .container-fluid.container_eleven h3{font-size:45px;margin: 3px 0px;line-height: 52px;}
    .container-fluid.container_eleven .section-content .text img {position: absolute;right:15px;top:-35px;width:90%;}
    .container_six .text img {width:80%;right: -170px;    top: -35px;}
    .container.container_eight p {font-size:45px;line-height: 70px;}
    .container.container_eight h3{    line-height:50px;font-size:70px;}
    .container.container_eight .text .text-in{padding-left: 100px;}
    .container.container_eight{max-width: 92%;top: 120px;}
    .container-fluid.container_six h2 {font-size: 100px;line-height: 100px;}
    .container-fluid.container_six label {font-size:36px;line-height: 50px;}
    .product_pricing a{font-size:15px;margin-top:0px;}
    .featur_pro_list .row .col-md-4 .one_sec_box{width: 100%;}
    section.featur_pro_list {margin:60px 60px;}
    .hot_pro_list{margin: 25px 40px;padding-top: 0;}
    h3.title{font-size: 28px;}
    .container.container_eight .section-content .text img {width: 86%;}
    a.view_all_btn{font-size: 20px;    margin-bottom: 10px;}
    .one_hot_power_bank.hot_prod3{width: 100%;}
    .hot_prod4 {width: 100%;}
    .one_hot_carger.hot_prod5 {width: 100%;}
    .one_sec_box h3 {font-size:22px;}
    .one_sec_box a{font-size:18px;padding: 6px 18px;}
    .main_menu ul li a{font-size: 14px;}
    .full_container {max-width: 92%;width: 92%;}
    .one_hot_power_bank.hot_prod3{height: 768px;padding-top: 100px;}
    .one_hot_produ.hot_prod2{    padding: 55px 20px;}
    .container-fluid.container_ten h2{font-size:80px;line-height:65px;}
    .container-fluid.container_ten .right img{    width: 408px !important;}
    .container-fluid.container_ten {top: 145px;}
    .container-fluid.container_ten p {font-size:40px;      line-height: 58px;}
    section.hot_pro_list{padding-top: 0px !important;padding-bottom: 20px !important;padding: 60px 20px;}
    .asuper .container-fluid .left-text h1 {font-size:70px;}
    .super-mob-list ul li .text h4{font-size: 26px;line-height: 28px;min-height: 80px;}
    .design-prefection .container-fluid .cols .text h2{font-size:85px;line-height:85px;}
    .design-prefection .container-fluid .cols img {width:85%;}
    .design-prefection .container-fluid{top: 100px;}
    .days-battery .container-fluid .cols .text img{width: 225px;}
    .days-battery .container-fluid .pro-img img {width:70%;}
    .days-battery .container-fluid .cols .text span{font-size: 430px;color: #fff;font-weight: 700;line-height: 325px;}
    .days-battery .container-fluid .cols .text .right-text {margin-top: 190px;}
    .days-battery .container-fluid .cols .text .right-text h2{font-size: 80px;line-height: 75px;}
    .days-battery .container-fluid .cols .text .right-text h2 span{font-size: 46px;line-height: 48px;}
    .days-battery .container-fluid .cols p{font-size: 28px;padding-left: 148px;line-height: 40px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color{width:450px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color h2{font-size:82px;line-height:70px;}
    .smart-ai-battery .container-fluid .flex .left h5{font-size:48px;padding-right: 50px;padding-bottom: 6px;}
    .smart-ai-battery .container-fluid .flex .left p{font-size:48px;line-height: 50px;padding-left: 20px;}
    .smart-ai-battery .container-fluid .right img {width:100%;}
    .bt-support .container-fluid .left img {width:72%;}
    .bt-support .container-fluid .right h2{font-size:85px;line-height:80px;}
    .bt-support .container-fluid .right p {font-size: 30px;line-height: 40px;}
    .bt-support .container-fluid .right img{width: 100%;}
    .expand-moory .container-fluid{top:70px;}
    .expand-moory .container-fluid .text h2{font-size:85px;margin-bottom: 35px;line-height:80px;}
    .expand-moory .container-fluid .text p{letter-spacing: 5px;font-size: 42px;}
    .expand-moory .container-fluid .text .center img {width:80%;float: right;}
    .auto-call-recording .container-fluid{bottom:75px;}
    .auto-call-recording .container-fluid .text h2{font-size: 100px;line-height:85px;}
    .auto-call-recording .container-fluid .text p{margin-top:20px;font-size:38px;line-height:48px;}
    .military-grade .container-fluid .flex .left h2 {font-size:85px;}
    .military-grade .container-fluid .flex .left p{    line-height: 60px;font-size:42px;}
    .military-grade .container-fluid .flex .right img {width: 100%;}
    .military-grade .container-fluid .flex .left{margin-top: 130px;}
    .design-prefection .container-fluid .cols .text h2{margin-top: 100px;}
    .sub_menu_dropdown{top:50px !important;}
    .main_header.fixed_header .sub_menu_dropdown {top:50px !important;}
    .inner_banner{margin-top: 75px;}
    .inner_banner_content h2 {font-size: 55px;}
    .inner_banner_content p {font-size: 32px;}
    .section_title h4{font-size: 42px;}
    .section_title p{font-size: 18px;}
    .one_core_value h4{font-size: 28px;}
    .one_core_value p{    font-size: 18px;}
    .our_leadship_content h6 {font-size: 20px;}
    .our_leadship_content p{font-size: 18px;}
    .content_le_team h6{font-size: 22px;font-size: 18px;}
    .one_team_impect h6 {font-size: 48px;}
    .one_team_impect p{font-size: 18px;}
    .impec_img p{font-size: 18px;}
    .media_cnter ul li h4{font-size: 24px;}
    .media_cnter ul li a{font-size: 20px;}

    .ip_content h4{font-size: 28px;margin-bottom: 10px;}
    .military-grade .container-fluid{top: 100px;}
    .container_two ul li .flex-col.lighting.lf_f {margin-top: 10px;}
    .container_two ul li .text p{margin-top: 5px;line-height: 20px;}
}

@media (max-width:1798px){
    .blazefiveg_eight .blaze_left_text .text img {height: 130px;}
    .herosixhundred_one .herosixhundred-one{top: 80px;}
    .blazefiveg_twelve .two p{font-size: 35px;}
    .blaze-clean-ui .one_detail_content h2 {font-size: 40px;}
    .blaze-macro-camera .one_detail_content p {font-size: 28px !important; line-height: 40px;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 38px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center{margin-top: 20px;}
    .beauty-mode .one_detail_content .beauty_mode p{line-height: 50px;}
    .one_detail_content p {font-size: 50px;line-height: 65px;}
    .prow_detalpage section .banner-height {height: 750px;}
    .one_detail_content p{font-size: 35px;}
    .one_detail_content h4{font-size: 35px;margin-bottom: 10px;}
    .one_detail_content h3 {font-size: 55px;line-height: 50px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h2 {font-size: 60px;line-height: 62px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h1 {font-size: 90px;}
    .bottom_list ul li .texts p{font-size: 26px !important;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in .pro-img img {width: 80%;padding-left: 10%;margin-top: 80px;}
    .zthree.zthree-bg-four .zthree-bg-four-in .bottom-img img {margin-top: 18%;}
    .herosixhundred_one .bg-height {height: 840px;width: 100%;}
    .herosixhundred_three .herosixhundred_three_in .center img{width: 55%;}
    .herosixhundred_three .herosixhundred_three_in{top: 150px;}
    .container_two ul li .flex-col.lighting.lf_f {margin-top: 9px;}
    .champion_three .champion_three_in h2 {margin-bottom: 80px;}
    .champion_nine .champion_nine_in p {font-size: 30px;}
    .champion_eight .champion_eight_in p {margin-bottom: 110px;}
    .champion_eight .champion_eight_in h2{font-size: 68px;}
    .champion_seven .champion_seven_in img {width: 82%;}
    .champion_six .champion_six_in p {font-size: 34px;line-height: 42px;margin-top: 25px;margin-bottom: 80px;}
    .champion_six .champion_six_in h2{font-size: 90px;line-height: 90px;}
    .champion_five .champion_five_in .text p{font-size: 35px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img {width: 68%;}
    .athreesuper .bg-height {height: 885px;}
    .afive_nine .afive_nine_in .left p {font-size: 30px;line-height: 50px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p {font-size: 40px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in img.memory{width: 28%;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .pro img{width: 45%;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text p {font-size: 43px;line-height: 45px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text p{font-size: 38px;line-height: 42px;}
    .battery-lifes .container-fluid .battery-life-in .text h5{font-size: 58px;line-height: 75px;}
    .music-lovers .container-fluid .music-lovers-in .text p{font-size: 42px;}
    .design-prefection .container-fluid .cols img {width:82%;}
    .days-battery .container-fluid {top:10%;}
    .asuper .bg-height {height:775px;}
    .container.container_seven h2 {font-size: 100px;line-height: 85px;}
    .blazepro_section.eight .img {height: 1590px;}
    .container_one .pro-img img {width: 93%;}
    .search_bar_dropdown{width:22%;}
    .container-fluid.container_six h2 {font-size: 105px;line-height: 90px;}
    .container-fluid.container_six label {font-size: 38px;line-height: 55px;}
    .container_four .section-content p {line-height: 45px;font-size: 36px;}
    .container.container_eight .section-content .text img {width:86%;}
    .container_three .section-content h2 {font-size: 90px;line-height: 98px;}
    .one_hot_power_bank.hot_prod3 {height: 735px;}
    .container_three {top: 115px;}
    .container_four .section-content h2 {font-size: 90px;line-height: 80px;}
    .container_four .section-content label {font-size: 50px;}
    .fiftympproduct img {width: 82%;}
    .container_six .text img {width: 85%;}
    .container-fluid.container_ten .section-content .text .center img {width: 88%;}
    section.featur_pro_list {margin: 50px 55px;}
    section.hot_pro_list {padding: 60px 15px;}
    .featur_pro_list .row .col-md-4:nth-child(1) .one_sec_box {padding-bottom: 0px;}
    .expand-moory .container-fluid .text p{font-size: 45px;}
    .smart-ai-battery .container-fluid .flex .left h5 {font-size:45px;}
    .military-grade .container-fluid .flex .left p{font-size:38px;line-height: 55px;}

    .container.container_eight h3 {line-height: 34px;font-size: 70px;}
    .container-fluid.container_ten p {font-size:38px;}
    .afive_three .afive_three_in .right img {width: 92%;}
    .auto-call-recording .container-fluid .text h2 {font-size: 85px;line-height: 78px;}
    .tripal-camera .one_detail_content{top: 70px;}
}

@media (max-width:1620px){
    .blazefiveg_eight .blaze_left_text .text p {line-height: 55px;font-size: 45px;}
    .product_details.blaze-enjoy .one_detail1 {top: 25%;}
    .blaze-macro-camera .one_detail_content{left: 30%;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 32px;}
    .hot_pro_row .hot_3 .one_sec_box{height: 43.5%;}
    .prow_detalpage section.one .right img {width: 44%;}
    .afive_one .afive_one_in .left-text h1 {font-size: 60px;}
    .bottom_list ul li .texts img{height: 65px;}
    .bottom_list ul li img.col_icon {height: 185px;}
    .military-grade .container-fluid .flex .left h2 {font-size: 70px;margin-bottom: 10px;}
    .auto-call-recording .container-fluid .text h2 {font-size: 70px;line-height: 65px;}
    .expand-moory .container-fluid .text h2 {font-size: 70px;margin-bottom: 20px;line-height: 70px;}
    .blaze-octa-processor .one_detail_content h2{font-size: 70px;}
    .blaze-clean-ui .one_detail_content h3{font-size: 28px;}
    .blaze-clean-ui .one_detail_content h2 {font-size: 35px;}
    .blaze-clean-ui .one_detail_content h4{font-size: 80px;line-height: 80px;}
    .blaze-octa-processor .one_detail_content h3{font-size: 35px;}
    .blaze-ram-group .one_detail_content .macro-camera h2{font-size:45px;}
    .blaze-ram-group .one_detail_content .macro-camera h2{font-size: 35px;}
    .blaze-ram-group .one_detail_content .macro-camera h2{font-size:70px;}
    .blaze-macro-camera .one_detail_content p{font-size:25px !important;margin-top: 10px;line-height:35px;}
    .blaze-macro-camera .one_detail_content h2 {font-size: 80px;}
    .blaze-dezzle .one_detail_content p {font-size: 32px;}
    .product_details.blaze-dezzle .one_detail1 .center img{top: 330px;}
    .blaze-dezzle .one_detail_content h2 span {font-size: 65px;}
    .blaze-dezzle .one_detail_content h2 {font-size: 80px;line-height: 60px};
    .blaze-dezzle .one_detail_content h2 span {font-size: 65px;}
    .beauty-mode .one_detail_content .beauty_mode p {line-height: 45px;font-size: 35px;margin-bottom: 40px;}
    .beauty-mode .one_detail_content .beauty_mode h2{font-size: 80px;}
    .one_detail_content p {font-size: 40px;line-height: 50px;}
    .tripal-camera .one_detail_content h2 {font-size: 55px;}
    .tripal-camera .one_detail_content h3{font-size: 80px;}
    .prow_detalpage section .banner-height {height: 700px;}
    .one_detail_content p {font-size: 30px;}
    .one_detail_content h4 {font-size: 30px;}
    .one_detail_content h3 {font-size: 45px;line-height: 45px;}
    .one_detail_content h1 {font-size: 80px;}
    .proul img {width: 55%;}
    .zthree.zthree-bg-eleven .text_two p {font-size: 35px;line-height: 38px;}
    .zthree.zthree-bg-eleven .text h2{font-size: 70px;line-height: 65px;}
    .bottom_list ul li .texts p {font-size: 22px !important;line-height: 24px !important;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in p{font-size: 40px;line-height: 40px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in h2 {font-size: 80px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in p{line-height: 40px;font-size: 30px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in h2 {line-height: 75px;font-size: 80px;}
    .zthree.zthree-bg-seven .zthree-bg-seven-in h2 {line-height: 80px;font-size: 80px;}
    .zthree.zthree-bg-six .zthree-bg-six-in p{font-size: 45px;}
    .zthree.zthree-bg-six .zthree-bg-six-in h2{line-height: 70px;font-size: 80px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul h6{font-size: 40px;}
    .zthree.zthree-bg-five .zthree-bg-five-in p{font-size: 40px;line-height: 40px;}
    .zthree.zthree-bg-five .zthree-bg-five-in h2 {line-height: 70px;font-size: 70px;}
    .zthree.zthree-bg-four .zthree-bg-four-in{top: 120px;}
    .zthree.zthree-bg-four .zthree-bg-four-in p {font-size: 40px;}
    .zthree.zthree-bg-four .zthree-bg-four-in h2 {line-height: 70px;font-size: 80px;}
    .zthree.zthree-bg-one .bg-height {height: 790px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h2 {font-size: 50px;line-height: 50px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h1 {font-size: 70px;}
    .herosixhundred_eight .herosixhundred_eight_in .text {margin-top: 90px;}
    .herosixhundred_eight .herosixhundred_eight_in p{font-size: 36px;margin-top: 20px;}
    .herosixhundred_eight .herosixhundred_eight_in h2{line-height:65px;padding: 0px 185px;font-size:60px;}
    .herosixhundred_eight .herosixhundred_eight_in h3 {font-size: 100px;}
    .herosixhundred_seven .herosixhundred_seven_in .left p{line-height: 40px;font-size: 40px;}
    .herosixhundred_seven .herosixhundred_seven_in .left label{line-height: 40px;font-size: 50px;}
    .herosixhundred_seven .herosixhundred_seven_in .left h2 {font-size: 75px;line-height: 80px;}
    .herosixhundred_six .herosixhundred_six_in p {font-size: 32px;line-height: 45px;}
    .herosixhundred_five .herosixhundred_five_in p {font-size: 32px;line-height: 60px;}
    .herosixhundred_five .herosixhundred_five_in h2 {font-size: 100px;line-height: 80px;}
    .herosixhundred_five .herosixhundred_five_in h2 span {font-size: 100px;}
    .herosixhundred_four .herosixhundred_four_in .text h2{line-height: 90px;font-size: 85px;}
    .herosixhundred_four .herosixhundred_four_in .text h3 span {font-size: 80px;    line-height: 100px;}
    .herosixhundred_four .herosixhundred_four_in .text h3 {font-size: 100px;}
    .herosixhundred_three .herosixhundred_three_in h2 {font-size: 100px;line-height: 108px;}
    .herosixhundred_one .bg-height {height: 725px;}
    .herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left h1 {font-size: 60px;}
    .champion_one .champion_one_in .left h1{font-size: 60px;}
    .champion_one .bg-height {height: 820px;}
    .champion_three .champion_three_in h2{font-size: 80px;line-height: 80px;}
    .champion_three .champion_three_in .right img{width: 85%;}
    .champion_four .champion_four_in .center img{margin-top: 140px;}
    .champion_five .champion_five_in .text h2{font-size: 85px;line-height: 80px;}
    .champion_six .champion_six_in p {font-size: 30px;}
    .champion_six .champion_six_in h2 {font-size: 85px;line-height: 82px;}
    .champion_seven .champion_seven_in h2{font-size: 85px;line-height: 80px;}
    .champion_seven .champion_seven_in p {font-size: 30px;line-height:45px;}
    .champion_eight .champion_eight_in p{font-size: 32px;line-height: 42px;}
    .champion_eight .champion_eight_in h2 {font-size: 56px;}
    .champion_eight .champion_eight_in .left img{width:85%;}
    .champion_nine .champion_nine_in p {font-size: 25px;line-height: 45px;}
    .champion_nine .champion_nine_in h2{font-size: 80px;line-height: 80px;}
    .champion_ten .champion_ten_in p {font-size: 32px;line-height: 42px;}
    .champion_ten .champion_ten_in h2{font-size: 80px;line-height: 80px;}
    .blazefiveg_twelve .blazefiveg_twelve_in p{line-height: 48px;}
    .blazefiveg_twelve .blazefiveg_twelve_in h2{font-size: 80px;}
    .blazefiveg_eleven .blazefiveg_eleven_in p.second{font-size: 35px !important;}
    .blazefiveg_eleven .blazefiveg_eleven_in p {font-size: 38px !important;}
    .blazefiveg_eleven .blazefiveg_eleven_in h2{font-size: 65px;}
    .blazefiveg_ten .blazefiveg_ten_in h2{margin-top: 10px;font-size:45px;}
    .blazefiveg_ten .blazefiveg_ten_in label {font-size:35px;line-height: 10px;}
    .blazefiveg_ten .blazefiveg_ten_in p {font-size:40px;}
    .blazefiveg_ten .blazefiveg_ten_in h3 {font-size: 80px;line-height:80px;}
    .blazefiveg_nine .blazefiveg_nine_in label{font-size: 35px;line-height: 30px;}
    .blazefiveg_nine .blazefiveg_nine_in h2 {font-size: 65px;line-height: 65px;}
    .blazefiveg_seven .blazefiveg_seven_in h2{font-size: 90px;line-height: 85px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img {width: 68%;margin-top: 220px;}
    .blazefiveg_five .blazefiveg_five_in h2 {font-size: 80px;line-height:65px;}
    .blazefiveg_five .blazefiveg_five_in label {font-size:45px;}
    .blazefiveg_seven .blazefiveg_seven_in label{font-size: 55px;line-height: 50px;}
    .blazefiveg_seven .blazefiveg_seven_in p {font-size: 40px;line-height: 50px;}
    .blazefiveg_six .blazefiveg_six_in h2{font-size: 80px;line-height: 60px;}
    .blazefiveg_five .blazefiveg_five_in p {font-size:35px;line-height: 45px;}
    .blazefiveg_four .blazefiveg_four_in p {font-size: 30px;line-height: 45px;}
    .blazefiveg_four .blazefiveg_four_in label {font-size:35px;}
    .blazefiveg_four .blazefiveg_four_in img{margin-top: 80px;}
    .blazefiveg_four .blazefiveg_four_in h5 {font-size:35px;line-height:40px;}
    .blazefiveg_four .blazefiveg_four_in h2 {font-size: 80px;}
    .blazefiveg_three .blazefiveg_three_in p {font-size: 35px;line-height: 45px;}
    .blazefiveg_three .blazefiveg_three_in h1{font-size: 80px;}
    .afive_three .afive_three_in .left h2{font-size:60px;line-height:70px;}
    .afive_three .afive_three_in{bottom: 50px;}
    .afive_four .afive_four_in .left{width: 50%;}
    .afive_three .afive_three_in .left{width: 50%;}
    .afive_three .afive_three_in .right{width: 50%;}
    .afive_five .afive_five_in .left h2 span:nth-child(2) {font-size: 60px;}
    .afive_five .afive_five_in .left h2 span:first-child {font-size: 120px;}
    .afive_four .afive_four_in .left h2{font-size:60px;line-height:70px;}
    .afive_five .afive_five_in .left h2 {font-size:60px;line-height: 60px;margin-top: 100px;}
    .afive_six .afive_six_in .right p {font-size: 35px;line-height: 45px;}
    .afive_six .afive_six_in .right h2{font-size:60px;line-height:60px;margin-top: 90px;}
    .afive_six .afive_six_in .left img{width: 70%;}
    .afive_nine .afive_nine_in .left p {font-size: 25px;line-height: 38px;}
    .afive_seven .afive_seven_in .left img{width:62%;margin-top: 20px;}
    .afive_nine .afive_nine_in .left h2 {font-size: 60px;line-height: 60px;}
    .afive_seven .afive_seven_in .left h2 {font-size: 60px;margin-top: 75px;line-height:60px;}
    .afive_seven .afive_seven_in .left p {font-size: 30px;line-height: 40px;}
    .afive_eight .afive_eight_in .left p{letter-spacing: 3px;font-size: 35px;line-height: 45px;}
    .afive_eight .afive_eight_in .left h2 {font-size:60px;line-height:65px;}
    .afive_ten .afive_ten_in .right p{font-size: 35px;line-height: 40px;}
    .afive_ten .afive_ten_in .right h2{font-size:60px;line-height: 65px;}
    .afive_eleven .afive_eleven_in .left h2{font-size:60px;line-height:60px;}
    .afive_eleven .afive_eleven_in .left p {font-size: 28px;line-height: 40px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p {font-size: 35px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text p {font-size: 35px;line-height: 35px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text h2 {font-size: 80px;line-height: 78px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text p {font-size: 35px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 80px;line-height: 80px;}
    .super-ai-battery .container-fluid .battery-life-in .text p{font-size:25px;line-height:30px;}
    .super-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 80px;line-height: 75px;}
    .battery-lifes .container-fluid .battery-life-in .text h5 {font-size: 45px;line-height: 60px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 span {font-size: 60px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2{font-size: 75px;}
    .big-speaker .container-fluid .big-speaker-in .text p {font-size: 35px;line-height: 30px;}
    .big-speaker .container-fluid .big-speaker-in .text h2 {font-size: 80px;}
    .music-lovers .container-fluid .music-lovers-in .text p {font-size: 35px;}
    .music-lovers .container-fluid .music-lovers-in .text h2 {font-size: 80px;line-height: 80px;}
    .athreesuper .container-fluid .left-text {margin-top: 20px;}
    .asuper .bg-height {height: 710px;}
    .country_list {right: 6.5%;}
    .container-fluid.container_six h2 {font-size: 95px;}
    .media_cnter ul li h4{font-size: 22px;}
    .media_cnter ul li a{font-size: 16px;}
    .search_bar_dropdown {width:24%;}
    .container_three {top: 90px;}
    .container_three .section-content label {font-size: 32px;line-height: 45px;}
    .blazepro_section .container_one .section-content {width: 430px;top:22px;}
    .blazepro_section .container_one .section-content h1 {font-size: 100px;line-height: 95px;}
    .container.container_eight{max-width: 90%;}
    .section-logo img {width: 240px;}
    .container.container_eight .section-content .text img {width:85%;}
    .container-fluid.container_ten {top:120px;}
    .container-fluid.container_eleven h2 {font-size:62px;line-height: 35px;} 
    .container-fluid.container_ten p {font-size:34px;line-height:51px;}
    .container-fluid.container_ten h2 {font-size:72px;line-height:58px;}
    .one_hot_power_bank.hot_prod3 {height: 660px;padding-top: 60px;}
    .one_hot_produ.hot_prod2 {padding: 46px 20px;}
    .blazepro_section .container_one .section-content label {font-size: 41px;line-height: 48px;}
    .container.container_nine h3 {font-size: 85px;line-height: 78px;}
    .container-fluid.container_ten .right img {width: 335px !important;}
    .container_two ul li .text p {font-size: 17px;line-height: 20px;}
    .container.container_nine .section-content .text img {width: 90%;}
    .blazepro_section.ten .img {height: 1045px;}
    .container_two ul li .text h2 {font-size: 22px;    line-height: 22px;}
    .container_one .pro-img img {width:100%;margin-top: 20px;}
    .container.container_eight p {font-size: 42px;line-height: 52px;}
    .container.container_eight h3 {line-height:55px;}
    .container.container_eight h2 {line-height: 105px;}
    .container-fluid.container_eleven p {font-size:26px;line-height:32px;}
    .container-fluid.container_eleven h3 {font-size: 32px;line-height: 35px;}
    .container-fluid.container_eleven h4 {font-size: 28px;line-height: 48px;}
    .container-fluid.container_eleven .section-content .text img{width:75%;top:-10px;}
    .blazepro_section.eleven .img {height: 870px;}
    .one_sec_box h3 {font-size: 20px;}
    .design-prefection .container-fluid .cols img {width: 82%;}
    .days-battery .container-fluid .cols p {font-size:26px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color {width: 400px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color h2 {font-size: 70px;line-height:70px;}
    .smart-ai-battery .container-fluid .flex .left h5 {font-size:38px;}
    .smart-ai-battery .container-fluid .flex .left p {font-size: 40px;}
    .bt-support .container-fluid .right h2 {font-size:70px;line-height:70px;}
    .bt-support .container-fluid .right p {font-size:26px;line-height:38px;}
    .expand-moory .container-fluid .text .center img {width:80%;}
    .expand-moory .container-fluid .text p {font-size: 38px;}
    .military-grade .container-fluid .flex .left p {line-height: 50px;font-size: 34px;}
    .military-grade .container-fluid .flex .right img {width:100%;}
    .asuper .container-fluid .left-text img {width: 90%;}
    .bt-support .container-fluid .left img {width: 68%;}
    .container-fluid.container_six label {line-height:33px;font-size: 34px;}
    .container-fluid.container_six{top: 190px;}
    .days-battery .container-fluid .cols p {font-size: 22px;line-height: 32px;}
    .days-battery .container-fluid .cols .text span{line-height: 298px;font-size: 347px;}
    .days-battery .container-fluid .cols .text .right-text h2 {font-size: 70px;line-height: 70px;}
    .days-battery .container-fluid .cols .text .right-text h2 span {font-size: 38px;line-height: 38px;}
    .blazepro_section.six .img {height: 918px;}
    .blazepro_section.eight .img {height: 1460px;}
    .container.container_nine h2 {font-size: 175px;}
    .super-mob-list ul li .text h4 {font-size: 22px;line-height: 22px;min-height: 60px;}
    .design-prefection .container-fluid .cols .text h2 {font-size:75px;    line-height: 70px;}
    .herosixhundred_one .herosixhundred-one{top: 70px;}
    .herosixhundred_six .herosixhundred_six_in{top: 90px;}
    .auto-call-recording .container-fluid .text p{font-size: 30px;line-height: 35px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text h2 {font-size: 80px;}
}
@media (max-width:1440px){
    .blazefiveg_eight .blaze_left_text .text p {line-height: 45px;font-size: 38px;}
    .champion_five .champion_five_in .text h2{line-height: 60px;}
    .champion_four .champion_four_in .in p span {line-height: 26px;}
    .one_detail1 .one_detail_content p {font-size: 30px;line-height: 40px;}
    .blaze-clean-ui .one_detail_content h2 {font-size: 32px;}
    .prow_detalpage section.one .right img {width: 46%;}
    .super-ai-battery .container-fluid .battery-life-in .pro img {width: 55%;}
    .blazefiveg_five .blazefiveg_five_in p {font-size: 30px;line-height: 45px;margin-bottom: 35px;}
    .blazefiveg_five .blazefiveg_five_in label {font-size: 40px;}
    .blazefiveg_five .blazefiveg_five_in h2 {font-size: 70px;line-height: 55px;}
    .blazefiveg_four .blazefiveg_four_in{top: 80px;}
    .blazefiveg_four .blazefiveg_four_in label {font-size: 30px;line-height: 40px;}
    .blazefiveg_four .blazefiveg_four_in h2 {font-size: 70px;line-height: 60px;}
    .blazefiveg_three .blazefiveg_three_in p {font-size: 30px;line-height: 35px;}
    .blazefiveg_three .blazefiveg_three_in h1 {font-size: 70px;}
    .bottom_list ul li img.col_icon {height: 150px;}
    .container_two.container.zthree_two .blaze_pro_list ul li .flex-col.lighting .text {top: -10%;}
    .battery-pro-list li .text .poab p {font-size: 16px;margin-bottom: 0;}
    .battery-pro-list li .text .poab h5{font-size: 18px;}
    .battery-pro-list li .text .poab img {width: 55px;height: 55px;}
    .asuper .container-fluid .left-text h1 {font-size: 60px;}
    .blaze-clean-ui .one_detail_content h4 {font-size: 70px;line-height: 75px;}
    .blaze-ram-group .one_detail_content .macro-camera h2 {font-size: 30px;}
    .blaze-ram-group .one_detail_content .macro-camera label{font-size:28px;line-height:25px;}
    .blaze-ram-group .one_detail_content .macro-camera h2{font-size:40px;}
    .blaze-macro-camera .one_detail_content h2 {font-size: 70px;}
    .blaze-dezzle .one_detail_content p {font-size: 30px;letter-spacing: 0;}
    .blaze-dezzle .one_detail_content h2 {    margin-bottom: 5px;font-size: 70px;line-height: 55px;}
    .blaze-dezzle .one_detail_content h2 span {font-size: 60px;}
    .beauty-mode .one_detail_content .beauty_mode p {line-height: 40px;font-size: 30px;}
    .beauty-mode .one_detail_content .beauty_mode h2 {font-size: 70px;}
    .one_detail_content p {font-size: 35px;line-height: 40px;}
    .tripal-camera .one_detail_content h2 {font-size: 45px;line-height: 60px;}
    .tripal-camera .one_detail_content h3 {font-size: 70px;line-height: 55px;}
    .prow_detalpage section .banner-height {height: 650px;}
    .one_detail_content p {font-size: 26px;}
    .one_detail_content h3 {font-size: 40px;}
    .one_detail_content h1 {font-size:70px;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 30px;}
    .product_details.blaze-dezzle .one_detail1 .center img {top: 290px;}
    .afive_eleven .afive_eleven_in .left p {font-size: 26px;}
    .zthree.zthree-bg-one .bg-height {height: 700px;}
    .zthree.zthree-bg-eleven .text_two p {font-size: 30px;}
    .zthree.zthree-bg-eleven .text h2 {font-size:60px;line-height: 60px;}
    .bottom_list ul li .texts img{height:42px;margin-top:5px;}
    .bottom_list ul li .texts{padding: 10px;}
    .tripal-camera .one_detail_content p {font-size: 35px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text {margin-top:60px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in p {font-size: 30px;line-height: 30px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in h2 {font-size: 70px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in p {line-height: 35px;font-size: 26px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in .pro-img img {width: 70%;margin-top: 40px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in h2 {line-height: 65px;font-size: 70px;}
    .zthree.zthree-bg-seven .zthree-bg-seven-in h2 {line-height: 70px;font-size: 70px;}
    .zthree.zthree-bg-six .zthree-bg-six-in p {font-size: 40px;}
    .zthree.zthree-bg-six .zthree-bg-six-in h2 {line-height: 60px;font-size: 70px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul h6 {font-size: 35px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul p {font-size: 26px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col {margin-top: 30px;}
    .zthree.zthree-bg-four .zthree-bg-four-in p {font-size: 35px;}
    .zthree.zthree-bg-four .zthree-bg-four-in h2 {line-height: 60px;font-size: 70px;}
    .herosixhundred_eight .herosixhundred_eight_in .center img{width: 90%;}
    .herosixhundred_eight .herosixhundred_eight_in p {font-size: 28px;letter-spacing: 1px;margin-top: 10px;}
    .herosixhundred_eight .herosixhundred_eight_in h3 {font-size: 70px;line-height: 60px;}
    .herosixhundred_eight .herosixhundred_eight_in h2 {line-height:42px;    font-size: 45px;}
    .herosixhundred_eight .herosixhundred_eight_in h3 {font-size: 70px;}
    .herosixhundred_seven .herosixhundred_seven_in .left p {line-height: 32px;font-size: 32px;}
    .herosixhundred_seven .herosixhundred_seven_in .left label{font-size: 40px;}
    .herosixhundred_seven .herosixhundred_seven_in .left h2 {font-size: 62px;line-height: 62px;}
    .herosixhundred_six .herosixhundred_six_in{top: 130px;}
    .herosixhundred_six .herosixhundred_six_in p {font-size: 28px;line-height: 40px;}
    .herosixhundred_six .herosixhundred_six_in h2{font-size: 70px;line-height: 70px;}
    .herosixhundred_five .herosixhundred_five_in .text {padding-left: 38%;}
    .herosixhundred_five .herosixhundred_five_in p {font-size: 26px;line-height: 50px;}
    .herosixhundred_five .herosixhundred_five_in h2 span {font-size: 70px;line-height: 70px;}
    .herosixhundred_five .herosixhundred_five_in h2 {font-size: 70px;line-height: 70px;}
    .herosixhundred_four .herosixhundred_four_in .text p {font-size: 32px;line-height: 38px;}
    .herosixhundred_four .herosixhundred_four_in .text h2 {line-height: 70px;font-size: 70px;}
    .herosixhundred_four .herosixhundred_four_in .text h3 span {font-size: 70px;line-height: 70px;}
    .herosixhundred_four .herosixhundred_four_in .text h3 {font-size:90px;line-height: 90px;}
    .herosixhundred_three .herosixhundred_three_in h2 {font-size: 70px;line-height: 78px;}
    .herosixhundred_one .bg-height {height:660px;}
    .blazefiveg_eleven .blazefiveg_eleven_in p{margin-top: 5px;margin-bottom: 5px;font-size: 35px !important;
        line-height: 45px;}
    .blazefiveg_twelve .blazefiveg_twelve_in p {font-size: 30px;line-height: 30px;}
    .blazefiveg_twelve .blazefiveg_twelve_in h2 {font-size: 70px;line-height: 80px;}
    .container_two ul li .flex-col.lighting.lf_f {margin-top: 7px;}
    .container_two ul li .text p{line-height:18px;}
    .champion_one .champion-one{bottom: 35px;}
    .champion_one .bg-height {height: 750px;}
    .blazefiveg_eleven .blazefiveg_eleven_in label {font-size: 35px;}
    .champion_three .champion_three_in h2 {font-size: 70px;line-height: 70px;}
    .champion_four .champion_four_in .center img {margin-top: 85px;}
    .champion_four .champion_four_in .in p {font-size: 32px;line-height:35px;}
    .champion_four .champion_four_in h2 {font-size: 70px;line-height: 70px;}
    .champion_four .champion_four_in .in .text label{height: 160px;font-size: 70px;}
    .champion_four .champion_four_in h2{font-size: 70px;line-height: 70px;}
    .champion_five .champion_five_in .text p {font-size:28px;}
    .champion_five .champion_five_in .text h2 {font-size: 70px;}
    .champion_five .champion_five_in .left-img img {width: 88%;}
    .champion_six .champion_six_in p {font-size: 27px;margin-bottom: 20px;line-height: 30px;}
    .champion_six .champion_six_in h2 {font-size: 75px;line-height: 75px;}
    .champion_six .champion_six_in h2 {font-size: 70px;line-height: 70px;}
    .champion_seven .champion_seven_in h2 {font-size: 70px;line-height: 70px;}
    .champion_seven .champion_seven_in p {font-size: 26px;line-height: 40px;}
    .champion_eight .champion_eight_in p {margin-bottom: 55px;}
    .champion_eight .champion_eight_in .left img {width: 88%;}
    .champion_eight .champion_eight_in .text{width: 67%;}
    .champion_nine .champion_nine_in h2 {font-size: 70px;line-height: 70px;}
    .champion_ten .champion_ten_in p {font-size: 27px;line-height: 38px;}
    .champion_ten .champion_ten_in h2 {font-size: 70px;line-height: 60px;}
    .blazefiveg_seven .blazefiveg_seven_in p {font-size: 48px;}
    .blazefiveg_seven .blazefiveg_seven_in label {font-size: 45px;}
    .blazefiveg_seven .blazefiveg_seven_in p {font-size: 35px;}
    .blazefiveg_ten .blazefiveg_ten_in{    top: 80px;}
    .blazefiveg_eleven .blazefiveg_eleven_in h2 {font-size: 60px;}
    .blazefiveg_ten .blazefiveg_ten_in h3 {font-size: 70px;line-height: 70px;}
    .blazefiveg_nine .blazefiveg_nine_in label {font-size: 30px;line-height: 10px;}
    .blazefiveg_nine .blazefiveg_nine_in p{font-size: 30px;line-height: 40px;margin-top: 40px;}
    .blazefiveg_nine .blazefiveg_nine_in h2 {font-size: 60px;line-height: 65px;}
    .afive_nine .afive_nine_in .left p {font-size: 26px;line-height:44px;}
    .afive_eight .afive_eight_in .left .m-image img {width: 50%;}
    .blazefiveg_seven .blazefiveg_seven_in h2 {font-size: 70px;line-height: 70px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img{margin-top: 190px;}
    .blazefiveg_six .blazefiveg_six_in p {font-size: 30px;line-height: 35px;}
    .blazefiveg_six .blazefiveg_six_in h2 {font-size: 70px;line-height: 55px;}
    .afive_eight .afive_eight_in .left p {font-size: 35px;line-height: 52px;}
    .afive_seven .afive_seven_in .left p {letter-spacing: 5px;}
    .afive_seven .afive_seven_in .left img {width: 55%;}
    .afive_nine .afive_nine_in .left {width: 75%;}
    .afive_six .afive_six_in .right p {letter-spacing: 2px;}
    .athreesuper .bg-height {height: 700px;}
    .music-lovers .container-fluid .music-lovers-in .text h2 {font-size: 70px;line-height:75px;}
    .big-speaker .container-fluid .big-speaker-in .text h2 {font-size: 70px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p {font-size: 28px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text h2 {font-size: 70px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text h2 {font-size: 70px;line-height: 70px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text p {font-size: 30px;line-height: 35px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 70px;line-height: 70px;}
    .ten-days {margin-right: -30px;}
    .ten-days img{width: 65%;}
    .athreesuper .container-fluid .left-text h1 {font-size: 70px;}
    .super-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 70px;line-height: 70px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2{line-height: 50px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 span {font-size: 48px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 {font-size: 60px;}
    .battery-lifes .container-fluid .battery-life-in .text h5 {font-size: 40px;line-height: 56px;}
    .music-lovers .container-fluid .music-lovers-in .text p {font-size: 30px;line-height: 42px;}
    .asuper .bg-height {height: 680px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color{padding-top: 150px;}
    .days-battery .container-fluid .cols .text .right-text h2 span {font-size: 35px;line-height: 48px;}
    .days-battery .container-fluid .cols .text .right-text h2 {font-size: 68px;line-height: 60px;}
    .blazepro_section.six .img {height: 840px;}
    .search_bar_dropdown {width:28%;}
    .country_list {right: 7%;}
    .container.container_nine .section-content .text img{margin-top:50px;}
    .container.container_eight .section-content .text img {width: 86%;}
    .one_hot_power_bank.hot_prod3 {height: 615px;padding-top: 45px;}
    .container_one .pro-img img {width:100%;margin-top: 0;}
    .proul img {width:52%;margin-top: 0px;}
    .container_three .section-content h2 {font-size: 80px;line-height: 80px;}
    .container_three .section-content label {font-size: 32px;line-height: 35px;}
    .blazepro_section .container_one .section-content h1 {font-size: 100px;line-height: 88px;}
    .blazepro_section .container_one .section-content label {font-size:35px;line-height: 38px;}
    .section-logo img {width:220px;}
    .blazepro_section .container_one .section-content {width:369px;}
    .fiftympproduct img {width: 75%;}
    .container-fluid.container_six h2 {font-size:86px;line-height: 80px;}
    .container-fluid.container_six label{font-size: 31px;}
    .container.container_nine h3 {font-size:75px;line-height:75px;}
    .container.container_nine p {font-size:42px;line-height: 40px;}
    .container-fluid.container_eleven .section-content .text img{width: 91%;right: 15px;top: -30px;}
    .container-fluid.container_eleven h2 {font-size:55px;line-height:45px;    margin-bottom: 8px;}
    .container-fluid.container_eleven p {font-size:20px;line-height:22px;}
    .container-fluid.container_eleven h3 {font-size:27px;line-height: 36px;}
    .container-fluid.container_eleven h4{font-size:25px;line-height: 30px;}
    section.featur_pro_list {margin: 50px 38px;}
    section.hot_pro_list {padding: 60px 0px;}
    .container-fluid.container_eleven {top: 75px;}
    .container-fluid.container_ten h2 {font-size:60px;line-height:48px;}
    .container-fluid.container_ten p {font-size:28px;line-height: 40px;margin-top: 0;}
    .container.container_seven {top: 130px;}
    .design-prefection .container-fluid .cols .text h2 {font-size:65px;line-height:65px;}
    .design-prefection .container-fluid .cols img {width:80%;}
    .days-battery .container-fluid {top:50px;}
    .days-battery .container-fluid .cols p {font-size:18px;line-height:28px;}
    .days-battery .container-fluid .pro-img img {width:78%;}
    .smart-ai-battery .container-fluid .flex .left .bg-color h2 {font-size: 60px;line-height: 61px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color {width: 337px;}
    .smart-ai-battery .container-fluid .flex .left h5 {font-size: 32px;}
    .smart-ai-battery .container-fluid .flex .left p {font-size: 34px;line-height: 35px;}
    .bt-support .container-fluid .right p {font-size: 22px;line-height: 30px;}
    .expand-moory .container-fluid .text h2 {font-size: 60px;line-height:60px;}
    .expand-moory .container-fluid .text p {font-size: 32px;}
    .auto-call-recording .container-fluid .text h2 {font-size:60px;line-height: 60px;}
    .military-grade .container-fluid .flex .left h2 {font-size:60px;}
    .military-grade .container-fluid .flex .left p {line-height:42px;font-size:28px;}
    .military-grade .container-fluid .flex .right img {width: 100%;    margin-left: 0px;}
    .days-battery .container-fluid{padding: 0px 55px;}
    .expand-moory .container-fluid{padding: 0px 60px;}
    .auto-call-recording .container-fluid{padding: 0px 40px;}
    .military-grade .container-fluid{padding: 0px 55px;}
    .design-prefection .container-fluid{padding: 0px 55px;}
    .blazepro_section.one .img {height: 730px;}
    .blazepro_section.three .img {height: 1000px;}
    .blazepro_section.four .img {height: 1055px;}
    .container.container_eight h2 {font-size: 90px;line-height: 90px;}
    .container.container_eight h3{font-size: 48px;line-height: 45px;margin-bottom: 0;}
    .container.container_eight p {font-size: 36px;}
    .blazepro_section.eight .img {height: 1320px;}
    .blazepro_section.eleven .img {height: 785px;}
    .blazepro_section.ten .img {height: 965px;}
    .container.container_nine h2 {font-size: 158px;}
    .blazepro_section.nine .img {height: 1280px;}
    .bottom_list {bottom: 0px;}
}

@media (max-width:1366px){
    .blazefiveg_eight .blaze_left_text .text img {height: 100px;margin-left: -20px;}
    .blazefiveg_eight .blaze_left_text .text h2 {font-size: 90px;line-height: 70px;}
	.hot_pro_row .hot_3 .one_sec_box {height: 44.5%;}
    .champion_four .champion_four_in .in p span {line-height: 25px;}
    .one_detail1 .one_detail_content p {font-size: 24px;line-height: 35px;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 28px;}
    .afive_one .afive_one_in .left-text h1{font-size: 50px;}
    .blaze-clean-ui .one_detail_content h4 {font-size: 60px;}
    .blaze-octa-processor .one_detail_content h3 {font-size:30px;line-height: 35px;}
    .blaze-octa-processor .one_detail_content h2 {font-size: 60px;}
    .blaze-ram-group .one_detail_content .macro-camera h2 {font-size: 26px;}
    .blaze-ram-group .one_detail_content .macro-camera h3 {font-size: 60px;}
    .blaze-macro-camera .one_detail_content h2 {font-size: 60px;line-height: 50px;}
    .product_details.blaze-dezzle .one_detail1 .center img {top: 270px;}
    .blaze-dezzle .one_detail_content p {font-size: 26px;}
    .blaze-dezzle .one_detail_content h2 span {font-size: 50px;}
    .blaze-dezzle .one_detail_content h2 {font-size: 60px;line-height: 50px;}
    .beauty-mode .one_detail_content .beauty_mode p {line-height: 35px;font-size: 26px;}
    .beauty-mode .one_detail_content .beauty_mode h2 {font-size: 60px;}
    .tripal-camera .one_detail_content h2 {font-size: 40px;line-height: 50px;}
    .tripal-camera .one_detail_content h3 {font-size: 60px;line-height: 50px;}
    .blaze-clean-ui .one_detail_content h3 {font-size: 25px;}
    .blaze-clean-ui .one_detail_content h2 {font-size:30px;}
    .blaze-macro-camera .one_detail_content p {font-size: 22px !important;line-height: 30px;}
    .prow_detalpage section .banner-height {height: 620px;}
    .one_detail_content h4 {font-size: 26px;}
    .one_detail_content h3 {font-size:30px;line-height: 50px;}
    .one_detail_content h1 {font-size: 60px;}
    .zthree.zthree-bg-one .zthree-bg-one-in .one-half .text img {margin-top: 90px;}
    .zthree.zthree-bg-one .bg-height {height: 610px;}
    .zthree.zthree-bg-eleven .text h2 {font-size: 60px;line-height: 55px;}
    .bottom_list ul li .texts p {font-size:16px !important;line-height:20px !important;margin-top: 12px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in h2 {font-size: 60px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in h2 {line-height: 56px;font-size: 60px;}
    .zthree.zthree-bg-seven .zthree-bg-seven-in h2 {line-height: 60px;font-size: 60px;}
    .zthree.zthree-bg-six .zthree-bg-six-in p {font-size: 35px;}
    .zthree.zthree-bg-six .zthree-bg-six-in h2{font-size: 60px;margin-bottom: 5px;}
    .zthree.zthree-bg-five .zthree-bg-five-in p {font-size: 35px;}
    .zthree.zthree-bg-five .zthree-bg-five-in h2 {line-height: 60px;font-size: 60px;}
    .zthree.zthree-bg-four .zthree-bg-four-in {top: 90px;}
    .zthree.zthree-bg-four .zthree-bg-four-in p {font-size: 30px;line-height: 38px;}
    .zthree.zthree-bg-four .zthree-bg-four-in h2 {line-height: 50px;font-size: 60px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h2 {font-size: 40px;line-height: 42px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h1 {font-size: 60px;}
    .herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left h1 {font-size: 50px;}
    .herosixhundred_seven .herosixhundred_seven_in .left h2 {font-size: 58px;}
    .herosixhundred_seven .herosixhundred_seven_in .right img {width: 95%;}
    .champion_seven .champion_seven_in p {font-size: 24px;line-height:38px;}
    .champion_nine .champion_nine_in p {font-size: 22px;line-height: 35px;}
    .champion_one .champion_one_in .left h1 {font-size: 50px;}
    .champion_ten .champion_ten_in img {width: 58%;}
    .champion_ten .champion_ten_in h2 {font-size: 60px;line-height: 60px;}
    .champion_six .champion_six_in h2 {font-size: 60px;line-height: 60px;}
    .champion_seven .champion_seven_in h2 {font-size: 60px;line-height: 60px;}
    .champion_nine .champion_nine_in h2 {font-size: 60px;line-height: 68px;}
    .champion_eight .champion_eight_in .text {width: 80%;}
    .champion_eight .champion_eight_in .left img {width:93%;}
    .champion_six .champion_six_in p {font-size: 26px;line-height: 35px;}
    .champion_four .champion_four_in h2 {font-size: 60px;line-height: 60px;}
    .champion_three .champion_three_in h2 {font-size: 60px;line-height: 60px;}
    .champion_one .bg-height {height: 670px;}
    .blazefiveg_twelve .blazefiveg_twelve_in p {font-size: 30px;line-height: 35px;}
    .blazefiveg_twelve .blazefiveg_twelve_in h2 {font-size:60px;line-height:65px;}
    .blazefiveg_eleven .blazefiveg_eleven_in{bottom: 100px;}
    .blazefiveg_eleven .blazefiveg_eleven_in label{font-size: 30px;line-height: 40px;}
    .blazefiveg_eleven .blazefiveg_eleven_in p.second {font-size: 30px !important;}
    .blazefiveg_eleven .blazefiveg_eleven_in p {font-size: 30px !important;line-height: 42px;}
    .blazefiveg_eleven .blazefiveg_eleven_in p{margin-top: 0px;}
    .blazefiveg_eleven .blazefiveg_eleven_in h2 {font-size: 52px;}
    .blazefiveg_ten .blazefiveg_ten_in h2{font-size: 45px;}
    .blazefiveg_nine .blazefiveg_nine_in{top: 100px;}
    .blazefiveg_seven .blazefiveg_seven_in label{line-height: 50px;}
    .blazefiveg_six .blazefiveg_six_in{top: 70px;}
    .blazefiveg_ten .blazefiveg_ten_in label {font-size: 35px;}
    .blazefiveg_seven .blazefiveg_seven_in p {font-size: 30px;}
    .blazefiveg_ten .blazefiveg_ten_in p {font-size:30px;}
    .blazefiveg_ten .blazefiveg_ten_in h3 {font-size:60px;line-height: 60px;}
    .blazefiveg_five .blazefiveg_five_in p{line-height: 40px;}
    .blazefiveg_six .blazefiveg_six_in h2 {font-size: 60px;line-height: 50px;}
    .blazefiveg_five .blazefiveg_five_in label {font-size: 30px;line-height: 40px;}
    .blazefiveg_five .blazefiveg_five_in h2 {font-size: 60px;line-height: 50px;}
    .blazefiveg_four .blazefiveg_four_in p {font-size: 25px;line-height: 35px;}
    .blazefiveg_four .blazefiveg_four_in label {font-size: 30px;line-height: 45px;}
    .blazefiveg_four .blazefiveg_four_in h5 {font-size:30px;line-height:30px;}
    .blazefiveg_four .blazefiveg_four_in h2 {font-size:60px;line-height: 60px;}
    .blazefiveg_three .blazefiveg_three_in h1 {font-size:60px;}
    .blazefiveg_seven .blazefiveg_seven_in h2 {font-size: 60px;line-height: 60px;}
    .blazefiveg_three .blazefiveg_three_in p {font-size: 26px;line-height: 30px;}
    .afive_three .afive_three_in .left h2 {font-size:50px;line-height:60px;}
    .afive_four .afive_four_in .left h2 {font-size:50px;line-height:50px;}
    .afive_six .afive_six_in .right h2 {font-size: 50px;line-height: 50px;}
    .afive_five .afive_five_in .left h2 span:nth-child(2) {font-size: 50px;}
    .afive_five .afive_five_in .left h2 span:first-child {font-size: 90px;}
    .afive_five .afive_five_in .left h2 {font-size:50px;line-height:50px;}
    .afive_seven .afive_seven_in .left p{font-size: 28px;}
    .afive_seven .afive_seven_in .left h2 {font-size:50px;}
    .afive_eight .afive_eight_in .left p {font-size: 28px;line-height:35px;}
    .afive_eight .afive_eight_in .left h2 {font-size:50px;line-height:50px;}
    .afive_nine .afive_nine_in .left p {font-size: 22px;line-height: 30px;}
    .afive_nine .afive_nine_in .left{width: 70%;}
    .afive_nine .afive_nine_in .left h2 {line-height:50px;font-size:50px;}
    .afive_eleven .afive_eleven_in .left p {font-size: 20px;line-height: 34px;}
    .afive_eleven .afive_eleven_in .left h2 {font-size:50px;line-height:48px;}
    .afive_ten .afive_ten_in .right p {font-size: 30px;line-height: 34px;}
    .afive_ten .afive_ten_in .right h2 {font-size:50px;line-height:50px;}
    .afive_seven .afive_seven_in .left img {width:55%;}
    .afive_six .afive_six_in .right p {font-size: 30px;line-height: 35px;}
    .afive_four .afive_four_in .right{width:50%;}
    .athreesuper .container-fluid .left-text h1 {font-size: 60px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p {font-size: 30px;line-height: 30px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text h2 {font-size: 60px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text p {font-size: 30px;line-height: 30px;margin-bottom: 5px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text h2 {font-size: 60px;line-height: 64px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text p {font-size: 25px;line-height: 29px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 60px;line-height: 58px;}
    .super-ai-battery .container-fluid .battery-life-in .text p {font-size: 24px;line-height: 30px;}
    .super-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 60px;line-height: 56px;}
    .battery-lifes .container-fluid .battery-life-in .text h5 {font-size: 32px;line-height: 44px;}
    .big-speaker .container-fluid .big-speaker-in .text p {font-size: 28px;}
    .big-speaker .container-fluid .big-speaker-in .text h2 {font-size: 60px;}
    .music-lovers .container-fluid .music-lovers-in .text h2 {font-size: 60px;line-height: 65px;}
    .music-lovers .container-fluid .music-lovers-in .text p {font-size: 24px;line-height:30px;}
    .asuper .bg-height {height: 675px;}
    .asuper .container-fluid .left-text img {width: 100%;}
    .one_sec_box h5{font-size: 18px;}
    .one_sec_box h3 {font-size: 18px;}
    .ip_content h4{font-size: 24px;margin-bottom: 10px;}
    .one_sec_box a {font-size: 14px;padding: 4px 18px;    width: 130px;}

    .media_cnter ul li h4{font-size:18px;}
    .media_cnter ul li a{font-size: 15px;}
    .inner_banner{margin-top: 55px;}
    .inner_banner_content h2 {font-size:30px;}
    .inner_banner_content p {font-size:24px;}
    .section_title h4{font-size:25px;}
    .section_title p{font-size: 16px;}
    .one_core_value h4{font-size: 28px;}
    .one_core_value p{    font-size: 16px;}
    .our_leadship_content h6 {font-size: 18px;}
    .our_leadship_content p{font-size: 16px;}
    .content_le_team h6{font-size: 18px;}
    .one_team_impect h6 {font-size: 32px !important;}
    .one_team_impect p{font-size: 16px;}
    .impec_img p{font-size: 16px;}
    .blazepro_section.six .img {height: 790px;}
    .blazepro_section.four .img {height: 920px;}
    .search_bar_dropdown {width:28%;}
    .container.container_nine{top:75px;}
    .container_four{top: 75px;}
    .blazepro_section.nine .img {height: 1020px;}
    .container-fluid.container_ten {top: 75px;}
    .container.container_nine h2 {font-size: 148px;line-height: 120px;}
    .container.container_nine h3 {font-size: 73px;line-height: 69px;}
    .container.container_nine .section-content .text img{width:75%;}
    .blazepro_section .container_one .section-content {width: 302px;}
    .section-logo img {width: 180px;}
    .blazepro_section .container_one .section-content h1 {font-size:75px;line-height:70px;}
    .blazepro_section .container_one .section-content label {font-size:28px;line-height:30px;}
    .container_one .pro-img img {width:100%;margin-top: 0px;}
    .container_three {top: 70px;}
    .blazepro_section.three .img {height: 965px;}
    .container_three .section-content h2 {font-size:75px;line-height: 100px;}
    .container_three .section-content label {font-size: 34px;line-height: 40px;}
    .container.container_seven img {margin-top: 30px;}
    .container.container_seven h2 {font-size: 85px;line-height: 80px;}
    .container.container_seven {top: 140px;}
    .container.container_eight h2 {font-size: 90px;line-height:80px;}
    .container.container_eight .section-content .text img {width:85%;}
    .container-fluid.container_eleven .section-content .text img {width:90%;right:15px;}
    .container-fluid.container_ten .section-content .text .center img {width:100%;}
    .product_pricing a {font-size: 14px;}
    .main_menu ul li a {font-size: 14px;}
    h3.title {font-size: 20px;}
    .days-battery .container-fluid .cols .text .right-text h2 {font-size: 60px;line-height: 40px;}
    .days-battery .container-fluid .cols .text .right-text {margin-top: 150px;}
    a.view_all_btn {font-size: 14px;padding: 3px 10px;}
    .one_hot_power_bank.hot_prod3 {height:588px;padding-top: 40px;}
    .inner_breadcum h4 {font-size:20px;}
    .design-prefection .container-fluid .cols .text h2 {font-size: 50px;line-height: 50px;}
    .asuper .container-fluid .left-text h1 {font-size: 50px;}
    .super-mob-list ul li .text h4 {font-size:16px;line-height:18px;}
    .expand-moory .container-fluid .text h2 {font-size:50px;line-height:50px;margin-bottom: 0;}
    .expand-moory .container-fluid .text p {font-size: 28px;}
    .expand-moory .container-fluid .text .center img{margin-top:10px;}
    .auto-call-recording .container-fluid .text h2 {font-size:50px;line-height:50px;}
    .auto-call-recording .container-fluid .text p {margin-top:0px;font-size: 28px;line-height: 35px;}
    .bt-support .container-fluid .right h2 {font-size:50px;line-height:50px;}
    .bt-support .container-fluid .right {    padding: 0px 0px 0px 40px;}
    .military-grade .container-fluid .flex .left h2 {font-size:50px;}
    .military-grade .container-fluid .flex .left p {line-height: 40px;font-size: 28px;}
    .days-battery .container-fluid .cols .text span {font-size:245px;    line-height:238px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color h2 {font-size: 50px;line-height: 50px;}
    .days-battery .container-fluid .cols .text .right-text h2 span {font-size: 32px;}
    .days-battery .container-fluid .cols p{padding-left: 120px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color {width: 315px;padding-top:118px;}
    header {padding: 0px 0px;}
    .battery-pro-list li .text .poab img{width: 55px;height: 55px;}
    .selec_coun i {font-size: 20px;padding: 0;}
    .search_bar_dropdown {margin-top: 2px;}
    .battery-pro-list li .text .poab p{font-size: 15px;margin-bottom: 0;}
    .battery-pro-list li .text .poab h5{font-size: 18px;}
    .days-battery .container-fluid .pro-img img {width:80%;}
    .battery-pro-list{margin-top:38px !important;}
    .search_con_input {height: 32px;    font-size: 13px;letter-spacing: .5px;}
    .container-fluid.container_ten .right img{width: 265px !important;}
    .blazepro_section.ten .img {height:890px;}
    .blazepro_section.eight .img {height: 1250px;}
    .blazepro_section.one .img {height: 690px;}
    .herosixhundred_one .bg-height {height: 645px;}
    .herosixhundred_one .herosixhundred-one{top: 70px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul h6 {font-size: 26px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul p {font-size: 20px;}
}

@media (max-width:1280px){
    .blazefiveg_eight .blaze_left_text .text p {line-height: 40px;font-size: 35px;}
    .three_days_list ul li h6 {font-size: 18px;line-height: 20px;}
    .three_days_list ul li span {font-size: 16px;}
    .champion_four .champion_four_in .in p span {line-height: 20px;}
    .super-mob-list .container-fluid ul li .text p{font-size: 18px;line-height: 20px;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 25px;}
    .tripal-camera .one_detail_content p {font-size: 25px;}
    .hot_pro_row .hot_3 .one_sec_box {height: 44%;}
    .banner_slider{margin-top: 58px;}
    .blaze-clean-ui .one_detail_content h4 {font-size: 50px;line-height: 60px;}
    .blaze-octa-processor .one_detail_content h2 {font-size: 50px;}
    .blaze-ram-group .one_detail_content .macro-camera label {font-size: 30px;line-height: 28px;}
    .blaze-ram-group .one_detail_content .macro-camera h3 {font-size: 50px;}
    .blaze-macro-camera .one_detail_content h2 {font-size: 50px;line-height: 40px;}
    .product_details.blaze-dezzle .one_detail1 .center img {top: 238px;}
    .beauty-mode .one_detail_content .beauty_mode p {line-height: 30px;font-size: 24px;}
    .beauty-mode .one_detail_content .beauty_mode h2 {font-size: 50px;}
    .tripal-camera .one_detail_content h2 {font-size: 30px;line-height: 40px;}
    .tripal-camera .one_detail_content h3 {font-size: 50px;}
    .prow_detalpage section .banner-height {height: 585px;}
    .one_detail_content p{letter-spacing: 1px;}
    .one_detail_content h1 {font-size: 50px;}
    .blaze-dezzle .one_detail_content p {font-size: 25px;}
    .blaze-dezzle .one_detail_content h2{font-size: 50px;line-height: 40px;}
    .zthree.zthree-bg-eleven .text_two p {font-size: 25px;margin-bottom: 0;line-height: 30px;}
    .zthree.zthree-bg-eleven .text h2 {font-size: 50px;line-height: 50px;}
    .bottom_list ul li .texts p {font-size: 16px !important;line-height: 20px !important;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in p {font-size: 25px;line-height: 25px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in h2 {font-size: 50px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in{top: 90px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in h2 {line-height: 50px;font-size: 50px;margin-bottom: 5px;}
    .zthree.zthree-bg-seven .zthree-bg-seven-in h2 {line-height: 50px;font-size: 50px;}
    .zthree.zthree-bg-six .zthree-bg-six-in p {font-size: 30px;}
    .zthree.zthree-bg-six .zthree-bg-six-in h2 {font-size: 50px;line-height: 50px;margin-bottom: 0px;}
    .zthree.zthree-bg-five .zthree-bg-five-in p {font-size: 30px;}
    .zthree.zthree-bg-five .zthree-bg-five-in h2 {line-height: 50px;font-size: 50px;}
    .zthree.zthree-bg-four .zthree-bg-four-in p {font-size: 25px;line-height: 33px;}
    .zthree.zthree-bg-four .zthree-bg-four-in h2 {line-height: 50px;font-size: 50px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h2 {font-size: 30px;line-height: 30px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h1 {font-size: 50px;}
    .herosixhundred_one .bg-height {height: 600px;}
    .herosixhundred_eight .herosixhundred_eight_in p {font-size: 22px;margin-top: 10px;}
    .herosixhundred_eight .herosixhundred_eight_in h2 {line-height: 45px;font-size: 35px;}
    .herosixhundred_eight .herosixhundred_eight_in h3 {font-size: 50px;line-height: 50px;}
    .herosixhundred_seven .herosixhundred_seven_in .right img {width: 90%;}
    .herosixhundred_seven .herosixhundred_seven_in .left p{font-size: 28px;}
    .herosixhundred_seven .herosixhundred_seven_in .left label {font-size: 28px;line-height: 25px;}
    .herosixhundred_seven .herosixhundred_seven_in .left h2 {font-size: 50px;margin-bottom: 0;}
    .herosixhundred_six .herosixhundred_six_in p {font-size: 22px;line-height: 30px;}
    .herosixhundred_six .herosixhundred_six_in h2 {font-size: 50px;line-height: 50px;}
    .herosixhundred_five .herosixhundred_five_in .text {padding-left: 40%;}
    .herosixhundred_five .herosixhundred_five_in p {font-size: 22px;line-height: 42px;}
    .herosixhundred_five .herosixhundred_five_in h2 span {font-size: 55px;line-height: 50px;}
    .herosixhundred_five .herosixhundred_five_in h2 {font-size: 55px;line-height: 50px;}
    .herosixhundred_four .herosixhundred_four_in .text p {font-size: 26px;line-height: 30px;}
    .herosixhundred_four .herosixhundred_four_in .text h2 {line-height: 50px;font-size: 50px;}
    .herosixhundred_four .herosixhundred_four_in .text h3 span {font-size: 50px;line-height: 50px;}
    .herosixhundred_three .herosixhundred_three_in {top: 125px;}
    .herosixhundred_three .herosixhundred_three_in h2 {font-size: 50px;line-height: 78px;}
    .blazefiveg_five .blazefiveg_five_in h2 {margin-bottom: 10px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img {width: 65%;margin-top: 220px;}
    .champion_ten .champion_ten_in p {font-size: 20px;line-height: 31px;}
    .champion_ten .champion_ten_in h2 {font-size: 50px;line-height: 50px;}
    .champion_nine .champion_nine_in p {font-size: 20px;line-height: 35px;}
    .champion_nine .champion_nine_in h2 {font-size: 50px;line-height: 50px;}
    .champion_eight .champion_eight_in p {font-size: 24px;line-height: 35px;margin-bottom: 28px;}
    .champion_eight .champion_eight_in h2 {font-size: 50px;line-height: 44px;margin-bottom: 8px;}
    .champion_eight .champion_eight_in .left img {width: 88%;}
    .champion_seven .champion_seven_in p {font-size: 22px;line-height:35px;}
    .champion_seven .champion_seven_in h2 {font-size: 50px;line-height: 50px;}
    .champion_six .champion_six_in p {font-size: 24px;line-height: 32px;margin-top: 10px;}
    .champion_six .champion_six_in h2 {font-size: 50px;line-height: 50px;}
    .champion_five .champion_five_in .text p {font-size: 22px;}
    .champion_five .champion_five_in .text h2 {font-size: 50px;line-height: 40px;}
    .champion_four .champion_four_in .center img {margin-top: 5px;width: 80%;}
    .champion_four .champion_four_in .in p {font-size: 26px;line-height: 35px;margin-top: 10px;}
    .champion_four .champion_four_in .in .text label {height: 120px;font-size: 50px;}
    .champion_four .champion_four_in h2 {font-size: 50px;line-height: 50px;}
    .champion_three .champion_three_in h2 {font-size: 50px;line-height: 50px;}
    .blazefiveg_three .blazefiveg_three_in p {font-size: 26px;}
    .blazefiveg_five .blazefiveg_five_in label {font-size: 26px;line-height: 30px;}
    .blazefiveg_five .blazefiveg_five_in h2 {font-size: 50px;line-height: 40px;}
    .blazefiveg_four .blazefiveg_four_in p {font-size: 22px;line-height: 30px;}
    .blazefiveg_four .blazefiveg_four_in label {font-size: 24px;line-height: 36px;}
    .blazefiveg_four .blazefiveg_four_in h5 {font-size: 26px;line-height: 30px;}
    .blazefiveg_four .blazefiveg_four_in h2 {font-size: 50px;line-height: 45px;}
    .blazefiveg_three .blazefiveg_three_in h1 {font-size:50px;}
    .blazefiveg_six .blazefiveg_six_in p {font-size: 24px;line-height:28px;}
    .blazefiveg_six .blazefiveg_six_in{top: 60px;}
    .blazefiveg_nine .blazefiveg_nine_in h2 {font-size: 50px;line-height: 45px;}
    .blazefiveg_five .blazefiveg_five_in p {line-height: 30px;font-size: 26px;margin-bottom: 25px;}
    .blazefiveg_six .blazefiveg_six_in h2{font-size:50px;line-height:40px;}
    .blazefiveg_seven .blazefiveg_seven_in p {font-size:28px;line-height:35px;}
    .blazefiveg_seven .blazefiveg_seven_in label{line-height: 45px;font-size:40px;}
    .blazefiveg_seven .blazefiveg_seven_in h2 {font-size:50px;line-height:40px;}
    .blazefiveg_nine .blazefiveg_nine_in label {font-size: 25px;padding: 8px 5px;}
    .blazefiveg_nine .blazefiveg_nine_in p {font-size: 26px;line-height: 35px;margin-top: 20px;}
    .blazefiveg_nine .blazefiveg_nine_in{top: 80px;}
    .blazefiveg_ten .blazefiveg_ten_in h2 {font-size: 32px;}
    .blazefiveg_ten .blazefiveg_ten_in label {font-size: 26px;}
    .blazefiveg_ten .blazefiveg_ten_in p {font-size: 26px;margin-top: 5px;margin-bottom: 15px;}
    .blazefiveg_ten .blazefiveg_ten_in h3 {font-size:50px;line-height:50px;}
    .blazefiveg_ten .two.cum-container-width p{font-size: 26px;line-height: 28px;}
    .blazefiveg_eleven .blazefiveg_eleven_in label {font-size: 26px;line-height: 26px;}
    .blazefiveg_eleven .blazefiveg_eleven_in p.second {font-size: 26px !important;}
    .blazefiveg_eleven .blazefiveg_eleven_in p {font-size: 26px !important;line-height: 30px;}
    .blazefiveg_eleven .blazefiveg_eleven_in h2 {font-size: 45px;line-height: 50px;margin-bottom: 10px;}
    .blazefiveg_twelve .blazefiveg_twelve_in p {font-size: 26px;line-height: 30px;}
    .blazefiveg_twelve .blazefiveg_twelve_in h2 {font-size:50px;line-height:58px;}
    .afive_eleven .afive_eleven_in .left p{line-height: 25px;}
    .afive_ten .afive_ten_in .right p {font-size: 22px;line-height: 25px;}
    .afive_nine .afive_nine_in .left p{line-height: 35px;}
    .afive_eight .afive_eight_in .left p {font-size: 22px;line-height: 37px;}
    .afive_seven .afive_seven_in .left img {width: 58%;}
    .afive_seven .afive_seven_in .left p {font-size: 22px;margin-bottom: 4px;}
    .afive_six .afive_six_in .right p {font-size: 24px;line-height: 30px;}
    .afive_five .afive_five_in .left h2 span:first-child {font-size: 90px;}
    .super-mob-list.afive_two ul li .text h4 {padding: 10px;}
    .athreesuper .bg-height {height: 665px;}
    .athreesuper .container-fluid .left-text h1 {font-size: 50px;}
    .big-speaker .container-fluid .big-speaker-in .text h2 {font-size: 50px;}
    .music-lovers .container-fluid .music-lovers-in .text h2 {font-size: 50px;line-height: 55px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text{width: 496px;}
    .ten-days img {width: 50%;}
    .super-ai-battery .container-fluid .battery-life-in .pro img { width: 50%;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 {line-height: 40px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 span {font-size: 40px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 {font-size: 50px;}
    .super-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 50px;line-height: 46px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 50px;line-height: 50px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text h2 {font-size: 50px;line-height: 50px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text h2 {font-size: 50px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p {font-size: 28px;line-height: 28px;}
    .container.container_eight{top: 75px;}
    .container.container_eight h2 {font-size: 75px;line-height: 65px;}
    .container.container_eight h3 {font-size: 40px;line-height: 35px;margin-bottom: 0;}
    .container.container_eight p {font-size: 30px;}
    .container.container_nine .section-content .text img {margin-top: 45px;}
    .container.container_nine p {font-size: 38px;line-height: 35px;}
    .container.container_nine h3 {font-size: 68px;line-height: 60px;}
    .container.container_nine h2 {font-size: 138px;line-height: 105px;}
    .blazepro_section.ten .img {height: 765px;}
    .blazepro_section.eleven .img {height: 640px;}
    .media_cnter ul {display: block;}
    .media_cnter ul li h4 {font-size: 20px;}
    .media_cnter ul li a {font-size: 16px;}
    .media_cnter ul li {width: 32%;margin-bottom: 10px;}
    .search_bar_dropdown {width: 31%;}
    .container_three .section-content h2 {font-size: 70px;line-height: 80px;}
    .container_three .section-content label {font-size: 30px;line-height: 32px;}
    .container_one .pro-img img {width: 91%;}
    .blazepro_section.six .img {height: 710px;}
    .container-fluid.container_six label {font-size:23px;line-height:34px;}
    .container-fluid.container_six h2 {font-size: 65px;line-height: 65px;}
    .container-fluid.container_ten p {font-size:25px;line-height:35px;}
    .container-fluid.container_ten h2{font-size:52px;line-height:42px;}
    .featur_pro_list .row .col-md-4:nth-child(1) .one_sec_box {padding-bottom: 0px;}
    .one_hot_produ.hot_prod2 {padding: 45px 20px;}
    .one_hot_power_bank.hot_prod3 {height:560px;}
    .blazepro_section.nine .img {height: 900px;}
    .blazepro_section .container_one{top:50px;}
    .proul img {width: 56%;}
    .blazepro_section.one .img {height: 590px;}
    .blazepro_section.three .img {height: 930px;}
    .container_four .section-content label {font-size: 35px;line-height: 42px;}
    .container_four .section-content h2 {font-size: 72px;line-height: 58px;}
    .container_six .text img{top:-87px;}
    .container_four .section-content p {line-height: 38px;font-size: 26px;}
    .container.container_seven p {font-size: 24px;line-height: 30px;}
    .container_two ul li .text h2 {font-size:24px;line-height:28px;}
    .container_two ul li .text p {font-size: 15px;line-height:13px;margin-top: 3px;}
    .fiftympproduct img {width:82%;}
    .container.container_eight .section-content .text img {width:75%;}
    .container-fluid.container_ten .section-content .text .center img {width:82%;}
    .container-fluid.container_eleven .section-content .text img {width:70%;top: 8px;}
    .blazepro_section.eight .img {height: 1000px;}
    .product_pricing{padding: 7px 7px;}
    .days-battery .container-fluid .cols .text .right-text h2 span {font-size: 28px;line-height: 25px;}
    .days-battery .container-fluid .cols .text .right-text h2 {font-size: 40px;line-height: 35px;}
    .design-prefection .container-fluid .cols .text h2 {font-size: 40px;line-height: 40px;}
    .container-fluid.container_eleven h2 {font-size:45px;line-height:40px;}
    .days-battery .container-fluid .cols p {font-size:18px;line-height:22px;padding-left: 90px;}
    .battery-pro-list li .text .poab img{width: 50px;height: 50px;}
    .days-battery .container-fluid .cols .text span {font-size: 200px;line-height:168px;}
    .smart-ai-battery .container-fluid .flex .left {margin-left:62px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color {width: 260px;padding-top: 100px;}
    .smart-ai-battery .container-fluid .flex .left h5 {font-size: 24px;}
    .smart-ai-battery .container-fluid .flex .left p {font-size: 26px;line-height: 26px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color h2 {font-size: 42px;line-height: 42px;}
    .bt-support .container-fluid .right p {font-size: 20px;line-height:28px;}
    .bt-support .container-fluid .left img{margin-top: 30px;}
    .military-grade .container-fluid .flex .left p {line-height:35px;font-size: 25px;}
    .military-grade .container-fluid .flex .left h2 {font-size:50px;}
    .bt-support .container-fluid .right h2 {font-size:50px;line-height:45px;}
    .expand-moory .container-fluid .text h2 {font-size:50px;line-height:50px;}
    .expand-moory .container-fluid .text p {font-size: 25px;}
    .military-grade .container-fluid .flex .right img {width:100%;margin-top: 0;margin-left: 0;}
    .military-grade .container-fluid .flex .left {margin-top: 60px;}
    .super-mob-list ul li .text h4{min-height: 50px;}
    .design-prefection .container-fluid {padding: 0px 50px;}
    .expand-moory .container-fluid {padding: 0px 50px;}
    .asuper .container-fluid .left-text h1 {font-size: 40px;}
    .days-battery .container-fluid .cols .text .right-text h2 span {font-size: 28px;line-height: 38px;}
    .days-battery .container-fluid .cols .text .right-text h2 {font-size: 50px;line-height:35px;}
    .days-battery .container-fluid .cols .text .right-text {margin-top:90px;}
    .blaze-dezzle .one_detail_content h2 span {font-size: 40px;}
}
@media (max-width:1024px){
    .blazefiveg_eight .blaze_left_text .text img {height: 77px;margin-left: -10px;}
    .blazefiveg_eight .blaze_left_text .text p {line-height: 30px;font-size: 25px;}
    .blazefiveg_eight .blaze_left_text .center img{left: 0;margin: 0 auto;width: 55%;}
    .afive_eleven .afive_eleven_in img {width: 68%;}
    .champion_four .champion_four_in .in p span {line-height: 16px;}
    .blaze-clean-ui .one_detail_content h3 {font-size: 22px;}
    .blaze-clean-ui .one_detail_content h2 {font-size: 28px;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 20px;}
    .one_detail_content h4 {font-size: 23px;}
    .one_detail1 .one_detail_content p {font-size: 25px;letter-spacing: 2px;}
    .hot_pro_row .hot_3 .one_sec_box{height: auto;}
    .product_details.one .one_detail1 .one_detail_content{margin-top: 50px;left: 5%;top: 0px;}
    .product_details.one .one_detail1{top:0px;height: 100%;}
    .prow_detalpage section.one .right img {width: 56%;}
    .super-ai-battery .container-fluid{top: 50px;}
    .afive_ten .afive_ten_in .right p {font-size: 20px;line-height: 28px;}
    .afive_ten .afive_ten_in .right h2 {font-size: 40px;line-height: 40px;margin-bottom: 20px;}
    .afive_eleven .afive_eleven_in .left h2 {font-size: 40px;line-height: 40px; margin-bottom:5px;}
    .bottom_list ul li img.col_icon {height: 100px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center img{width: 90%;}
    .one_detail_content p{line-height: 30px;}
    .product_details.blaze-enjoy .one_detail1{top: 14%;}
    .product_details.blaze-clean-ui .one_detail1 .left img {width: 88%;}
    .product_details.blaze-ram-group .one_detail1 .left img {width: 32%;}
    .product_details.blaze-macro-camera .one_detail1 .center img {width: 80%;}
    .blaze-macro-camera .one_detail_content p {font-size: 20px;line-height: 22px;}
    .blaze-dezzle .one_detail_content p {font-size: 20px;}
    .product_details.blaze-dezzle .one_detail1 .center img {top: 210px;}
    .beauty-mode .one_detail_content .beauty_mode p {letter-spacing: 0px;}
    .tripal-camera .one_detail_content .center img {margin-top: 10px;width: 80%;}
    .asuper .container-fluid .left-text{margin-top: 0px;}
    .asuper .bg-height {height: 620px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center img{width: 85%;}
    .bottom_list ul li .texts img {height: 35px;margin-top: 0px;}
    .zthree.zthree-bg-eleven .text_two p {font-size:16px;line-height:18px;}
    .zthree.zthree-bg-eleven .text h2 {font-size: 40px;line-height: 35px;    margin-top: 35px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center {margin-top: 20px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text {margin-top: 50px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in p {font-size:20px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in h2 {font-size: 40px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in .pro-img img{margin-top: 10px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in p {line-height: 28px;font-size: 22px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in h2 {line-height: 40px;font-size: 40px;margin-bottom: 5px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in {top: 50px;}
    .zthree.zthree-bg-seven .zthree-bg-seven-in h2 {line-height: 40px;font-size: 40px;margin-top: 0;}
    .zthree.zthree-bg-six .zthree-bg-six-in p {font-size: 22px;}
    .zthree.zthree-bg-six .zthree-bg-six-in h2 {font-size: 40px;line-height: 40px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col {margin-top: 15px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul p {font-size: 16px;line-height: 30px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul h6 {font-size: 20px;}
    .zthree.zthree-bg-five .zthree-bg-five-in p {font-size: 26px;}
    .zthree.zthree-bg-five .zthree-bg-five-in h2 {line-height: 40px;font-size: 40px;margin-bottom: 0;}
    .zthree.zthree-bg-four .zthree-bg-four-in .bottom-img img {margin-top: 7%;}
    .zthree.zthree-bg-four .zthree-bg-four-in {top: 50px;}
    .zthree.zthree-bg-four .zthree-bg-four-in p {font-size: 20px;line-height: 25px;}
    .zthree.zthree-bg-four .zthree-bg-four-in h2 {line-height: 40px;font-size: 40px;}
    .zthree.zthree-bg-one .zthree-bg-one-in .one-half .text img {margin-top: 55px;}
    .zthree.zthree-bg-one .bg-height {height: 500px;}
    .herosixhundred_eight .herosixhundred_eight_in h2 {line-height: 38px;font-size: 30px;}
    .herosixhundred_eight .herosixhundred_eight_in h3 {font-size: 40px;line-height: 40px;}
    .herosixhundred_eight .herosixhundred_eight_in .center img {width: 65%;}
    .herosixhundred_seven .herosixhundred_seven_in .left img {width: 60%;}
    .herosixhundred_seven .herosixhundred_seven_in .right img {width: 70%;}
    .herosixhundred_seven .herosixhundred_seven_in .left p {font-size: 22px;line-height: 25px;}
    .herosixhundred_seven .herosixhundred_seven_in .left h2 {font-size: 40px;line-height: 48px;}
    .herosixhundred_six .herosixhundred_six_in {top: 60px;}
    .herosixhundred_six .herosixhundred_six_in h2 {font-size: 40px;line-height: 40px;}
    .herosixhundred_five .herosixhundred_five_in{top: 60px;}
    .herosixhundred_five .herosixhundred_five_in p {font-size: 18px;line-height: 30px;padding-right: 0px;}
    .herosixhundred_five .herosixhundred_five_in h2 span {font-size: 40px;line-height: 40px;}
    .herosixhundred_five .herosixhundred_five_in h2 {font-size: 40px;line-height: 40px;}
    .herosixhundred_four .herosixhundred_four_in .rightimg img{margin-top: 30px;}
    .herosixhundred_four .herosixhundred_four_in .text p {font-size:18px;line-height: 22px;}
    .herosixhundred_four .herosixhundred_four_in .text h2 {line-height: 35px;font-size: 40px;}    
    .herosixhundred_four .herosixhundred_four_in .text h3 span {font-size: 40px;line-height: 40px;}
    .herosixhundred_four .herosixhundred_four_in .text h3 {font-size: 70px;line-height: 65px;}
    .herosixhundred_three .herosixhundred_three_in .center img {width: 50%;}
    .herosixhundred_three .herosixhundred_three_in h2 {font-size: 40px;line-height: 40px;}
    .herosixhundred_three .herosixhundred_three_in {top: 75px;}
    .super-mob-list .herosixhundred_two ul li .text p{font-size: 16px;line-height: 18px;margin-top: 0px;height: 35px;}
    .herosixhundred_one .herosixhundred-one{top: 70px;}
    .herosixhundred_one .bg-height {height: 580px;}
    .herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left h1 {font-size: 40px;margin-bottom: 50px;}
    .container_two ul li .flex-col.lighting.lf_f {margin-top: 6px;}
    .champion_ten .champion_ten_in p{line-height: 25px;}
    .champion_ten .champion_ten_in img{margin-top: 0px;}
    .champion_ten .champion_ten_in h2 {font-size: 40px;line-height: 40px;}
    .champion_nine .champion_nine_in .center img{width: 78%;}
    .champion_nine .champion_nine_in h2 {font-size: 40px;line-height: 40px;}
    .champion_eight .champion_eight_in{top: 110px;}
    .champion_eight .champion_eight_in p {font-size: 20px;line-height: 28px;}
    .champion_eight .champion_eight_in h2 {font-size: 40px;line-height: 40px;}
    .champion_eight .champion_eight_in .text {width: 90%;}
    .champion_eight .champion_eight_in .left img {width: 75%;}
    .champion_seven .champion_seven_in img{margin-top: 0px;}
    .champion_seven .champion_seven_in p {font-size: 20px;line-height:28px;padding-right: 20px;}
    .champion_seven .champion_seven_in h2 {font-size: 40px;line-height: 40px;}
    .champion_six .champion_six_in{top: 80px;}
    .champion_six .champion_six_in p {font-size: 18px;line-height: 22px;}
    .champion_six .champion_six_in h2 {font-size: 40px;line-height: 40px;}
    .champion_five .champion_five_in .text h2 {font-size: 40px;line-height: 40px;}
    .champion_five .champion_five_in .text p {font-size: 16px;line-height: 18px;}
    .champion_five .champion_five_in .left-img img {width: 75%;left: 0%;}
    .champion_four .champion_four_in .center img {width: 60%;left: 0;margin: 0 auto;}
    .champion_four .champion_four_in .in p {font-size: 20px;line-height: 24px;}
    .champion_four .champion_four_in .in .text label {height: 100px;font-size: 40px;}
    .champion_four .champion_four_in h2 {font-size: 40px;line-height: 40px;}
    .champion_three .champion_three_in .right img {width: 72%;}
    .champion_three .champion_three_in h2 {font-size: 40px;line-height: 40px;margin-bottom: 40px;}
    .champion_one .bg-height {height: 580px;}
    .blazefiveg_twelve .blazefiveg_twelve_in img {margin-top: 10px;width: 80%;}
    .champion_one .champion_one_in .left h1 {font-size: 40px;margin-bottom: 50px;}
    .blazefiveg_twelve .blazefiveg_twelve_in{top:50px;}
    .blazefiveg_twelve .blazefiveg_twelve_in p {font-size:16px;line-height: 20px;}
    .blazefiveg_twelve .blazefiveg_twelve_in h2 {font-size: 50px;line-height: 55px;}
    .blazefiveg_eleven .blazefiveg_eleven_in label {font-size: 20px;line-height: 20px;}
    .blazefiveg_eleven .blazefiveg_eleven_in p.second {font-size: 20px !important;}
    .blazefiveg_eleven .blazefiveg_eleven_in p {font-size: 20px !important;line-height: 24px;}
    .blazefiveg_eleven .blazefiveg_eleven_in h2 {font-size: 35px;line-height: 35px;}
    .blazefiveg_ten .two.cum-container-width p {font-size: 20px;line-height: 20px;}
    .blazefiveg_ten .blazefiveg_ten_in h2 {font-size: 24px;}
    .blazefiveg_ten .blazefiveg_ten_in label {font-size: 22px;}
    .blazefiveg_ten .blazefiveg_ten_in p {font-size: 24px;}
    .blazefiveg_ten .blazefiveg_ten_in h3 {font-size: 50px;}
    .blazefiveg_nine .blazefiveg_nine_in label {font-size: 20px;padding: 5px 5px;    margin-bottom: 25px;}
    .blazefiveg_nine .blazefiveg_nine_in p {font-size: 20px;line-height: 26px;margin-top: 10px;}
    .blazefiveg_nine .blazefiveg_nine_in h2 {font-size: 42px;line-height: 45px;}
    .blazefiveg_seven .blazefiveg_seven_in label {line-height: 45px;font-size: 40px;}
    .blazefiveg_seven .blazefiveg_seven_in h2 {font-size: 50px;line-height: 40px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img{width: 54%;margin-top: 160px;}
    .blazefiveg_six .blazefiveg_six_in p {font-size: 20px;line-height: 22px;}
    .blazefiveg_six .blazefiveg_six_in .text{padding-left: 120px;}
    .blazefiveg_five .blazefiveg_five_in {top:60px;}
    .blazefiveg_five .blazefiveg_five_in img{width: 80%;}
    .blazefiveg_five .blazefiveg_five_in h2 {font-size: 50px;margin-bottom: 10px;}
    .blazefiveg_three .blazefiveg_three_in img {width:70%;}
    .blazefiveg_four .blazefiveg_four_in img {left: 0%;}
    .blazefiveg_four .blazefiveg_four_in img {width:80%;}
    .blazefiveg_four .blazefiveg_four_in p {font-size: 20px;line-height: 25px;}
    .blazefiveg_four .blazefiveg_four_in label {font-size: 20px;line-height: 35px;}
    .blazefiveg_four .blazefiveg_four_in h5 {    margin-bottom: 0;}
    .blazefiveg_three .blazefiveg_three_in{top:40px;}
    .blazefiveg_three .blazefiveg_three_in p {font-size: 20px;line-height: 22px;}
    .afive_nine .afive_nine_in .left {width: 92%;}
    .blazefiveg_four .blazefiveg_four_in {top: 50px;}
    .afive_eight .afive_eight_in .left .m-image img{margin-top: 0px;}
    .afive_ten .afive_ten_in{top:15%;}
    .afive_five .afive_five_in .left h2 span:first-child {font-size: 65px;}
    .afive_ten .afive_ten_in img {width: 35%;}
    .afive_three .afive_three_in {bottom: 20px;}
    .afive_three .afive_three_in .left h2 {font-size: 40px;line-height: 50px;}
    .afive_four .afive_four_in .left h2 {font-size: 40px;line-height: 40px;}
    .afive_five .afive_five_in .left h2 span:nth-child(2) {font-size: 40px;}
    .afive_five .afive_five_in .left h2 {font-size: 40px;line-height: 40px;}
    .afive_six .afive_six_in .right h2 {font-size: 40px;line-height: 40px;}
    .afive_nine .afive_nine_in .left p {line-height:26px;margin-bottom: 5px;font-size: 20px;}
    .afive_nine .afive_nine_in .left h2{margin-bottom: 8px;font-size: 40px;line-height: 40px;}
    .afive_nine .afive_nine_in .right img{width: 88%;}
    .afive_seven .afive_seven_in .left p {font-size: 20px;letter-spacing: 2px;line-height: 30px;padding-right: 4px;}
    .afive_eight .afive_eight_in .left h2 {font-size: 40px;line-height: 40px;}
    .afive_seven .afive_seven_in .left img {width:55%;}
    .afive_seven .afive_seven_in .left h2 {font-size: 40px;line-height: 40px;    margin-top: 50px;}
    .afive_eight .afive_eight_in .left p {font-size: 20px;line-height: 28px;letter-spacing: 1px;}
    .afive_six .afive_six_in .left img {width:60%;}
    .afive_five .afive_five_in .left h2{margin-top: 50px;}
    .afive_four .afive_four_in .right img {width: 90%;}
    .afive_three .afive_three_in .right img {width: 80%;}
    .afive_one .afive_one_in .right-text img {width: 90%;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in img{width: 80%;margin: 0 auto;}
    .athreesuper .bg-height {height: 590px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text h2 {font-size: 40px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p {font-size: 20px;line-height: 20px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in img.memory {margin-top: 20px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .pro img {width: 40%;top: -60px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text p {font-size: 20px;line-height: 20px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text h2 {margin-bottom: 15px;font-size: 40px;line-height: 45px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text{position: relative;top: 50px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text p {font-size: 20px;line-height: 20px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 40px;line-height: 42px;}
    .super-ai-battery .container-fluid .battery-life-in .text p {font-size: 20px;line-height: 20px;}
    .super-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 40px;line-height: 40px;}
    .ten-days {margin-right: -48px;}
    .battery-lifes .container-fluid .battery-life-in .text h5 {font-size: 24px;line-height: 32px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text {width: 460px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 span {font-size: 35px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 {font-size: 40px;}
    .music-lovers .container-fluid .music-lovers-in .text p {font-size: 20px;line-height: 20px;}
    .music-lovers .container-fluid .music-lovers-in .text h2 {font-size: 40px;line-height: 40px;}
    .athreesuper .container-fluid .left-text h1 {font-size: 40px;}
    .big-speaker .container-fluid .big-speaker-in .text p {font-size: 20px;}
    .big-speaker .container-fluid .big-speaker-in .text h2 {font-size: 40px;}
    .big-speaker .container-fluid .big-speaker-in .text {top: 60px;position: relative;}
    .big-speaker .container-fluid .big-speaker-in .pro img{margin-top: 0px;}
    .music-lovers .container-fluid .music-lovers-in .pro img{margin-top: 0px;}
    .cum-container-width {width: 85% !important;}
    .container_four{width: 85% !important;}
    .container_one .pro-img img {width: 75%;}
    .container.container_nine .section-content .text img {width: 60%;}
    .one_sec_box h5 {font-size: 14px;}
    .search_bar_dropdown {width:39%;}
    .search_bar_dropdown {margin-top: 0px;}
    .country_list {right: 8%;}
    .container_five {max-width: 86%;}
    .container.container_eight{top: 55px;}
    .container_six .text img {width: 65%;right: -40px;}
    .container.container_eight .text .text-in{padding-left: 30px;}
    .one_hot_power_bank.hot_prod3 {height: 425px;}
    .blazepro_section .container_one .section-content {width:243px;top: 20px;}
    .blazepro_section .container_one .section-content h1 {font-size: 60px;line-height: 60px;}
    .blazepro_section .container_one .section-content label {font-size:22px;line-height:25px;margin-bottom: 5px;}
    .section-logo img {width:140px;}
    .blazepro_section.six .img {height: 550px;}
    .container_two ul li .text h2 {font-size:18px;line-height:18px;}
    .container_two ul li .text p {font-size: 14px;}
    .container_three .section-content h2 {font-size: 70px;line-height: 85px;}
    .container_three .section-content label {font-size: 26px;line-height: 26px;}
    .container_three{top: 50px;}
    .blazepro_section.one .img {height: 485px;}
    .blazepro_section.three .img {height: 730px;}
    .container_three .section-content h2 {font-size: 55px;line-height: 66px;}
    .blazepro_section.four .img{    height: 738px;}
    .container-fluid.container_six h2 {font-size: 62px;line-height: 60px;}
    .container-fluid.container_six label{font-size: 22px;line-height:28px;}
    .container-fluid.container_six {top: 195px;}
    .container-fluid.container_ten .section-content .text .center img {width: 84%;}
    .container.container_seven h2 {font-size: 60px;line-height: 50px;}
    .container.container_seven p {font-size: 20px;line-height: 26px;}
    .container.container_seven img{width:75%;}
    .container.container_eight h2 {font-size: 60px;line-height:50px;}
    .container.container_eight{width: 85%;}
    .blazepro_section.eight .img {height: 930px;}
    .container-fluid.container_six{width: 85%;}
    .container.container_eight h3 {font-size: 32px;}
    .container_four .section-content p {margin-top:0px !important;font-size: 24px;line-height: 30px;}
    .container.container_eight p {font-size: 26px;line-height: 35px;}
    .container.container_eight .section-content .text img {margin-left:0px;}
    .container-fluid.container_eleven .section-content .text img {width:60%;top:32px;}
    .blazepro_section.eleven .img {height: 565px;}
    .container-fluid.container_eleven{width: 85%;}
    .container-fluid.container_ten h2{line-height: 45px;font-size: 55px;}
    .container-fluid.container_ten p {font-size: 25px;}
    .container.container_nine h3 {font-size:55px;line-height: 50px;}
    .container.container_nine p {font-size: 28px;margin-top: 0px;}
    .container.container_nine .section-content .text img{margin-left:0px;}
    .one_sec_box h3 {font-size: 14px;}
    .one_sec_box a {font-size: 14px;}
    h3.title {font-size: 20px;}
    .blazepro_section.nine .img {height: 760px;}
    .container.container_nine h2 {font-size: 112px;line-height: 90px;margin-left: -8px;}
    .container-fluid.container_ten{width: 85%;}
    section.featur_pro_list {margin:40px 25px;}
    .hot_pro_list .container-fluid{padding-left: 0;padding-right: 0;}
    .one_sec_box.one_hot_produ{padding: 23px 20px;}
    .featur_pro_list .row .col-md-4:nth-child(1) .one_sec_box {padding-bottom: 0px;}
    .asuper .container-fluid .left-text img{margin-top: 15px;}
    .super-mob-list ul li .text h4 {font-size: 16px;line-height: 17px;min-height: 30px;}

    .design-prefection .container-fluid {top: 120px;}
    .design-prefection .container-fluid .cols img{width:60%;}
    .days-battery .container-fluid{padding: 0px 40px;}
    .days-battery .container-fluid .cols .text span {font-size: 220px;line-height: 155px;}
    .days-battery .container-fluid .cols .text .right-text {margin-top: 70px;}
    .days-battery .container-fluid .cols .text .right-text {margin-top: 105px;}
    .days-battery .container-fluid .cols .text .right-text h2 {font-size: 40px;line-height:25px;}
    .days-battery .container-fluid .cols .text .right-text h2 span {font-size: 23px;}
    .days-battery .container-fluid .cols p {font-size:16px;line-height: 22px;padding-left: 75px;}
    .days-battery .container-fluid .pro-img img {width:65%;margin-top:0px;}
    .battery-pro-list{margin-top:25px !important;}
    .battery-pro-list li .text .poab img {width: 35px;height: 35px;}
    .battery-pro-list li .text .poab h5{font-size: 18px;}
    .battery-pro-list li .text .poab p{font-size: 15px;margin-bottom: 0;}
    .smart-ai-battery .container-fluid .flex .left {margin-left: 0px;}
    .design-prefection .container-fluid {padding: 0px 40px;}
    .smart-ai-battery .container-fluid .flex .left h5 {padding-right: 18px;font-size: 25px;padding-bottom: 3px;padding-top: 3px;}
    .smart-ai-battery .container-fluid .flex .left p {margin-top: 5px; font-size: 28px;line-height: 28px;padding-left: 0;}
    .smart-ai-battery .container-fluid .right img{    width: 72%;}
    .bt-support .container-fluid{padding: 0px 40px;}
    .bt-support .container-fluid .right h2 {font-size: 45px;line-height: 40px;}
    .bt-support .container-fluid .right p {font-size: 20px;line-height: 26px;margin-top: 10px;margin-bottom: 20px;}
    .bt-support .container-fluid .left img {width:55%;margin-left: 75px;margin-top: 0px;}
    .expand-moory .container-fluid .text h2 {font-size:40px;line-height: 40px;margin-bottom:8px;}
    .expand-moory .container-fluid .text p {font-size: 20px;line-height: 22px;margin: 0;}
    .expand-moory .container-fluid {top: 60px;}
    .military-grade .container-fluid .flex .right img {width: 80%;}
    .military-grade .container-fluid {top: 100px;}
    .auto-call-recording .container-fluid .flex{align-items: center;}
    .auto-call-recording .container-fluid {bottom:45px;}
    .expand-moory .container-fluid .text .center img{width:66%;float: none;}
    .auto-call-recording .container-fluid .text h2 {font-size:40px;line-height:38px;}
    .auto-call-recording .container-fluid .text p {margin-top:5px;font-size: 20px;line-height:22px;}
    .military-grade .container-fluid .flex .left h2 {font-size: 40px;}
    .military-grade .container-fluid .flex .left p {line-height:26px;font-size:20px;}
    .container.container_nine{width: 85% !important;}
    .container.container_seven{width: 85%;}
    .container_four{width: 85%;}
    .container_three{width: 85%;}
    .container_two.container {max-width: 85%;}
    .blazepro_section .container_one{width: 85%;}
    .fiftympproduct img {width: 65%;}
    .spect-width {width: 100% !important;}
    .spec_page .table-responsive .table td.spectification_title {width: 25% !important;padding-left: 0% !important;}
}

@media (max-width:912px){
    .blazefiveg_eight .blaze_left_text .text img {height:75px;}
    .blazefiveg_eight .blaze_left_text .text h2 {font-size: 68px;line-height: 55px;}
    .blazefiveg_eight .blaze_left_text .text img{margin-left: 0px;}
    .blazefiveg_eight .blaze_left_text .text p {line-height: 25px;font-size: 20px;}
    .afive_eleven .afive_eleven_in img{margin-left: 50px;}
    .afive_eleven .afive_eleven_in .left{margin-top: 78px;}
    .afive_eleven .afive_eleven_in .left p {line-height: 30px;padding-right: 50px;}
	.container_two.container{padding-bottom: 30px;}
	.blazepro_section .container_one .section-content label{font-size: 21px;    padding-right: 24px;}
    .enjoylist ul li{padding: 0px 5px !important;}
    .one_detail1 .one_detail_content p{margin-bottom: 0;font-size: 16px !important;line-height: 24px;}
    .enjoylist ul li .en_list img {width: 35px !important;height: 35px !important;}
    .blazefiveg_twelve .blazefiveg_twelve_in img{width: 75%;}
    .blazefiveg_twelve .blazefiveg_twelve_in p{margin-top: 8px;margin-bottom: 8px;}
    .blazefiveg_twelve .blazefiveg_twelve_in h2 {font-size: 40px;}
    .blazefiveg_seven .blazefiveg_seven_in p {font-size: 22px;}
    .blazefiveg_seven .blazefiveg_seven_in label {line-height: 38px;font-size: 32px;}
    .blazefiveg_seven .blazefiveg_seven_in h2 {font-size: 40px;line-height: 30px;}
    .blazefiveg_six .blazefiveg_six_in h2 {font-size: 40px;line-height: 35px;}
    .blazefiveg_five .blazefiveg_five_in img {width: 75%;}
    .blazefiveg_five .blazefiveg_five_in h2 {font-size: 40px;margin-bottom: 4px;}
    .blazefiveg_four .blazefiveg_four_in img {width: 75%;}
    .blazefiveg_four .blazefiveg_four_in h2 {font-size: 40px;line-height: 40px;}
    .blazefiveg_three .blazefiveg_three_in h1 {font-size: 40px;margin-bottom: 5px;}
    .bottom_list {bottom: 10px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center img {width: 75%;}
    .zthree.zthree-bg-five .zthree-bg-five-in{top: 35px;}
    .blaze-clean-ui .one_detail_content{top: -70px;}
    .blaze-clean-ui .one_detail_content h3 {font-size: 18px;}
    .blaze-clean-ui .one_detail_content h2 {font-size: 20px;}
    .blaze-clean-ui .one_detail_content h4 {font-size: 40px;line-height: 50px;}
    .blaze-octa-processor .one_detail_content h3 {font-size: 20px;}
    .blaze-octa-processor .one_detail_content h2 {font-size: 40px;}
    .blaze-ram-group .one_detail_content .macro-camera label {font-size: 25px;line-height: 20px;}
    .blaze-ram-group .one_detail_content .macro-camera h3 {font-size: 40px;}
    .blaze-macro-camera .one_detail_content p {font-size: 18px !important;}
    .blaze-macro-camera .one_detail_content h2 {font-size: 40px;line-height: 30px;}
    .product_details.blaze-dezzle .one_detail1 .center img {top: 182px;}
    .blaze-dezzle .one_detail_content p {font-size: 18px;}
    .blaze-dezzle .one_detail_content h2 span {font-size: 30px;}
    .blaze-dezzle .one_detail_content h2 {font-size: 40px;line-height: 30px;}
    .beauty-mode .one_detail_content .beauty_mode p {line-height: 25px;font-size: 20px;}
    .beauty-mode .one_detail_content .beauty_mode h2 {font-size: 40px;}
    .tripal-camera .one_detail_content .center img {margin-top: 20px;}
    .tripal-camera .one_detail_content {top: 50px;}
    .tripal-camera .one_detail_content h3 {font-size: 40px;}
    .prow_detalpage section.one .right img{margin-top: 0px;width: 45%;}
    .one_detail_content p {line-height: 26px;font-size: 23px;}
    .one_detail_content h4 {font-size: 20px;}
    .one_detail_content h3 {font-size: 25px;line-height: 30px;}
    .one_detail_content h1 {font-size: 40px;}
    .prow_detalpage section .banner-height {height: 410px;}
    .bottom_list ul li .texts p {font-size: 15px !important;}
    .bottom_list ul li .texts img {margin-top: 8px;}
    .container_two ul li .text{padding: 12px;}
    .container_two ul li .text p {font-size: 12px;margin-top: 0px;}
    .herosixhundred_one .bg-height {height: 510px;}
    .champion_four .champion_four_in .center img{left: 100px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img{margin-top: 140px;}
    .champion_one .bg-height {height: 510px;}
    .champion_nine .champion_nine_in p {font-size: 18px;padding-right: 10px;}
    .afive_ten .afive_ten_in .right p {font-size: 18px;}
    .afive_one .afive_one_in .left-text h1 {font-size:35px;margin-bottom: 30px;}
    .afive_seven .afive_seven_in .left img {width:45%;}
    .afive_ten .afive_ten_in {top:10%;}
    .athreesuper .bg-height {height: 530px;}
    .super-ai-battery .container-fluid .battery-life-in .text p{font-size:16px;}
    .super-mob-list ul li .text h4{font-size: 14px;}
    .asuper .bg-height {height: 590px;}
    .container.container_nine .section-content .text img {width: 68%;}
    .container.container_nine h3{line-height: 56px;}
    .container-fluid.container_ten h2 {line-height: 40px;font-size: 45px;}
    .container-fluid.container_ten p {font-size: 20px;line-height:28px;}
    .ip_content h1{font-size: 30px;}
    .container-fluid.container_ten .right img {width: 218px !important;}
    .search_bar_dropdown {width: 43%;}
    .country_list {right: 9%;}
    .blazepro_section.ten .img {height: 740px;}
    .container-fluid.container_ten .section-content .text .center img {width: 100%;}
    .container-fluid.container_eleven h2 {font-size: 45px;line-height: 30px;}
    .container-fluid.container_eleven p {font-size: 18px;line-height: 22px;}
    .container-fluid.container_eleven h3 {font-size: 21px;}
    .container-fluid.container_eleven h4 {font-size: 19px;    line-height: 26px;}
    .container-fluid.container_ten {padding: 0px 0px !important;}
    .container.container_eight .section-content .text img {width: 83%;}
    .container.container_seven {top: 90px;}
    .fiftympproduct img {width:72%;}
    .container_six .text img {margin-top: 15px;}
    .container_one .pro-img img {width:85%;}
    .proul img {width:62%;}
    .Subscribe p {font-size: 13px;line-height: 16px;}
    .footer-logo a {font-size: 13px;}
    .footer_middle_links li a {font-size: 13px;}
    .blazepro_section .container_one {top:50px;}
    .fiftympproduct img{margin-top: 30px;}
    .container-fluid.container_six .section-content {padding: 0px 0px;}
    .container-fluid.container_eleven .section-content .text img{top: 0px;width: 83%;}
    .product_pricing h4 {font-size: 14px;}
    .product_pricing a {font-size: 13px;}
    .product_pricing{padding: 7px 5px;}
    .one_host_detila {padding: 0 10px;}
    .hot_prod4 {padding: 12px 0px;}
    .one_hot_carger.hot_prod5{padding: 12px 0px;}
    .one_hot_power_bank.hot_prod3 {height: 390px;}
    .days-battery .container-fluid .pro-img img { width:60%;margin-top: 0px;}
    .design-prefection .container-fluid .cols .text h2 {font-size: 40px;margin-top: 40px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color h2 {font-size:40px;line-height:40px;}
    .days-battery .container-fluid .cols p {font-size: 18px;padding-left: 0px;}
    .bt-support .container-fluid .right h2 {font-size:40px;line-height: 40px;}
    .bt-support .container-fluid .right p {font-size: 16px;line-height: 22px;}
    .expand-moory .container-fluid {padding: 0px 35px;}
    .auto-call-recording .container-fluid .text p {margin-top: 8px;font-size: 18px;line-height: 22px;}
    .expand-moory .container-fluid .text .center img {width:63%;}
    .military-grade .container-fluid .flex .left p{font-size: 20px;}
    .quick_vie_img {width: 100%;}
    .quickbody_content{width: 100%;margin-top: 28px;}
    .blazefiveg_six .blazefiveg_six_in {top: 50px;}
}

@media (max-width:820px){
    .blazefiveg_eight .blaze_left_text .text img {height:58px;}
    .container_two ul li .flex-col.lighting {margin-top: 6px;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 16px;}
    .tripal-camera .one_detail_content p {font-size: 20px;}
    .one_detail1 .one_detail_content p {font-size: 20px;}
    .blaze-ram-group .one_detail_content .macro-camera h2 {font-size: 25px;}
    .blaze-clean-ui .one_detail_content h3 {font-size: 16px;}
    .search_bar_dropdown {width: 49%;}
    .afive_five .afive_five_in .left h2 span:nth-child(2) {font-size: 30px;}
    .afive_five .afive_five_in .left h2 span:first-child {font-size: 55px;}
    .afive_five .afive_five_in .left h2 {font-size: 30px;line-height: 30px;}
    .afive_four .afive_four_in .left h2 {font-size: 30px;line-height: 30px;}
    .afive_three .afive_three_in .left h2 {font-size: 30px;line-height: 40px;margin-top: 0;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text {margin-top: 25px;}
    .blaze-macro-camera .one_detail_content{left: 25%;}
    .product_details.blaze-dezzle .one_detail1 .center img {top: 160px;}
    .tripal-camera .one_detail_content {top: 40px;}
    .one_detail_content h4{margin-top:5px;}
    .prow_detalpage section .banner-height {height: 354px;}
    .prow_detalpage section.one .right img{width: 45%;}
    .bottom_list ul li{padding: 2px;}
    .zthree.zthree-bg-eleven .text h2 {font-size: 30px;line-height: 30px;}
    .bottom_list ul li .texts p {font-size: 12px !important;line-height: 13px !important;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center img{width:80%;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in p {line-height: 23px;font-size: 18px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in h2 {line-height: 30px;font-size: 30px;}
    .zthree.zthree-bg-six .zthree-bg-six-in p {font-size: 18px;}
    .zthree.zthree-bg-six .zthree-bg-six-in h2 {font-size: 30px;line-height: 30px;}
    .zthree.zthree-bg-five .zthree-bg-five-in{top: 30px;}
    .zthree.zthree-bg-five .zthree-bg-five-in p {font-size: 20px;}
    .zthree.zthree-bg-five .zthree-bg-five-in h2 {line-height: 30px;font-size: 30px;}
    .zthree.zthree-bg-four .zthree-bg-four-in p {font-size: 16px;line-height: 20px;}
    .zthree.zthree-bg-four .zthree-bg-four-in h2 {line-height: 30px;font-size: 30px;}
    .zthree.zthree-bg-one .bg-height {height: 420px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h2 {font-size: 25px;line-height: 28px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h1 {font-size: 40px;}
    .herosixhundred_eight .herosixhundred_eight_in h2 {line-height: 30px;font-size: 25px;}
    .herosixhundred_eight .herosixhundred_eight_in h3 {font-size: 30px;line-height: 30px;}
    .herosixhundred_seven .herosixhundred_seven_in{top: 48px;}
    .herosixhundred_seven .herosixhundred_seven_in .left p {font-size: 18px;line-height: 20px;margin: 10px 0px;}
    .herosixhundred_seven .herosixhundred_seven_in .left label {font-size: 20px;line-height: 16px;}
    .herosixhundred_seven .herosixhundred_seven_in .left h2 {font-size: 30px;line-height: 30px;}
    .herosixhundred_six .herosixhundred_six_in p {font-size: 18px;line-height: 22px;}
    .herosixhundred_six .herosixhundred_six_in h2 {font-size: 30px;line-height: 30px;}
    .herosixhundred_five .herosixhundred_five_in h2 span {font-size: 30px;line-height: 30px;}
    .herosixhundred_five .herosixhundred_five_in h2 {font-size: 30px;line-height: 30px;}
    .herosixhundred_four .herosixhundred_four_in .rightimg img {margin-top: 0px;}
    .herosixhundred_four .herosixhundred_four_in .text p { font-size: 18px;}
    .herosixhundred_four .herosixhundred_four_in .text h2 {line-height: 30px;font-size: 30px;}
    .herosixhundred_four .herosixhundred_four_in .text h3 span {font-size: 30px;line-height: 30px;}
    .herosixhundred_four .herosixhundred_four_in .text h3 {font-size: 50px;line-height: 50px;}
    .herosixhundred_three .herosixhundred_three_in h2 {font-size: 30px;line-height: 30px;}
    .herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left h1 {font-size: 35px;}
    .blazefiveg_seven .blazefiveg_seven_in {top: 30px;}
    .blazefiveg_eleven .blazefiveg_eleven_in {bottom: 50px;}
    .blazefiveg_nine .blazefiveg_nine_in {top: 50px;}
    .blazefiveg_six .blazefiveg_six_in {top: 42px;}
    .blazefiveg_four .blazefiveg_four_in{top: 50px;}
    .super-mob-list .container-fluid ul li .text p{line-height: 18px;margin-top: 0px;font-size: 16px;}
    .champion_ten .champion_ten_in h2 {font-size: 30px;line-height: 30px;}
    .champion_nine .champion_nine_in p {font-size: 15px;line-height: 28px;}
    .champion_nine .champion_nine_in h2 {font-size: 30px;line-height: 30px;}
    .champion_eight .champion_eight_in h2 {font-size: 30px;line-height: 30px;}
    .champion_eight .champion_eight_in .left img {width: 65%;}
    .champion_seven .champion_seven_in p {font-size: 18px;padding-right: 20px;}
    .champion_seven .champion_seven_in h2 {font-size: 30px;line-height: 35px;}
    .champion_six .champion_six_in h2 {font-size: 30px;line-height: 30px;}
    .champion_four .champion_four_in .in p {font-size: 18px;line-height: 25px;}
    .champion_four .champion_four_in .in .text label{font-size: 30px;}
    .champion_four .champion_four_in h2 {font-size: 30px;line-height: 30px;}
    .champion_three .champion_three_in h2 {font-size: 30px;line-height: 30px;margin-bottom: 30px;}
    .champion_one .champion_one_in .left h1 {font-size: 35px;}
    .afive_six .afive_six_in .right p {font-size: 18px;line-height: 22px;}
    .afive_eleven .afive_eleven_in .left h2 {font-size:30px;line-height: 30px;}
    .afive_eleven .afive_eleven_in .left p {line-height: 18px;font-size: 16px;}
    .afive_nine .afive_nine_in .left p{font-size: 18px;}
    .bt-support .container-fluid{top: 80px;}
    .afive_ten .afive_ten_in .right h2 {font-size: 30px;line-height: 30px;}
    .afive_nine .afive_nine_in .left h2 {font-size: 30px;line-height: 30px;}
    .afive_eight .afive_eight_in .left h2 {font-size: 30px;line-height: 35px;}
    .afive_seven .afive_seven_in .left img {width: 40%;}
    .afive_seven .afive_seven_in .left p{padding-left: 20px;}
    .afive_seven .afive_seven_in .left h2 {font-size: 30px;line-height: 30px;}
    .afive_six .afive_six_in .right h2 {font-size: 30px;line-height: 30px;}
    .media_cnter ul li a {font-size: 15px;}
    .design-prefection .container-fluid .cols .text h2{line-height: 38px;font-size: 35px;}
    .sub_menu_dropdown {top: 50px !important;}
    .country_list {right: 9.5%;}
    .container-fluid.container_six label{line-height: 26px;}
    .blazepro_section .container_one .section-content {width: 243px;top: 28px;}
    .container-fluid.container_ten p {font-size:16px;line-height: 22px;margin: 0;}
    .container-fluid.container_ten h2 {line-height:36px;font-size:38px;}
    .container_four {top: 70px;}
    .container_six .text img {width: 60%;}
    .proul img {width:70%;}
    .blazepro_section.ten .img {height: 690px;}
    .one_hot_power_bank.hot_prod3 {height: 360px;}
    .blazepro_section.six .img {height: 400px;}
    .blazepro_section.one .img {height: 445px;}
    .container_four .section-content label {font-size: 26px;}
    .container_four .section-content p {margin-top: 10px !important;line-height: 25px;font-size: 18px;text-align: center;}
    .fiftympproduct img {width: 82%;}
    .container_two ul li .flex-col.lighting .text {top: 20%;}
    .blazepro_section.eleven .img {height: 500px;}
    .container-fluid.container_six {top: 130px;}
    .container.container_five .row .col .gallery-img {margin-bottom: 24px;margin-top: 35px;}
    .container-fluid.container_eleven .section-content .text img {width:60%;top:65px;}
    .container.container_seven {top: 85px;}
    .container.container_nine .section-content .text img {margin-top: 40px;width: 77%;}  
    .blazepro_section.eight .img {height: 845px;}
    .container.container_nine h2 {font-size: 100px;}
    .days-battery .container-fluid .cols p {font-size: 15px;}
    .battery-pro-list li .text .poab img{width: 35px;height: 35px;}
    .battery-pro-list {margin-top:10px !important;}
    .battery-pro-list li .text .poab h5{font-size: 15px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color {padding-top:65px;width: 250px;}
    .smart-ai-battery .container-fluid .flex .left h5 {font-size: 22px;}
    .smart-ai-battery .container-fluid .flex .left p {font-size: 24px;}
    .bt-support .container-fluid .left img {margin-left: 25px;}
    .bt-support .container-fluid .right {padding: 0px 0px;}
    .expand-moory .container-fluid .text h2 {font-size: 40px;line-height: 35px;}
    .expand-moory .container-fluid .text p {font-size: 16px;line-height: 16px;}
    .auto-call-recording .container-fluid .text p{font-size: 20px;line-height: 25px;}
    .military-grade .container-fluid .flex .left {margin-top: 30px;width: 65%;}
    .military-grade .container-fluid .flex .left p {font-size: 18px;line-height: 27px;}
    .military-grade .container-fluid {padding: 0px 35px;}
    .container_two ul li .text.ramplus {top: 0%;}
    .expand-memory .container-fluid{top: 85px;}
    .super-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 35px;line-height: 35px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text p {font-size: 16px;line-height: 16px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 35px;line-height: 32px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text h2 {font-size: 35px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p {font-size: 16px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text h2 {margin-bottom: 12px;font-size: 35px;line-height: 36px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text p {font-size: 16px;line-height: 16px;}
    .athreesuper .container-fluid .left-text h1 {font-size: 35px;}
    .music-lovers .container-fluid .music-lovers-in .text h2 {font-size: 35px;line-height: 35px;}
    .music-lovers .container-fluid .music-lovers-in .text p {font-size: 16px;}
    .big-speaker .container-fluid .big-speaker-in .text h2 {font-size: 35px;}
    .big-speaker .container-fluid .big-speaker-in .text p {font-size: 16px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 {font-size: 35px;line-height: 35px;}
    .ten-days img {width: 40%;}
    .battery-lifes .container-fluid .battery-life-in .text h5 {font-size: 16px;line-height: 22px;}
    .blazefiveg_five .blazefiveg_five_in p{padding: 5px 70px;}
    .blazefiveg_five .blazefiveg_five_in {top: 40px;}
    .champion_six .champion_six_in p {font-size: 16px;}
    .champion_ten .champion_ten_in p{letter-spacing: 0px;}
    .bottom_list {bottom: 0px;}
}

@media (max-width:768px){
    .three_days_list ul li span {font-size: 14px;}
    .three_days_list ul li h6 {font-size: 16px;line-height: 13px;}
    .herosixhundred_four .herosixhundred_four_in .text img {height: 38px;}
    .herosixhundred_four .herosixhundred_four_in .text p {font-size: 16px;}
    .herosixhundred_eight .herosixhundred_eight_in p{margin-top: 5px;}
    .super-mob-list .container-fluid ul li .text p{line-height: 15px;font-size: 14px;}
    .champion_seven .champion_seven_in p{line-height: 22px;}
    .search_bar_dropdown {width: 52%;}
    .prow_detalpage section.one .right img {width: 40%;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center img {width: 72%;}
    .blazefiveg_twelve .blazefiveg_twelve_in img {width: 70%;}
    .blazefiveg_ten .blazefiveg_ten_in {top: 50px;}
    .blazefiveg_ten .blazefiveg_ten_in h3 {font-size: 40px;line-height: 35px;margin-bottom: 10px;}
    .blazefiveg_four .blazefiveg_four_in img {width: 67%;}
    .bottom_list {bottom: 0px;}
    .container_two ul li .flex-col.lighting .text {top: 28%;}
    .beauty-mode .one_detail_content .beauty_mode p{font-size: 18px;}
    .prow_detalpage section .banner-height {height: 290px;}
    .one_detail_content p {line-height: 20px;font-size: 16px;}
    .one_detail_content h4 {font-size: 16px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color h2 {font-size: 30px;line-height: 30px;}
    .days-battery .container-fluid .cols .text .right-text {margin-top: 40px;}
    .days-battery .container-fluid .cols .text span {font-size: 140px;line-height: 100px;}
    .design-prefection .container-fluid {top: 90px;}
    .herosixhundred_seven .herosixhundred_seven_in .left p {font-size: 16px;}
    .herosixhundred_five .herosixhundred_five_in p{padding-left: 5px;}
    .champion_nine .champion_nine_in{top: 50px;}
    .champion_eight .champion_eight_in {top: 80px;}
    .champion_nine .champion_nine_in .center img{left: 50px;}
    .blazefiveg_nine .blazefiveg_nine_in h2 {font-size: 35px;line-height: 35px;}
    .afive_nine .afive_nine_in .left p{font-size: 16px;}
    .afive_seven .afive_seven_in .left p {font-size: 16px;line-height: 26px;margin-bottom: 4px;}
    .ten-days img {width: 40%;}
    .afive_seven .afive_seven_in .left img {width: 37%;}
    .asuper .bg-height {height: 530px;}
    .team_imag{height: 172px;}
    ul.listing li{width: 25%;}
    .content_le_team h4{font-size: 17px;}
    .container_one .pro-img img {width:100%;}
    .proul img{width:74%;}
    .blazepro_section .container_one .section-content h1{line-height: 60px;}
    .container.container_eight .section-content .text img {width:88%;}
    .container-fluid.container_ten h2 {line-height: 34px;font-size: 38px;}
    section.featur_pro_list {margin: 40px 15px;}
    .fiftympproduct img {width: 90%;}
    .container.container_nine h2 {font-size:92px;}
    .container.container_nine h3 {font-size: 49px;}
    .container.container_nine .section-content .text img{width: 82%;}
    .blazepro_section .container_one {top:33px;}
    .container_two ul li .text h2 {font-size: 15px;line-height: 20px;}
    .container-fluid.container_six h2 {font-size:50px;line-height: 50px;}
    .container-fluid.container_six label {font-size:17px;line-height:22px;}
    .container-fluid.container_eleven{top: 68px;}
    .container-fluid.container_eleven .section-content .text img {width: 65%;top: 75px;}
    .hot_pro_list {margin: 25px 25px;}
    .one_hot_power_bank.hot_prod3 {height: 359px;}
    .design-prefection .container-fluid .cols .text h2 {font-size: 35px;line-height:35px;}
    .days-battery .container-fluid .cols p{padding-left: 0px;}
    .days-battery .container-fluid {top: 50px;}
    .smart-ai-battery .container-fluid .flex .left .bg-color {padding-top:50px;width: 200px;}
    .smart-ai-battery .container-fluid .flex .left h5{padding-right: 25px;}
    .expand-moory .container-fluid .text .center img{width: 61%;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img {margin-top: 158px;width: 50%;}
}

@media (max-width:540px){
    .blazefiveg_eight .blaze_left_text .center img{width: 49%;}
    .blazefiveg_eight .blaze_left_text .text p {line-height: 20px;font-size: 16px;}
    .blazefiveg_eight .blaze_left_text .text h2 {font-size: 45px;line-height: 40px;}
    .blazefiveg_eight .blaze_left_text .text{margin-top: 5%;}
    .herosixhundred_four .herosixhundred_four_in{width: 90%;}
	.container-fluid.container_ten .section-content .text .center img{margin-top: 10px;}
	.blazepro_section.ten .container-fluid.container_ten .section-content .text .text-in .right{width:30%;}
	.container_two.container{padding-bottom: 10px;}
section.page_title.inner_breadcum{margin-top: 0px;}
    .super-mob-list .herosixhundred_two{margin: 20px auto;overflow: hidden;}
    .three_days_list ul li h6{line-height: 10px;}
    .three_days_list ul li span {font-size: 10px;}
    .champion_four .champion_four_in .in p span {line-height: 10px;}
    .enjoylist ul li .en_list span{font-size: 11px !important;}
    .enjoylist ul li .en_list label {font-size: 10px !important;line-height: 5px !important;}
    .product_details.blaze-enjoy .one_detail1 {top:6%;}
    .one_detail1 .one_detail_content p{line-height: 20px;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 12px !important;}
    .beauty-mode .one_detail_content{top: 0px;}
    .tripal-camera .one_detail_content p {font-size: 16px;letter-spacing: 0;}
    .one_detail1 .one_detail_content p {font-size: 16px !important;}
    .search_bar_dropdown::before{right: 50px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text p{line-height: 12px;}
    .super-ai-battery .container-fluid .battery-life-in .text p {font-size: 14px;}
    .afive_one .afive_one_in .left-text img {width: 85%;}    
    .three_days_list {padding-right: 0%;}
    .spectification_title h5{font-size: 16px;}
    .spectification_details ul li {display: block;}
    .one_sub_menu{margin-bottom: 8px;}
    .one_sub_menu li{width: 100%;}
    .one_sub_menu .spanHeadSecond.black{margin-bottom: 0px;}
    .banner_slider{margin-top: 0px;}
    .product_details.blaze-clean-ui .one_detail1 .left{text-align: center;}
    .product_details.blaze-clean-ui .one_detail1 .left img {width: 76%;}
    .blaze-clean-ui .one_detail_content {top: -40px;}
    .blaze-clean-ui .one_detail_content h3 {font-size: 13px;}
    .blaze-clean-ui .one_detail_content h2 {font-size: 16px;}
    .blaze-clean-ui .one_detail_content h4 {font-size: 30px;line-height: 30px;}
    .blaze-octa-processor .one_detail_content h2 {font-size: 30px;}
    .blaze-octa-processor .one_detail_content h3 {font-size: 16px;line-height: 20px;}
    .blaze-ram-group .one_detail_content .macro-camera h2 {font-size: 16px;}
    .blaze-ram-group .one_detail_content .macro-camera label {font-size: 18px;line-height: 14px;margin-bottom: 0;}
    .blaze-ram-group .one_detail_content .macro-camera h3 {font-size: 30px;}
    .product_details.blaze-ram-group .one_detail1 .left img {width: 55%;}
    .product_details.blaze-ram-group .one_detail1 .left{width: 50%;text-align: center;}
    .blaze-ram-group .one_detail_content{width: 50%;}
    .product_details.blaze-macro-camera .one_detail1 .center img {width: 68%;bottom: 8px;}
    .blaze-macro-camera .one_detail_content {left: 8%;}
    .blaze-macro-camera .one_detail_content{width: 100%;}
    .blaze-macro-camera .one_detail_content p {font-size: 14px !important;}
    .blaze-macro-camera .one_detail_content h2 {font-size: 30px;line-height: 20px;margin-bottom: 5px;}
    .product_details.blaze-dezzle .one_detail1 .center img {top: 110px;}
    .blaze-dezzle .one_detail_content p {font-size: 13px;}
    .blaze-dezzle .one_detail_content h2 span {font-size: 20px;}
    .blaze-dezzle .one_detail_content h2 {font-size: 30px;line-height: 20px;}
    .beauty-mode .one_detail_content .beauty_mode p {font-size: 14px;line-height: 18px;}
    .beauty-mode .one_detail_content{left: 5%;}
    .beauty-mode .one_detail_content .beauty_mode h2 {font-size: 30px;margin-bottom: 5px;}
    .tripal-camera .one_detail_content .center img {margin-top: 10px;width: 70%;}
    .tripal-camera .one_detail_content {top: 25px;}
    .tripal-camera .one_detail_content h2 {font-size: 20px;line-height: 25px;}
    .tripal-camera .one_detail_content h3 {font-size: 30px;line-height: 30px;}
    .product_details.one .one_detail1{padding-left: 0%;}
    .prow_detalpage section.one .right img {width: 50%;}
    .product_details.one .one_detail1 .one_detail_content {width: 60%;}
    .one_detail_content h3 {font-size: 20px;line-height: 18px;}
    .one_detail_content h1 {font-size: 30px;}
    .product_details.one .one_detail1 .one_detail_content {margin-top: 15px;}
    .full_container {max-width: 95%;width: 95%;}
    .zthree.zthree-bg-five img{background: #000;padding-top: 60px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul p {font-size: 14px;line-height: 18px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col ul h6 {font-size: 18px;}
    .zthree.zthree-bg-five .zthree-bg-five-in .horizontal-col {margin-top: 10px;}
    .zthree.zthree-bg-five .zthree-bg-five-in p{line-height: 22px;margin-bottom: 0;}
    .zthree.zthree-bg-six .zthree-bg-six-in p{line-height: 35px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in .pro-img img {margin-top: 30px;width: 60%;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in p {line-height: 21px;font-size: 16px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in {top: 30px;}
    .bottom_list ul li img.col_icon {height: 70px;}
    .bottom_list ul li .texts p {margin-top: 10px;letter-spacing: 0;font-size: 10px !important;line-height: 12px !important;}
    .bottom_list ul li .texts img {height: 25px;margin-top: 0px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center {margin-top: 0px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center img {width: 75%;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in p {font-size: 16px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in h2 {font-size: 30px;}
    .zthree.zthree-bg-eleven .text_two{bottom: 20px;}
    .zthree.zthree-bg-eleven .text h2 {font-size: 25px;line-height: 25px;margin-top: 20px;}
    .container_two ul li{padding: 3px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center img {width: 60%;}
    .container_two ul li .flex-col.lighting {margin-top: 3px;}
    .zthree.zthree-bg-seven .zthree-bg-seven-in h2{    padding: 0;font-size: 30px;line-height: 30px;}
    .zthree.zthree-bg-seven .zthree-bg-seven-in {padding-left: 0px !important;top: 28px;}
    .zthree.zthree-bg-six .zthree-bg-six-in{top: 30px;}
    .zthree.zthree-bg-four .zthree-bg-four-in .bottom-img img {margin-top: 13%;width: 85%;}
    .zthree.zthree-bg-four .zthree-bg-four-in {top: 30px;}
    .container_two.container.zthree_two .blaze_pro_list ul li .flex-col .text p { color: #fff;font-size: 8px;line-height: 9px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h2 {font-size: 18px;line-height: 18px;}
    .zthree.zthree-bg-one .zthree-bg-one-in h1 {font-size: 30px;}
    .zthree.zthree-bg-one .bg-height {height: 320px;}
    .herosixhundred_eight .herosixhundred_eight_in p {font-size: 16px;margin-top: 0px;}
    .herosixhundred_eight .herosixhundred_eight_in .text {margin-top: 40px;}
    .herosixhundred_seven .herosixhundred_seven_in .left label {font-size: 18px;line-height: 12px;}
    .herosixhundred_seven .herosixhundred_seven_in .left img{margin-top: 0px;}
    .herosixhundred_seven .herosixhundred_seven_in .left {width: 100%;}
    .herosixhundred_seven .herosixhundred_seven_in .right img {width: 68%;}
    .herosixhundred_six .herosixhundred_six_in {top: 40px;}
    .herosixhundred_six .herosixhundred_six_in .text .center{text-align: center;}
    .herosixhundred_six .herosixhundred_six_in .text .center img{width: 70%;}
    .herosixhundred_six .herosixhundred_six_in h2 {margin-bottom: 5px;}
    .herosixhundred_six .herosixhundred_six_in p {font-size: 16px;line-height: 18px;}
    .herosixhundred_six .herosixhundred_six_in .text{padding-left: 10px;}
    .herosixhundred_five .herosixhundred_five_in h2{margin-bottom: 0px;}
    .herosixhundred_five .herosixhundred_five_in .left img {width: 65%;margin-top: -65px;}
    .herosixhundred_five .herosixhundred_five_in {top: 30px;}
    .herosixhundred_five .herosixhundred_five_in p {font-size: 14px;line-height: 24px;}
    .herosixhundred_eight .herosixhundred_eight_in h2{padding: 0px 100px;}
    .herosixhundred_four .herosixhundred_four_in .text p {font-size: 15px;letter-spacing: 0;margin-bottom: 0;line-height: 18px;margin-top: 5px;}
    .herosixhundred_three .herosixhundred_three_in .center img {width: 42%;}
    .herosixhundred_three .herosixhundred_three_in {top: 40px;}
    .super-mob-list .herosixhundred_two ul li .text p{padding-left: 0px;}
    .herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left h1 {font-size: 30px;margin-bottom: 20px;}
    .herosixhundred_one .herosixhundred-one {top:55px;}
    .herosixhundred_one .bg-height {height:365px;}
    .champion_eight .champion_eight_in .text .center img{    width: 90%;}
    .champion_eight .champion_eight_in .left{width: 50%;}
    .champion_eight .champion_eight_in .left img {width: 100%;}
    .champion_seven .champion_seven_in h2{line-height: 30px;}
    .champion_three .champion_three_in .right img {width: 62%;}
    .champion_ten .champion_ten_in p {font-size: 16px;line-height: 20px;}
    .champion_ten .champion_ten_in img {width: 55%;}
    .champion_ten .champion_ten_in{top: 45px;}
    .champion_nine .champion_nine_in p{letter-spacing: 0;line-height: 20px;}
    .champion_nine .champion_nine_in .center img {top:50%;    right: -50px;}
    .champion_nine .champion_nine_in{top: 25px;}
    .champion_eight .champion_eight_in p {font-size: 14px;line-height: 22px;}
    .champion_eight .champion_eight_in {top:32px;}
    .champion_seven .champion_seven_in img {width: 75%;}
    .champion_seven .champion_seven_in p {padding-right: 5px;padding-left: 3px;font-size: 13px;line-height:20px;}
    .champion_seven .champion_seven_in{top: 40px;}
    .champion_six .champion_six_in {top: 20px;}
    .champion_six .champion_six_in p {font-size: 13px;line-height: 15px;}
    .champion_five .champion_five_in .left-img img {width: 65%;}
    .champion_five .champion_five_in .text p {font-size: 14px;}
    .champion_five .champion_five_in .text h2 {font-size: 30px;line-height: 30px;}
    .champion_five .champion_five_in .text{top: 0px;}
    .champion_four .champion_four_in .center img {width: 55%;left: 55px;}
    .champion_four .champion_four_in .in p {font-size: 16px;line-height: 20px;}
    .champion_four .champion_four_in{top: 10px;}
    .champion_three .champion_three_in{margin-bottom: 10px;}
    .champion_three .champion_three_in{top: 30px;}
    .super-mob-list .container-fluid ul li .text p{font-size: 13px;line-height: 14px;}
    .champion_one .champion_one_in .left h1 {font-size: 30px;margin-bottom: 20px;}
    .champion_one .bg-height {height: 365px;}
    .blazefiveg_twelve .blazefiveg_twelve_in {top:25px;}
    .blazefiveg_twelve .blazefiveg_twelve_in p {font-size: 16px;line-height: 18px;}
    .blazefiveg_twelve .blazefiveg_twelve_in h2 {font-size: 30px;line-height: 30px;}
    .blazefiveg_eleven .blazefiveg_eleven_in {bottom: 15px;}
    .blazefiveg_eleven .blazefiveg_eleven_in label {font-size: 14px;line-height: 16px;margin-bottom: 0;}
    .blazefiveg_eleven .blazefiveg_eleven_in p.second {font-size: 14px !important;margin-bottom: 5px;}
    .blazefiveg_eleven .blazefiveg_eleven_in p {    margin-bottom: 5px;font-size: 14px !important;line-height: 18px;}
    .blazefiveg_eleven .blazefiveg_eleven_in h2 {font-size: 25px;line-height: 25px;}
    .blazefiveg_ten .two.cum-container-width{bottom: 0px;}
    .blazefiveg_ten .two.cum-container-width p {font-size: 16px;line-height: 17px;}
    .blazefiveg_ten .blazefiveg_ten_in h2 {font-size: 18px;margin-top: 2px;}
    .blazefiveg_ten .blazefiveg_ten_in{top: 30px;}
    .blazefiveg_twelve .two{bottom:5px;}
    .blazefiveg_twelve .blazefiveg_twelve_in img {width: 60%;}
    .blazefiveg_ten .blazefiveg_ten_in label {font-size: 18px; }
    .blazefiveg_ten .blazefiveg_ten_in p {font-size: 18px;line-height: 20px;margin-bottom:5px;}
    .blazefiveg_ten .blazefiveg_ten_in h3 {font-size: 30px;line-height: 30px;}
    .blazefiveg_nine .blazefiveg_nine_in {top: 25px;}
    .blazefiveg_nine .blazefiveg_nine_in label {font-size: 16px;}
    .blazefiveg_nine .blazefiveg_nine_in p {font-size: 13px;line-height:18px;margin-bottom: 20px;}
    .blazefiveg_nine .blazefiveg_nine_in h2 {font-size: 30px;line-height: 30px;}
    .blazefiveg_seven .blazefiveg_seven_in p {font-size: 16px;line-height: 20px;}
    .blazefiveg_seven .blazefiveg_seven_in label {line-height: 28px;font-size: 25px;margin-bottom:5px;}
    .blazefiveg_seven .blazefiveg_seven_in h2 {font-size: 30px;line-height: 30px;margin-bottom: 0;}
    .blazefiveg_seven .blazefiveg_seven_in {top:10px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img {width: 42%;margin-top: 125px;}
    .blazefiveg_six .blazefiveg_six_in {top: 30px;}
    .blazefiveg_six .blazefiveg_six_in p {font-size: 16px;line-height: 18px;}
    .blazefiveg_six .blazefiveg_six_in h2 {font-size:30px;line-height:25px;}
    .blazefiveg_six .blazefiveg_six_in .text {padding-left: 85px;}
    .blazefiveg_five .blazefiveg_five_in {top:20px;}
    .blazefiveg_five .blazefiveg_five_in h2 {font-size: 30px;line-height: 35px;}
    .blazefiveg_five .blazefiveg_five_in label {font-size: 20px;line-height: 25px;}
    .blazefiveg_five .blazefiveg_five_in p {font-size: 16px;line-height: 15px;margin-bottom:8px;}
    .blazefiveg_five .blazefiveg_five_in label {font-size:18px;line-height: 20px;}
    .blazefiveg_five .blazefiveg_five_in h2 {font-size: 30px;line-height: 35px;margin-bottom: 0px;}
    .blazefiveg_four .blazefiveg_four_in p {font-size: 14px;line-height: 15px;padding-right: 5px;padding-left: 2px;}
    .blazefiveg_four .blazefiveg_four_in label {font-size: 16px;line-height: 24px;letter-spacing: 1px;margin-bottom: 5px;padding-right: 5px;
    padding-left: 2px;}
    .blazefiveg_four .blazefiveg_four_in h5 {font-size:18px;line-height: 20px;letter-spacing: 1px;}
    .blazefiveg_four .blazefiveg_four_in h2 {font-size:30px;line-height:29px;}
    .blazefiveg_four .blazefiveg_four_in{top: 40px;}
    .blazefiveg_five .blazefiveg_five_in img {width: 60%;margin-top: 10px;}
    .blazefiveg_four .blazefiveg_four_in img {width: 65%;margin-top: 35px;}
    .blazefiveg_three .blazefiveg_three_in img {width:65%;}
    .blazefiveg_three .blazefiveg_three_in p {font-size: 16px;line-height: 18px;margin-bottom: 10px;}
    .blazefiveg_three .blazefiveg_three_in h1 {font-size: 30px;}
    .afive_eleven .afive_eleven_in{bottom: 20px;}
    .afive_eleven .afive_eleven_in .left {margin-top: 25px;}
    .afive_eleven .afive_eleven_in img {width:60%;}
    .afive_eleven .afive_eleven_in .left h2{line-height: 20px;}
    .afive_eleven .afive_eleven_in .left p {line-height:16px;font-size: 10px;}
    .afive_ten .afive_ten_in .right p {font-size: 16px;line-height: 23px;padding-right: 3px;}
    .afive_ten .afive_ten_in .right h2 {    margin-bottom: 10px;}
    .afive_nine .afive_nine_in .left .center {margin-top: 10px;}
    .afive_nine .afive_nine_in .left p {line-height: 22px;}
    .afive_nine .afive_nine_in .right {width: 70%;}
    .afive_nine .afive_nine_in .left {width: 78%;}
    .afive_nine .afive_nine_in .left h2 {line-height: 30px;font-size: 32px;}
    .afive_eight .afive_eight_in .right img {width: 90%;}
    .afive_eight .afive_eight_in .left h2 {margin-bottom: 10px;}
    .afive_eight .afive_eight_in .left p {font-size: 16px;line-height: 22px;margin-bottom: 3px;}
    .afive_seven .afive_seven_in .left img {width: 24%;}
    .afive_seven .afive_seven_in .left p {letter-spacing: 1px;padding-left: 35px;line-height: 22px;padding-right: 3px;}
    .afive_seven .afive_seven_in .left h2 {margin-top: 28px;line-height: 28px;margin-bottom: 5px;}
    .afive_six .afive_six_in{bottom: 8px;}
    .afive_eight .afive_eight_in{top: 10%;}
    .afive_six .afive_six_in .right p {font-size: 16px;line-height: 20px;letter-spacing: 1px;}
    .afive_five .afive_five_in .left h2 span:nth-child(2) {font-size:30px;}
    .afive_five .afive_five_in .left h2 span:first-child {font-size: 40px;}
    .afive_three .afive_three_in .right img {width: 70%;}
    .super-mob-list.afive_two {padding: 0;}
    .afive_six .afive_six_in .right h2 {margin-top: 40px;}
    .afive_six .afive_six_in .left img {width: 55%;}
    .afive_five .afive_five_in .left h2 {margin-top: 25px;}
    .super-mob-list.afive_two ul li .text h4 {padding: 5px;}
    .afive_one .afive_one_in .right-text img {width: 75%;}
    .afive_one .afive_one_in .left-text h1 {font-size: 30px;margin-bottom: 10px;line-height: 50px;}
    .expand-memory .container-fluid {top: 40px;}
    .super-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 22px;line-height: 22px;}
    .super-ai-battery .container-fluid{top: 30px;}
    .super-ai-battery .container-fluid .battery-life-in .pro img {margin-top: 10px;}
    .super-ai-battery .container-fluid .battery-life-in .text p{line-height: 16px;padding-right:10px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text {width: 342px;}
    .ten-days img {width: 25%;}
    .ten-days {margin-right: -78px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .pro img {width: 35%;top: 0px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text p{margin-bottom: 3px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text h2 {margin-bottom: 8px;font-size: 22px;line-height: 22px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .pro img {width: 58%;padding-top: 10px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text h2 {font-size: 22px;line-height: 22px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 {font-size: 22px;line-height: 22px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text .life h2 span {font-size: 22px;}
    .big-speaker .container-fluid .big-speaker-in .text p {font-size: 16px;line-height: 16px;}
    .big-speaker .container-fluid .big-speaker-in .text h2 {font-size: 22px;}
    .music-lovers .container-fluid .music-lovers-in .text p {font-size: 16px;padding-right: 15px !important;line-height: 17px;}
    .music-lovers .container-fluid .music-lovers-in .text h2 {font-size: 22px;line-height: 23px;}
    .music-lovers .container-fluid .music-lovers-in .pro img{width: 85%;}
    .athreesuper .container-fluid .left-text h1 {font-size: 22px;}
    .athreesuper .bg-height {height: 380px;}
    .cum-container-width {width: 95% !important;}
    .quickbody_content h4{font-size: 22px;}
    .modal-content{width: 98%;}
    .blazepro_section .container_one {width: 92%;}
    .blazepro_section.one .img {height: 380px;}
    .new_short_lists .row .col-md-3:nth-last-child(-n+4) .one_new_vendor {margin-bottom: 10px;}
    .one_news{margin-bottom: 20px;}
    .scrollbar {max-height: initial;}
    .news_content2 {min-height: auto;}
    .ip_content h4 {font-size: 18px;}
    .submit_tabs .tab-content{padding: 30px 20px;}
    .media_cnter.helpline {width: 100%;}
    .media_cnter.helpline ul li{    margin: 10px 0%;}
    .media_cnter ul li {width: 100%;text-align: center;}
    .inner_banner {margin-top: 0px;}
    ul.listing li {width: 50% !important;}
    .one_team_impect {width: 33% !important;}
    .content_le_team h4 {font-size: 20px;}
    .our_leadership{padding: 30px 10px 0 10px !important;}
    .team_imag {height: auto;}
    .our_leadship_content h4 {font-size: 26px;}
    .section_title h4 {font-size: 22px !important;}
    .inner_banner_content {top: 20%;}
    .inner_banner_content h2 {font-size: 26px Im !important;margin-bottom: 0px !important;}
    .inner_banner_content p {font-size: 16px !important;}
    .search_bar_dropdown {width: 95%;right: 0;left: 0;}
    .search_bar_dropdown{padding: 2px;}
    .search_bar_dropdown{margin-bottom: 5px;}
    .country_list{right: 17%;}
    .container_five .row .col .gallery-img img {border-radius: 0px;}
    .container_five {max-width: 92%;}
    .main_menu {display: block;}
    .last_li {margin-left: 0px;}
    .container_two.container{padding: 30px 0px;max-width: 92%;}
    .custom-a img {width: auto;}
    header {padding: 0px 0px;}
    .container_two ul li .text{padding: 10px;}
    .container_six .text img {width: 100%;left: 0;right: 0;margin: 0 auto;text-align: right;margin-top: 40px;}
    .proul img {width:48%;}
    section.hot_pro_list {padding: 60px 8px;}
    .container.container_eight .section-content .text img {width: 92%;margin-top: 20px;}
    .one_hot_power_bank.hot_prod3 {height: auto;}
    .container_two {padding: 30px 30px;}
    .container_three .section-content h2 {font-size: 35px;line-height: 40px;}
    .container_three .section-content label {font-size: 15px;line-height: 16px;}
    .blazepro_section .container_one .section-content{right: 4%;top: 0;}
    .blazepro_section .container_one { top: 45px;}
    .section-logo img {width: 110px;}
    .blazepro_section.eight .img {height: 658px;}
    .blazepro_section.six .img {height: 500px;}
    .container-fluid.container_six {width: 92%;}
    .zthree.zthree-bg-eleven .text_two p {font-size: 14px;line-height: 15px;}
    .blazepro_section .container_one .section-content {width: 186px;}
    .blazepro_section .container_one .section-content label {font-size: 15px;line-height: 15px;}
    .blazepro_section .container_one .section-content h1{font-size: 45px;line-height: 40px;}
    .container_three {top: 40px;width: 92% !important;padding: 0;}
    .container_four {top: 45px;width: 92% !important;padding: 0;}
    .container_four .section-content .text{padding-left: 0;text-align:center;width: 100%;}
    .container_four .section-content h2 {font-size: 55px;line-height: 45px;}
    .container_four .section-content label {font-size: 16px;line-height: 22px;}
    .container_four .section-content p{display: initial;font-size: 16px;}
    .blazepro_section.four .img {height: 545px;}
    .fiftympproduct img {margin-top: 10px;    width:85%;}
    .container-fluid.container_eleven h2 {font-size: 26px;line-height: 25px;margin-bottom: 0;}
    .container-fluid.container_eleven p {font-size: 14px;line-height: 15px;letter-spacing: 1px;}
    .container-fluid.container_eleven h3 {font-size: 17px;line-height: 23px;letter-spacing: 1px;margin: 0;}
    .container-fluid.container_eleven h4 {font-size: 13px;letter-spacing: 1px;line-height: 20px;}
    .container-fluid.container_eleven {top:50px;    width: 92%;}
    .container-fluid.container_ten h2 {line-height: 25px;font-size:26px;}
    .container-fluid.container_ten p {font-size: 12px;    padding-right: 5px;line-height:18px;margin-top: 0px;letter-spacing: 0;}
    .container-fluid.container_ten {width: 92%;}
    .container.container_nine {width: 92% !important;}
    .container.container_nine h3 {font-size: 36px;line-height:36px;    margin-bottom: 0;}
    .container.container_nine p {font-size:20px;}
    .container.container_nine h2 {font-size: 70px;line-height: 62px;}
    .container.container_nine .section-content .text img {margin-top: 35px;width: 90%;}
    .blazepro_section.ten .img {height: 502px;}
    .container.container_eight h2 {font-size:42px;line-height: 40px;}
    .container.container_eight h3 {font-size: 22px;line-height: 25px;margin-bottom: 0px;}
    .container.container_eight p {font-size: 16px;line-height: 25px;}
    .container.container_eight{top: 40px;width: 92%;padding: 0;}
    .blazepro_section.nine .img {height: 630px;}
    .container-fluid.container_ten .right img {width: 140px !important;}
    .container.container_seven h2 {font-size: 32px;line-height: 32px;}
    .container.container_seven p {font-size: 16px;line-height: 20px;margin: 0;}
    .container.container_seven {top: 50px;width: 92%;padding: 0;}
    .container-fluid.container_six h2 {font-size: 39px;line-height: 34px;}
    .container-fluid.container_six label {font-size: 13px;line-height: 17px;}
    .container-fluid.container_six {top: 160px;}
    .container-fluid.container_six .section-content .text .text-in{position: absolute;margin-top: -120px;}
    .container_two ul li .flex-col.lighting .text {top:31%;}
    .product_pricing {padding: 7px 10px;}
    .container_two ul li .flex-col.lighting .text.lf {top: 0%;}
    .container_two ul li .text p {font-size: 11px;margin-top: 0px;}
    .container_two ul li .text h2 {font-size: 13px;line-height: 15px;}
    .blazepro_section.three .img {height: 445px;}
    .product_pricing h4 {font-size: 16px;}
    .product_pricing a {font-size: 16px;}
    .selec_coun{text-align: center;}
    .product_head_1 h4{text-align: center;}
    .main_header .row .col-sm-2{width: 50%;float: left;}
    .main_header .row .text-right{width: 50%;float: left;}
    .head_menu{display: none;     position: absolute;
        background: #fff!important;
        border-top: 3px solid #ea1957 !important;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
        width: 98%;    padding: 8px;
        margin: 0 auto;
        left: 0;    border-radius:0px;
        right: 0;
        top: 55px;}
        .head_menu::before{border-bottom: 10px solid #ea1957;
    content: ' ';
    width: 0;
    height: 0;
    right:0px;
    position: absolute;
    top: -13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;}
.main_menu ul li a {padding-bottom: 0px !important;}
    .head_menu_show{display: revert;}
    .main_menu ul li{display: block;}
    .last_li.burger_menu span i{font-size: 20px;}
    .last_li.r_border{border-left: none;    margin-left: 5px;}
    .detailpage_breadcum .full_container .right a{font-size: 13px;letter-spacing: 0px;}

    .detailpage_breadcum {margin-top:0px;}
    .main_header.fixed_header{padding: 0px 0px;padding-left: 0;position:initial !important;}
    header {background: #fff;position: static;}
    .selec_coun i {font-size:20px;padding: 16px 0px;}
    .main_menu ul li a {padding-bottom: 6px;font-size: 15px;text-align: left;}
    .one_sec_box{margin-top: 6px;margin-bottom: 6px;}
    .hot_pro_list .container-fluid .row:first-child{display: flex;align-items: center;}
    .hot_pro_list .container-fluid .row:first-child {margin-bottom: 10px;}
    .hot_pro_list .container-fluid .row:first-child .col-md-6{width: 50%;}
    .hot_pro_list .container-fluid .row:first-child .col-md-6 h3{ margin-bottom: 0;}
    .hot_pro_row .hot_6 {max-width: 100%;flex: 0 0 98%;}
    .hot_pro_row .hot_4 {max-width: 100%;flex: 0 0 100%;}
    .hot_pro_row .hot_3 {flex: 0 0 100%;max-width: 100%;padding-left: 15px;}
    .one_sec_box h3 {font-size: 14px;}
    a.view_all_btn {font-size: 14px;margin-bottom: 0px;}
    .one_hot_carger.hot_prod5 {padding: 30px 0px;}
    .hot_prod4 {padding: 30px 0px;}
    h3.title {font-size: 20px;}
    .footer-logo{margin-bottom: 20px;}
    .footer_middle_links .row .col-md-4{margin-bottom: 20px;}
    .sub_menu_dropdown{top:0px !important;position: relative;box-shadow: none;padding: 0px 0;}
    .main_header.fixed_header .sub_menu_dropdown {top:0px !important;}
    section.featur_pro_list {margin: 15px 0px;}
    .hot_pro_row .hot_6{flex: 0 0 100%;padding-left: 0;}
    .main_menu ul li a{    padding: 0 12px;}
    .hot_pro_list .container-fluid .row:first-child .col-md-6{padding: 0px;}
    .col-md-4.hot_4{padding: 0px;}
    .hot_pro_row .hot_3{padding: 0px;}
    .inner_breadcum h4{font-size: 16px;}
    .container-fluid.container_eleven .section-content .text img {width:92%;top:35px;}
    .container_one .pro-img img{width: 100%;}
    .c-mar-top {margin-top: 10px;}
    .days-battery .container-fluid .pro-img img {width: 74%;}
    .container_five{padding-top: 0px !important;padding-bottom: 10px !important;padding: 0;margin: 0 auto;position: initial;}
    .super-mob-list{overflow:hidden;padding:0px 0px;}
    .asuper .img-responsive{height: 1100px;object-fit: cover;}
    .asuper .container-fluid .left-text img{width: 100%;}
    .asuper .container-fluid .right-text{text-align: center;margin-top: 38px;width: 50%;}
    .asuper .container-fluid .right-text img{width:85%;}
    .asuper .container-fluid{top: 0px;}
    .super-mob-list ul li .text{top: 3px;}
    .asuper .img-responsive {height: 345px;}
    .super-mob-list ul {display: block;}
    .asuper .container-fluid .left-text h1 {font-size: 32px;}
    .super-mob-list ul li {margin: 15px 2px;width:19%;float: left;}
    .super-mob-list ul li .text h4 {    font-size: 12px;
        line-height: 14px;
        min-height: 32px;}
        .big-speaker .container-fluid .big-speaker-in .text {top: 50px;}
    .design-prefection .container-fluid {top: 30px;}
    .design-prefection .img-responsive{height: 600px;}
    .design-prefection .container-fluid .cols {display: block;text-align: center;}
    .design-prefection .container-fluid .cols .text {width: 100%;}
    .design-prefection .container-fluid .cols .text h2 {margin-right: 0;}
    .design-prefection .container-fluid {padding: 0px 20px;}
    .days-battery .container-fluid .cols .text span {font-size: 135px;line-height: 119px;}
    .days-battery .container-fluid .cols .text .right-text {margin-top:70px;}
    .design-prefection .container-fluid .cols img{margin-top:20px;    width: 50%;}
    .days-battery .container-fluid .cols .text .right-text h2 {font-size: 23px;}    
    .days-battery .container-fluid .cols .text .right-text h2 span {font-size: 18px;line-height: 24px;}
    .days-battery .container-fluid {padding: 0px 20px;}
    .days-battery .img-responsive{height:405px;}
    .smart-ai-battery .container-fluid .flex .right {width: 100%;}
    .smart-ai-battery .container-fluid .flex{display: block;}
    .smart-ai-battery .container-fluid .flex .left_one{width: 100%;}
    .design-prefection .container-fluid .cols .right {width: 100%;}
    .days-battery .container-fluid .cols p{line-height: 18px;font-size: 13px;}
    .battery-pro-list{width: 100%;margin-top: 26px !important;}
    .smart-ai-battery .img-responsive{height: 735px;object-fit: cover;}
    .smart-ai-battery .container-fluid .flex .left .bg-color {padding-top:20px;width: 100%;}
    .smart-ai-battery .container-fluid .flex .left{text-align: center;}
    .bt-support .img-responsive{height: 1065px;object-fit:cover;}
    .hide-on-mobile{display: none;}
    .bt-support .container-fluid{text-align: center;}
    .bt-support .container-fluid {padding: 0px 20px;top: 60px;}
    .bt-support .container-fluid .right p{font-size: 18px;}
    .bt-support .container-fluid .right{margin-bottom: 60px;}
    .bt-support .container-fluid .left img {margin-left: 0px;width:55%;}
    .expand-moory .container-fluid .text p{letter-spacing: 1px;}
    .expand-moory .img-responsive{height: 620px;}
    .expand-moory .container-fluid .text h2 {font-size: 32px;line-height: 35px;}
    .expand-moory .container-fluid {padding: 0px 20px;}
    .expand-moory .container-fluid .text .center img {width: 85%;}
    .auto-call-recording .container-fluid .flex{display: block;}
    .military-grade .container-fluid .flex .right{width: 100%;}
    .auto-call-recording .img-responsive{height:725px;object-fit: cover;visibility: hidden;}
    .auto-call-recording .container-fluid {padding: 0px 20px;bottom: 0px;margin-bottom: 40px;}
    .auto-call-recording .container-fluid .text{margin-top: 0px;width: 100%;padding-right: 0;}
    .auto-call-recording .container-fluid .pro {width: 100%;}
    .military-grade .container-fluid{top: 25px;padding: 0px 20px;}
    .military-grade .container-fluid .flex{display: block;}

    .military-grade .img-responsive{height: 700px;object-fit: cover;}
    .military-grade .container-fluid .flex .left{width: 100%;text-align: center;}
    .military-grade .container-fluid .flex .left h2{margin-bottom: 5px;}
    .military-grade .container-fluid .flex .left{margin-bottom: 45px;}
    .military-grade .container-fluid .flex .left p{line-height: 26px;}
    .military-grade .container-fluid .flex .right img {width: 74%;}
    .icon-block{justify-content: end;}
    .last_li .selec_coun{align-items: center;}
    .last_li .selec_coun span{margin-top: 4px;font-size:15px;}
    .profile_team_popup .team_profile{margin-bottom: 10px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in img{width: 65%;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text h2 {font-size: 22px;}
    .product_details.blaze-dezzle .one_detail1 {left: 2%;}
    .herosixhundred_four .herosixhundred_four_in .text img {height:25px;}
    .three_days_list ul li h6 {font-size: 12px;}
    .summery tbody tr td, .summery thead th {border: none!important;font-size: 16px !important;}
    .table td, .table th{padding: 5px;}
    .table-responsive{width:100%;}
    .gallery .previews {width: 20%;max-height: 355px;}
    .gallery .full {width: 80%;margin-right: 0;}
    .hot_pro_row .hot_3 .one_sec_box{height: auto;}
    .blaze-macro-camera .one_detail_content{left: 0;}
    .spectification_details ul li strong{margin-right: 0%;min-width: 100%;width: 100%;}
    .main_menu ul li.product_dropdown_list:hover .sub_menu_dropdown{position: inherit !important;padding-top: 5px;}
}

@media (min-width:541px){
    .show_on_laptop{display: none;}
    .last_li.burger_menu{display: none;}
}

@media (max-width:414px){
    .container_two.container.zthree_two .blaze_pro_list ul li .flex-col.lighting .text{top: 0%;}
    .blazefiveg_eight .blaze_left_text .text img {height: 35px;}
    .blazefiveg_eight .blaze_left_text .center img {width: 47%;}
    .afive_eleven .afive_eleven_in img {margin-left: 25px;}
.music-lovers {margin-top: 20px;}
    .herosixhundred_four .herosixhundred_four_in .text img {height: 25px;}
    .blaze-dezzle .one_detail_content h2 {font-size: 23px;}
    .enjoylist {width: 100% !important;}
    .enjoylist ul li .en_list img {width: 25px !important;height: 25px !important;}
    .product_details.blaze-enjoy .one_detail1 .center img {width: 85%;}
    .one_detail_content h4 span{padding: 0px 4px;}
    .blaze-dezzle .one_detail1 .one_detail_content p {font-size: 8px !important;}
    .one_detail1 .one_detail_content p {font-size: 12px !important;line-height: 16px;}
    .gallery .previews {max-height: 255px;}
    .smart-ai-battery .container-fluid .right img{width: 100%;}
    .bt-support .container-fluid{top: 40px;}
    .auto-call-recording .container-fluid{margin-bottom: 20px;}
    .expand-moory .container-fluid .text .center img {width: 100%;margin-top: 30px;}
    .athreesuper .bg-height {height: 310px;}
    .music-lovers .container-fluid .music-lovers-in .pro img {width: 75%;}
    .music-lovers .container-fluid .music-lovers-in .text p {font-size: 12px;line-height: 13px;}
    .big-speaker .container-fluid .big-speaker-in .text p {font-size: 12px;}
    .battery-lifes .container-fluid .battery-life-in .text h5 {font-size: 15px;}
    .battery-lifes .container-fluid .battery-life-in .battery-text{margin-bottom: 0px !important;}
    .battery-lifes .container-fluid .battery-life-in .pro img {width: 65%;}
    .super-ai-battery .container-fluid .battery-life-in .pro img{margin-top: 0px;}
    .super-ai-battery .container-fluid .battery-life-in .text p{    padding-right: 10px;font-size: 10px;line-height: 8px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text p {padding-right: 8px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .pro img {width: 48%;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text p {line-height: 10px;padding-right: 8px;}
    .expand-memory .container-fluid {top: 25px;}
    .super-ai-battery .container-fluid .battery-life-in .text{top: -15px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p {font-size: 12px;line-height: 10px;}
    .herosixhundred_four .herosixhundred_four_in{top: 10px;}
    .herosixhundred_four .herosixhundred_four_in .text {width:70%;}
    .blazefiveg_five .blazefiveg_five_in {top: 20px;}
    .blazefiveg_three .blazefiveg_three_in {top: 20px;}
    .bottom_list ul li img.col_icon {height: 60px;}
    .blaze-octa-processor .one_detail_content h2 {font-size: 22px;}
    .blaze-clean-ui .one_detail_content{width: 100%;text-align: center;}
    .product_details.blaze-clean-ui .one_detail1 .left img {width: 65%;margin-top: 35px;}
    .product_details.blaze-octa-processor .one_detail1{top: 2%;}
    .blaze-octa-processor .one_detail_content h3 {font-size: 13px;line-height: 13px;}
    .blaze-ram-group .one_detail_content {width: 65%;}
    .product_details.blaze-ram-group .one_detail1 .left {width: 40%;}
    .product_details.blaze-ram-group .one_detail1 .left img {width: 70%;}
    .product_details.blaze-macro-camera .one_detail1 .center img {width: 65%;}
    .blaze-macro-camera .one_detail_content p { font-size: 13px !important;margin-top: 0;line-height: 14px;}
    .product_details.blaze-dezzle .one_detail1 .center img {top: 85px;}
    .product_details.beauty-mode .one_detail1 .center img {top: 20px;width: 65%;}
    .product_details.beauty-mode .one_detail1 .center img {width: 65%;}
    .tripal-camera .one_detail_content .center img{width: 58%;}
    .tripal-camera .one_detail_content h2 {font-size: 16px;line-height: 16px;}
    .one_detail_content p{font-size: 12px;line-height: 16px;}
    .one_detail_content h4 {font-size: 12px;margin-bottom: 5px;}
    .herosixhundred_one .bg-height {height: 300px;}
    .zthree.zthree-bg-eleven .text_two p {font-size: 15px;line-height: 16px;}
    .zthree.zthree-bg-eleven .text h2 {font-size: 20px;line-height: 20px;}
    .bottom_list ul li .texts p{font-size: 8px !important;line-height: 10px !important;}
    .bottom_list ul li .texts {padding: 5px;}
    .bottom_list ul li .texts img {height: 22px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in p {margin-bottom: 5px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text .center img {width:50%;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in h2 {font-size: 25px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in .pro-img img{width: 55%;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in {top: 18px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in p {line-height: 16px;font-size: 12px;}
    .zthree.zthree-bg-eight .zthree-bg-eight-in h2 {line-height: 25px;font-size: 25px;}
    .zthree.zthree-bg-seven .zthree-bg-seven-in h2 {font-size: 25px;line-height: 25px;}
    .zthree.zthree-bg-six .zthree-bg-six-in p {line-height: 25px;}
    .zthree.zthree-bg-six .zthree-bg-six-in p {font-size: 16px;padding-right: 5px;}
    .zthree.zthree-bg-six .zthree-bg-six-in h2 {font-size: 25px;line-height: 25px;}
    .zthree.zthree-bg-five .zthree-bg-five-in h2 {line-height: 25px;font-size: 25px;}
    .zthree.zthree-bg-four .zthree-bg-four-in .bottom-img img {margin-top: 18%;width: 70%;}
    .zthree.zthree-bg-four .zthree-bg-four-in p {font-size: 13px;line-height: 16px;}
    .zthree.zthree-bg-four .zthree-bg-four-in h2 {line-height: 25px;font-size: 25px;}
    .container_two ul li .text {padding: 5px;}
    .zthree.zthree-bg-one .bg-height {height: 250px;}
    .blazefiveg_four .blazefiveg_four_in h2 {font-size: 25px;line-height: 25px;}
    .blazefiveg_three .blazefiveg_three_in p {font-size: 14px;line-height: 16px;margin-bottom: 10px;margin-top: 5px;}
    .blazefiveg_three .blazefiveg_three_in h1 {font-size: 25px;}
    .container_two ul li .flex-col.lighting .text.lf{padding: 5px;}
    .inner_breadcum h4 {font-size: 15px;}
    .champion_ten .champion_ten_in p {font-size: 13px;line-height: 16px;}
    .champion_ten .champion_ten_in h2 {font-size: 24px;line-height: 22px;margin-bottom: 5px;}
    .champion_nine .champion_nine_in p {font-size: 11px;line-height: 20px;}
    .champion_nine .champion_nine_in h2 {font-size: 25px;line-height: 25px;}
    .champion_eight .champion_eight_in .center img{width: 80%;}
    .champion_eight .champion_eight_in p {font-size: 12px;line-height: 15px;margin-bottom: 10px;}
    .champion_eight .champion_eight_in h2 {font-size: 22px;line-height: 22px;}
    .champion_eight .champion_eight_in .left{width: 50%;}
    .champion_eight .champion_eight_in .left img{width: 100%;}
    .champion_seven .champion_seven_in {top: 25px;}
    .champion_seven .champion_seven_in p {font-size: 10px;line-height: 15px;}
    .champion_seven .champion_seven_in h2 {font-size: 25px;line-height: 25px;}
    .champion_six .champion_six_in p {font-size: 10px;line-height: 12px;margin-bottom: 5px;margin-top: 0;}
    .champion_six .champion_six_in h2 {font-size: 25px;line-height: 25px;}
    .champion_five .champion_five_in .text p {font-size: 10px;line-height: 10px;}
    .champion_five .champion_five_in .text h2 {font-size: 25px;line-height: 25px;}
    .champion_four .champion_four_in .center img{left: 90px;}
    .champion_four .champion_four_in .in p {font-size: 14px;line-height: 14px;margin-top: 5px;}
    .champion_four .champion_four_in .in .text label {font-size: 25px;height: 60px;}
    .champion_four .champion_four_in h2 {font-size: 25px;line-height: 22px;}
    .champion_three .champion_three_in .right img {width: 65%;}
    .champion_three .champion_three_in h2 {font-size: 25px;line-height: 25px;margin-bottom: 10px;}
    .super-mob-list .container-fluid ul li .text p {font-size: 9px;line-height: 10px;text-align: center;width: 100%;display: block;margin-top: 5px;}
    .champion_one .champion_one_in .left h1 {font-size: 25px;}
    .champion_one .bg-height {height: 290px;}
    .herosixhundred_seven .herosixhundred_seven_in .left h2 {font-size: 25px;line-height: 25px;}
    .herosixhundred_five .herosixhundred_five_in h2 {font-size: 25px;line-height: 25px;}
    .herosixhundred_four .herosixhundred_four_in .text h2 {line-height: 25px;font-size: 25px;}
    .herosixhundred_three .herosixhundred_three_in h2 {font-size: 25px;line-height: 25px;letter-spacing: 1px;}
    .herosixhundred_one .herosixhundred-one .herosixhundred_one_in .left h1 {font-size: 25px;}
    .herosixhundred_four .herosixhundred_four_in .rightimg img {width: 90%;}
    .herosixhundred_four .herosixhundred_four_in .text p {font-size: 11px;}
    .herosixhundred_eight .herosixhundred_eight_in h2{font-size: 20px;}
    .herosixhundred_eight .herosixhundred_eight_in h3 {font-size: 25px;line-height: 25px;}
    .herosixhundred_eight .herosixhundred_eight_in p {font-size: 13px;}
    .herosixhundred_eight .herosixhundred_eight_in .text {margin-top: 20px;}
    .herosixhundred_eight .herosixhundred_eight_in .center img {width: 60%;}
    .herosixhundred_seven .herosixhundred_seven_in .left {width: 60%;}
    .herosixhundred_seven .herosixhundred_seven_in .left img {width: 38%;float: right;margin-right: 20px;}
    .herosixhundred_seven .herosixhundred_seven_in {top: 25px;}
.afive_eleven .afive_eleven_in .left p{padding-right: 8px;padding-left: 5px;}
     .herosixhundred_seven .herosixhundred_seven_in .right{width: 50%;}
    .herosixhundred_seven .herosixhundred_seven_in .right img {width:80%;}
    .herosixhundred_six .herosixhundred_six_in .text .right{text-align: center;}
    .herosixhundred_six .herosixhundred_six_in .text .right img{width: 50%;}
    .herosixhundred_six .herosixhundred_six_in .text {padding-left: 0px;}
    .herosixhundred_six .herosixhundred_six_in p {font-size: 12px;line-height: 16px;margin-bottom: 10px;}
    .herosixhundred_six .herosixhundred_six_in p {font-size: 12px;line-height: 16px;}
    .herosixhundred_six .herosixhundred_six_in h2 {font-size: 25px;line-height: 25px;}
    .herosixhundred_five .herosixhundred_five_in .left img {width: 60%;}
    .herosixhundred_five .herosixhundred_five_in p {font-size: 10px;line-height: 21px;}
    .herosixhundred_three .herosixhundred_three_in .center img {width: 38%;}
    .afive_ten .afive_ten_in .right p {font-size: 13px;margin-bottom: 0;padding-left: 10px;line-height: 19px;}
    .afive_ten .afive_ten_in .right h2 {font-size: 23px;line-height: 25px;    margin-bottom:5px;}
    .afive_nine .afive_nine_in .left p{padding-right: 8px;    font-size: 12px;line-height: 18px;}
    .afive_nine .afive_nine_in .left h2 {line-height: 25px;font-size: 25px;}
    .afive_eight .afive_eight_in .left .m-image{margin-top: 20px;}
    .afive_eight .afive_eight_in .left p {letter-spacing: 0;font-size: 14px;line-height: 17px;}
    .afive_eight .afive_eight_in .left h2 {font-size: 25px;line-height: 25px;}
    .afive_seven .afive_seven_in .left img {width: 20%;margin-top:5px;}
    .afive_seven .afive_seven_in .left p{letter-spacing: 0px;padding-left: 20px;line-height: 18px;font-size: 15px;}
    .afive_seven .afive_seven_in .left h2 {font-size: 24px;margin-top: 17px;line-height: 20px;}
    .afive_six .afive_six_in .right p{letter-spacing: 0px;}
    .afive_eleven .afive_eleven_in .left h2 {font-size: 25px;line-height: 20px;}
    .afive_six .afive_six_in .right h2 {font-size: 25px;line-height: 25px;}
    .afive_five .afive_five_in .left h2 span:nth-child(2) {font-size: 25px;}
    .afive_six .afive_six_in .right p {font-size: 14px;line-height: 15px;}
    .afive_five .afive_five_in .left h2 span:first-child {font-size:30px;}
    .afive_five .afive_five_in .left h2 {font-size: 25px;line-height: 25px;margin-top: 15px;}
    .afive_four .afive_four_in .left h2 {font-size: 25px;line-height: 25px;margin-top: 0;}
    .afive_three .afive_three_in .left h2 {font-size: 25px;margin-top: 0px;    line-height: 35px;}
    .super-mob-list.afive_two {padding: 30px 0px;}
    .afive_six .afive_six_in .left img {width: 50%;}
    .afive_one .afive_one_in{bottom: 5%;}
    .afive_six .afive_six_in .right{top: -50%;}
    .afive_one .afive_one_in .flex{align-items: end;}
    .one_team_impect h6 {font-size: 22px !important;}
    .one_team_impect {width: 50% !important;}
    .one_team_impect {width: 50%;}
    .sub_menu_dropdown {top: 95px !important;}
    .search_bar_dropdown {margin-top: 0px;}
    .country_list {right: 22%;}
    header {padding: 0px 0px;}
    .main_menu ul li a{padding: 0px 5px;}
    .container.container_seven img {margin-top: 20px;}
    .proul img {width:65%;}
    .main_menu ul li a{    padding-bottom: 10px;}
    .one_sec_box a {font-size: 14px;}
    .one_sec_box h3 {font-size: 15px;}
    .container-fluid.container_six {top: 200px;}
    .container_two {padding: 50px 25px;}
    .blazepro_section.one .img {height: 280px;}
    .blazepro_section .container_one {top: 24px;}
    .container_three .section-content h2 {font-size: 28px;line-height: 30px;}
    .container_four .section-content p{font-size: 15px;}
    .fiftympproduct img {margin-top: 10px;    width:100%;}
    .blazepro_section .container_one .section-content {width: 186px;}
    .blazepro_section .container_one .section-content h1 {font-size: 40px;line-height: 38px;}
    .blazepro_section .container_one .section-content label{line-height: 15px;}
    .container.container_seven h2 {font-size: 24px;}
    .container.container_seven {top: 30px;}
    .blazefiveg_four .blazefiveg_four_in p {font-size: 12px;line-height: 10px;margin-bottom: 0;}
    .blazefiveg_four .blazefiveg_four_in h5 {margin-bottom: 0px;letter-spacing: 0;font-size: 16px;line-height: 15px;}
    .blazefiveg_four .blazefiveg_four_in label {letter-spacing: 0; font-size: 14px;line-height: 20px;padding-right: 10px;margin-bottom:0px;}
    .container_two ul li .flex-col.lighting.lf_f {margin-top: 3px;}
    .blazefiveg_four .blazefiveg_four_in {top: 20px;}
    .blazefiveg_six .blazefiveg_six_in {top: 20px;}
    .blazefiveg_ten .blazefiveg_ten_in {top: 20px;}
    .blazefiveg_twelve .two{bottom: 10px;}
    .blazefiveg_twelve .blazefiveg_twelve_in {top: 20px;}
    .blazefiveg_twelve .blazefiveg_twelve_in img {margin-top: 8px;}
    .blazefiveg_twelve .blazefiveg_twelve_in p {font-size: 14px;line-height: 16px;margin: 5px 0px;}
    .blazefiveg_twelve .blazefiveg_twelve_in h2 {font-size: 22px;line-height: 22px;}
    .blazefiveg_eleven .blazefiveg_eleven_in label {font-size: 11px;line-height: 14px;}
    .blazefiveg_eleven .blazefiveg_eleven_in p.second {font-size: 11px !important;margin-bottom: 0px;}
    .blazefiveg_eleven .blazefiveg_eleven_in p {margin-bottom: 5px;font-size: 10px !important;line-height: 13px;}
    .blazefiveg_eleven .blazefiveg_eleven_in h2 {font-size: 20px;line-height: 20px;margin-bottom: 2px;}
    .blazefiveg_ten .two.cum-container-width p {font-size: 14px;line-height: 15px;    margin-bottom: 5px;}
    .blazefiveg_ten .blazefiveg_ten_in h2 {font-size: 15px;margin: 0;}
    .blazefiveg_ten .blazefiveg_ten_in label {font-size: 15px;}
    .blazefiveg_ten .two.cum-container-width {bottom: 10px;}
    .blazefiveg_ten .blazefiveg_ten_in p {font-size: 15px;line-height: 16px;margin-bottom: 0px;}
    .blazefiveg_ten .blazefiveg_ten_in h3 {font-size: 25px;line-height: 25px;margin-bottom: 5px;}
    .blazefiveg_nine .blazefiveg_nine_in label {font-size: 11px;line-height: 11px;padding: 2px 5px;}
    .blazefiveg_nine .blazefiveg_nine_in p {font-size: 10px;line-height: 13px;margin-bottom: 10px;}
    .blazefiveg_nine .blazefiveg_nine_in h2 {font-size: 22px;line-height: 22px;}
    .blazefiveg_seven .blazefiveg_seven_in p {font-size: 15px;line-height: 18px;padding-right: 20px;}
    .blazefiveg_seven .blazefiveg_seven_in label {line-height: 20px;font-size:18px;margin-bottom: 2px;}
    .blazefiveg_seven .blazefiveg_seven_in h2 {font-size: 25px;line-height: 25px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img {width: 43%;margin-top: 100px;}
    .blazefiveg_six .blazefiveg_six_in p {font-size: 15px;line-height: 18px;margin-top: 5px;}
    .blazefiveg_six .blazefiveg_six_in .text {padding-left: 60px;}
    .blazefiveg_six .blazefiveg_six_in h2 {font-size: 30px;line-height: 25px;}
    .blazefiveg_five .blazefiveg_five_in p {font-size: 15px;line-height: 14px;margin-bottom: 5px;padding: 5px 15px;}
    .blazefiveg_five .blazefiveg_five_in label {font-size: 16px;line-height: 18px;}
    .blazefiveg_five .blazefiveg_five_in h2 {font-size: 25px;line-height: 25px;}
    .container_two ul li .text p {font-size: 8px;line-height: 10px;}
    .container-fluid.container_ten h2 {line-height: 20px;font-size: 22px;}
    .container-fluid.container_eleven {padding-left: 0px;}
    .blazepro_section.eight .img {height: 540px;}
    .container.container_nine h2 {font-size: 54px;line-height: 54px;margin-left: 0px;}
    .container-fluidcontainer_eleven h1 {font-size: 24px;line-height: 18px;}
    .container-fluid.container_eleven p{margin: 0px;}
    .container.container_nine {top: 50px;}
    .blazepro_section.ten .img {height: 400px;}
    .container-fluid.container_ten {top: 50px;}
    .blazepro_section.nine .img {height: 540px;}
    .container.container_nine p {font-size: 18px;}
    .container-fluid.container_eleven .section-content .text img { width:88%;top:140px;}
    .container.container_nine .section-content .text img{width:100%;}
    .container-fluid.container_ten p {font-size: 12px;padding-left: 3px;}
    .container-fluid.container_eleven h2 {font-size: 24px;line-height: 24px;}
    .container-fluid.container_eleven p {font-size: 12px;line-height: 17px;}
    .container-fluid.container_eleven h3 {font-size: 15px;}
    .container.container_eight .text .text-in {padding-left: 0px;}
    .asuper .container-fluid .left-text h1 {font-size: 32px;}
    .asuper .img-responsive{height:275px;}
    .design-prefection .container-fluid .cols img {width: 65%;}
    .smart-ai-battery .container-fluid .right img {margin-left: 0px;width: 90%;}
    .bt-support .img-responsive {height: 860px;}
    .expand-moory .img-responsive {height: 565px;}
    .battery-pro-list {margin-top: 25px !important;}
    .days-battery .container-fluid .cols p {line-height: 15px;font-size: 10px;}
    .days-battery .container-fluid .cols .text span {font-size: 135px;line-height: 110px;}
    .days-battery .container-fluid .cols .text .right-text h2 span {font-size: 12px;line-height: 14px;}
    .auto-call-recording .img-responsive {height: 570px;}
    .auto-call-recording .container-fluid .text{margin-bottom: 25px;}
    .auto-call-recording .container-fluid .text h2 {font-size: 42px;line-height: 36px;}
    .auto-call-recording .container-fluid .text p {font-size: 18px;line-height: 18px;}
    .military-grade .container-fluid .flex .left h2 {font-size: 42px;}
    .military-grade .img-responsive {height: 585px;}
    .military-grade .container-fluid .flex .left p {font-size: 16px;}
    .bt-support .container-fluid .right h2 {font-size: 38px;line-height: 35px;}
    .bt-support .container-fluid .right p {font-size: 16px;}
    .super-mob-list ul li .text h4{line-height:10px;font-size:8px;    min-height: 15px;}
    h3.title {font-size: 17px;}
    .super-mob-list ul li{    margin: 0px 1px;}
    .days-battery .container-fluid .pro-img img {width: 100%;}
    .container_two ul li .text h2{font-size: 10px;line-height: 11px;}
    .container-fluid.container_ten .right img {width: 122px !important;}
    .container_two ul li .flex-col.lighting .text {top: 35%;}
}

@media (max-width:393px){
    .blazefiveg_eight .blaze_left_text .text p {line-height: 18px;font-size: 15px;}
    .afive_eleven .afive_eleven_in .left h2{margin-bottom: 0px;}
    .expand-moory .container-fluid .text p {letter-spacing: 0px;}
    .military-grade .container-fluid .flex .left p {line-height: 20px;}
    .blazefiveg_twelve .blazefiveg_twelve_in p {font-size: 11px;line-height: 11px;}
    .blazefiveg_five .blazefiveg_five_in img{margin-top: 0px;}
    .container-fluid.container_six label {line-height: 14px;}
    .enjoylist ul li .en_list label {font-size: 10px!important;}
    .one_detail1 .one_detail_content p{line-height: 14px;}
    .gallery .previews {max-height: 240px;}
    .afive_six .afive_six_in .right p {font-size: 10px;}
    .proul img {width: 66%;}
    .zthree.zthree-bg-eleven .text_two p {font-size: 11px;}
    .bottom_list ul li .texts p{margin-top: 2px !important;}
    .bottom_list ul li img.col_icon {height: 52px;}
    .bottom_list ul li .texts img {height: 18px;}
    .blazepro_section.one .img {height: 260px;}
    .blaze-ram-group .one_detail_content .macro-camera h2 {font-size: 14px;margin-top: 8px;}
    .blaze-ram-group .one_detail_content .macro-camera label {font-size: 15px;line-height: 10px;}
    .blaze-ram-group .one_detail_content .macro-camera h3 {font-size: 25px;}
    .blaze-dezzle .one_detail_content p {font-size: 13px;}
    .tripal-camera .one_detail_content p {font-size: 11px;}
    .blaze-macro-camera .one_detail_content p {font-size: 11px !important;}
    .beauty-mode .one_detail_content .beauty_mode p {font-size: 12px;line-height: 14px;}
    .one_detail_content p {font-size: 10px;line-height: 13px;}
    .afive_eleven .afive_eleven_in .left p {padding-right: 5px;padding-left: 3px;}
    .zthree.zthree-bg-six .zthree-bg-six-in p {font-size: 15px;}
    .champion_six .champion_six_in p {font-size: 9px;}
    .champion_six .champion_six_in h2 {font-size: 20px;line-height: 20px;}
    .champion_four .champion_four_in .in .text label {font-size: 20px;}
    .champion_four .champion_four_in .in p {font-size: 13px;line-height: 13px;}
    .champion_four .champion_four_in h2 {font-size: 20px;line-height: 20px;}
    .champion_three .champion_three_in h2 {font-size: 20px;line-height: 20px;}
    .champion_one .champion_one_in .left h1 {font-size: 22px;}
    .inner_breadcum h4 {font-size: 13px;}
    .blazefiveg_nine .blazefiveg_nine_in {top: 20px;}
    .blazefiveg_six .blazefiveg_six_in p {font-size: 13px;line-height: 16px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img {width: 42%;}
    .container_three .section-content h2{line-height: 42px;}
    .last_li .selec_coun {margin-left: 11px;}
    .section-logo img {width: 85px;}
    .asuper .container-fluid .right-text img {width: 82%;}
    .design-prefection .container-fluid .cols img {width: 70%;}
    .smart-ai-battery .container-fluid .right img{width: 100%;}
    .main_menu ul li a{padding: 0px 6px;}
    .container.container_nine p {font-size: 16px;}
    .container.container_nine h2{font-size: 50px;}
    .container-fluid.container_ten .right img {width: 104px!important;margin-bottom: 10px;}
    .container-fluid.container_ten p{font-size: 12px;line-height: 15px;padding-left: 5px;}
    .blazepro_section.ten .img {height: 368px;}
    .blazepro_section.eleven .img {height: 480px;}
    .blazepro_section.nine .img {height: 520px;}
    .blazepro_section.eight .img {height: 520px;}
    .container.container_seven {top: 20px;}
    .blazepro_section.six .img {height: 435px;}
    .blazepro_section.four .img {height: 500px;}
    .blazepro_section .container_one .section-content {width: 186px;}
    .herosixhundred_four .herosixhundred_four_in .text img{margin-top: 5px;}
    .three_days_list ul li span{    font-size: 12px;}
}

@media (max-width:390px){
    .gallery .previews {max-height: 235px;}
    .battery-pro-list {margin-top: 41px !important;}
    .container-fluid.container_ten p{line-height:18px;}
    .container.container_nine .section-content .text img {margin-top: 15px;}
    .top_menu{justify-content: center;}
    .asuper .container-fluid .right-text img {width: 85%;}
    .design-prefection .container-fluid .cols img {width: 70%;}
    .smart-ai-battery .container-fluid .right img{width: 100%;}
    .bt-support .container-fluid .left img{width: 58%;}
    .military-grade .container-fluid .flex .right img {width:72%;}
    .container-fluid.container_ten .right img {width: 102px !important;margin-bottom: 10px;}
    .athree-autocall-recording .container-fluid .athree-autocall-recording-in .text p{padding: 4px 10px;}
}

@media (max-width:375px){
    .days-battery .container-fluid .cols p {line-height: 11px;font-size: 8px;}
    .prow_detalpage section.one .right img {width:57%;}
    .gallery .previews {max-height: 225px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in .text p {font-size: 14px;margin-bottom: 0px;}
    .big-speaker .container-fluid .big-speaker-in .text p {font-size: 10px;}
    .music-lovers .container-fluid .music-lovers-in .text p{letter-spacing: 0;}
    .athreesuper .bg-height {height: 275px;}
    .expand-memory .container-fluid .expand-memory-ins .expand-memory-in img.memory {margin-top: 5px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text{top: 35px;}
    .btsuper-ai-battery .container-fluid .battery-life-in .text p {font-size: 14px;line-height: 10px;margin-bottom: 0;}
    .super-ai-battery .container-fluid .battery-life-in .text p {font-size:9px;}
    .herosixhundred_seven .herosixhundred_seven_in .left p {font-size: 13px;line-height: 15px;margin: 5px 0px;}
    .zthree.zthree-bg-ten .zthree-bg-ten-in .text {margin-top: 15px;}
    .afive_eleven .afive_eleven_in .left p{font-size: 9px;}
    .champion_seven .champion_seven_in p{padding-right: 10px;}
    .blazepro_section .container_one .section-content {width: 166px;}
    .blazepro_section .container_one .section-content label {font-size: 13px;}
    .container_two ul li .flex-col.lighting {margin-top: 2px;}
    .container-fluid.container_ten h2 {line-height: 18px;font-size: 18px;margin-bottom: 0;}
    .container-fluid.container_ten p{line-height: 18px;}
    .container.container_nine .section-content .text img {width: 80%;}
    .container.container_eight h2{line-height: 26px;}
    .container.container_eight .section-content .text img {width: 89%;}
    .container_one .pro-img img{margin-top: 0px;}
    .proul img {width:71%;}
    .container-fluid.container_eleven .section-content .text img{width: 100%;}
    .container_three .section-content h2{line-height: 36px;}
    .container.container_eight p {font-size: 15px;}
    .container-fluid.container_eleven {top: 30px;}
    .design-prefection .container-fluid .cols img {width: 75%;}
    .main_menu ul li a{font-size: 14px;}
    .blazepro_section.eight .img {height: 475px;}
    .container.container_nine h2 {font-size:48px;line-height: 36px;}
    .container.container_nine h3 {font-size: 26px;line-height: 30px;}
    .blazepro_section.nine .img {height: 405px;}
    .container-fluid.container_ten .right img {width: 88px !important;}
}

@media (max-width:360px){
    .blazefiveg_eight .blaze_left_text .text h2 {font-size: 35px;line-height: 25px;}
    .blazefiveg_six .blazefiveg_six_in .pro-img img {width:39%;}
    .blazefiveg_nine .blazefiveg_nine_in label{font-size: 10px;}
    .blazefiveg_nine .blazefiveg_nine_in h2 {font-size: 18px;}
    .tripal-camera .one_detail_content p {font-size: 10px;letter-spacing: 0;}
    .enjoylist ul li .en_list span {font-size: 8px !important;position: relative;top: -4px;}
    .enjoylist ul li .en_list label {font-size: 8px!important;}
    .one_detail1 .one_detail_content p {font-size: 9px!important;}
    .enjoylist ul li .en_list img {height: 20px!important;width: 20px!important;}
    .gallery .previews {max-height: 212px;}
    .three_days_list ul li h6 {font-size: 12px;}
    .afive_ten .afive_ten_in .right h2{margin-bottom: 0px;}
    .afive_ten .afive_ten_in .right p {font-size: 11px;line-height: 18px;}
    .herosixhundred_seven .herosixhundred_seven_in {top: 20px;}
    .herosixhundred_seven .herosixhundred_seven_in .left p {font-size: 14px;line-height: 16px;}
    .herosixhundred_six .herosixhundred_six_in {top: 20px;}
    .herosixhundred_six .herosixhundred_six_in .text {width: 52%;}
    .herosixhundred_six .herosixhundred_six_in .left {width: 48%;}
    .herosixhundred_five .herosixhundred_five_in .left img{margin-top: -50px;}
    .herosixhundred_five .herosixhundred_five_in {top: 20px;}
    .herosixhundred_five .herosixhundred_five_in .text {padding-left: 30%;}
    .herosixhundred_one .bg-height {height: 255px;}
    .blazefiveg_five .blazefiveg_five_in {top: 10px;}
    .blazefiveg_twelve .two {bottom: -5px;}
    .blazefiveg_twelve .blazefiveg_twelve_in img {width: 50%;}
    .proul img {width:75%;}
    .zthree.zthree-bg-six .zthree-bg-six-in p {font-size: 12px;}
    .zthree.zthree-bg-four .zthree-bg-four-in h2{margin-bottom: 0;}
    .zthree.zthree-bg-four .zthree-bg-four-in {top: 18px;}
    .blazepro_section.eleven .img {height: 430px;}
    .blazepro_section.one .img {height: 235px;}
    .inner_breadcum h4 {font-size: 12px;}
    .main_menu ul li a {padding: 0px 4px;}
    .container-fluid.container_ten p{font-size:9px;}
    .container_two ul li .text h2 {font-size: 10px;padding-right: 2px;}
    .blaze-octa-processor .one_detail_content h3 {font-size: 11px;}
}





.spe_show_more_btn {
    text-align: center;
    margin: 30px 0px;
}
.spe_show_more_btn a:hover{background: #ea1957;
    border-color: #ea1957;
    color: #fff !important;}
.spe_show_more_btn a {
    display: block;width: 135px;
    font-weight: 500;padding: 5px;
    width: 135px;cursor: pointer;
    margin: 0 auto;
        text-transform: capitalize;
    font-size: 16px;
    border: 1px solid #000;
    color: #000;
    border-radius: 50px;
}

.spec_banner img{width: 100%;}
.specification_breadcum .full_container .right a:first-child {color: #000 !important;} 
.specification_breadcum .specification a:last-child {color: #ed1556 !important}

.table th, .table td{border-top: none;}
.summery thead th{border: none !important;text-align: center;padding: 5px;font-size: 22px;width: 33.33%;}
.summery tbody tr td{    width: 33.33%;border: none !important;text-align: center;font-size: 22px;padding: 5px !important;}
.table-striped tbody tr:nth-of-type(odd) {background-color: #fff !important;}
.spec_page .table-responsive .table td, .table th{padding: 50px 0px;border-bottom: 1px solid #212529;border-top: none !important;}
.table-responsive{width: 90%;margin: 0 auto;}
.spec_page .table-responsive .table td.spectification_title{width: 30%;padding-left: 0%;}
.spect-width{width: 85% !important;margin: 0 auto;}
.carousel.carousel-slider .control-arrow:hover {background: none !important;}
.product-search{padding: 30px;    margin-top: 58px;}
.product-search h1{margin-bottom: 0;font-size: 22px;font-weight: 600;text-align: center;    color: #ea1957;
    font-family: 'siri_semi';}

.enjoylist{width: 85%;
    margin: 0 auto;}
.enjoylist ul{overflow: hidden;}
.enjoylist ul li{width: 16.6%;
    float: left;
    padding: 0px 10px;
    text-align: center;}
.enjoylist ul li .en_list img{width: 50px;height: 50px;object-fit: contain;}
.enjoylist ul li .en_list label{ font-size: 16px;   display: block;
    color: #fff;line-height: 12px;
    margin-top: 10px;
    margin-bottom: 0;}
.enjoylist ul li .en_list span{color: #d5e3d2;
    font-size: 14px;}
    .slick-prev, .slick-next{display: none !important;}
    .mohit{padding:10px}