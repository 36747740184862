/* Magnific Popup CSS */
.mfp-bg {
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: hidden;
  position: fixed;
  background: rgba(0,0,0,0.85);
  opacity: 1;
  left: 0;
  right: 0;
  top: 0;
  
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-content p {
	line-height: 20px;
}

.mfp-wrap {
  top: 0;
  right: 0;
  margin-left: 70px;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 0;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }



.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; 
}

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #cccccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 0; 
}
  .mfp-preloader a {
    color: #cccccc; }
    .mfp-preloader a:hover {
      color: white; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; 
  
}
  
button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 1;
  filter: alpha(opacity=100);
  padding: 0 0 18px 10px;
  color: white;
  font-style: normal;
  font-size: 28px;
}
  .mfp-close:hover, .mfp-close:focus {
  opacity: 0.65;
  filter: alpha(opacity=65); }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close,.mfp-inline-holder .mfp-close  {
  color: #fff;
  right: 0px;
  text-align: center; }

.mfp-inline-holder .mfp-close  {
	color: #fff;
	top: 0px;
	right: 0px;
	background-color: #0193df;
	font-size: 40px;
}

.mfp-image-holder .mfp-close {
	top: 40px;
	background: #da251c;
	color: #fff;
}

.mfp-image-holder .mfp-close:hover {
	background: #ac130b; opacity:1;
}

.mfp-counter {
	/*background: url(../img/photo-icon-2.png) no-repeat 0 2px;*/
	padding-left: 20px;
	position: absolute;
	top: 5px;
	right: 10px;
	color: #cccccc;
	font-size: 12px;
	line-height: 18px;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }

  .mfp-arrow:after, .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before, .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; 
  /* background: url(../images/previous_btn.png) no-repeat center right !important; background-size:25px; */
  }
  .mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
    }
  

.mfp-arrow-right {
  right: 0; 
  /* background: url(../images/next_btn.png) no-repeat center left !important;background-size:25px; */
  }
.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
	
}
  

.mfp-arrow-right:before {
	
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 863px; }
  .mfp-iframe-holder .mfp-close {
    top: -45px;
	background: #0193df;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 42.5%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: black; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444444; }
  .mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #f3f3f3;
	word-wrap: break-word;
	padding-left: 10px;
	margin-top: 5px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
	  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-ie7 .mfp-img {
  padding: 0; }
.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }
.mfp-ie7 .mfp-container {
  padding: 0; }
.mfp-ie7 .mfp-content {
  padding-top: 44px; }
.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

  .mfp-inline-holder .mfp-content {
    width: 100%;
    max-width: 550px; 
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow:    0px 0px 15px 0px rgba(0, 0, 0, 0.4);
    box-shadow:         0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  }

.mfp-inline-holder .mfp-close button{
  color: #000;
}

.mfp-content video{
  width: 100%;
}
.tj-mp-action{
  display: block;
  position: absolute;
  top:50%;
  margin-top:-60px;
  z-index: 2000;
  padding: 40px;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}

.tj-mp-action i{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}
.tj-mp-action:hover i,
.tj-mp-action:focus i,
.tj-mp-close:hover i,
.tj-mp-close:focus i{
  background-color:#dc971f;
  color:#272822;
  border: 1px solid #dc971f;
}
.tj-mp-arrow-right{
  right: 15px;
  padding-right: 0px;
}
.tj-mp-arrow-left{
  left: 15px;
  padding-left: 0px;
}


/* overlay at start */
.mfp-fade.mfp-bg {
  width:0;
  opacity: 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.9;
  width: 100%;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
  width: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  left: 0;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
  left:-50px;
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0; left:-50px;}
    100% {opacity: 1; left:0;}
}

@-moz-keyframes fadeIn {
    0% {opacity: 0; left:-50px;}
    100% {opacity: 1; left:0;}
}

@-o-keyframes fadeIn {
    0% {opacity: 0; left:-50px;}
    100% {opacity: 1; left:0;}
}

@keyframes fadeIn {
    0% {opacity: 0; left:-50px;}
    100% {opacity: 1; left:0;}
}

.mfp-figure,.mpf-iframe{
  position: relative;
}
.mfp-open .mfp-figure,
.mfp-open .mfp-iframe {
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: .4s;
    -moz-animation-duration: .4s;
    -ms-animation-duration: .4s;
    -o-animation-duration: .4s;
    animation-duration: .4s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
}

.mfp-iframe-scaler .mfp-counter{
  position: absolute;
  top:auto;
}
.mfp-iframe-scaler .mfp-title{
  position: absolute;
}