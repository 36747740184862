* {
    padding: 0;
    margin: 0;
    outline: 0;
  }
  .flt {
    float: left;
  }
  .clr {
    clear: both;
  }
  ol,
  ul {
    margin: 0;
    padding: 0;
  }
  a {
    transition: 0.5s all ease;
    text-decoration: none !important;
  }
  a:hover {
    text-decoration: none;
    transition: 0.5s all ease;
  }
  a:focus {
    text-decoration: none;
    transition: 0.5s all ease;
  }
  ul li {
    list-style: none;
  }
  
  body,
  html {
    height: 100%;
    margin: 0;
    line-height: 24px;
    color: #000;
  }
  
 
  .pr0px {
    padding-right: 0px;
  }
  .pl0 {
    padding-left: 0px;
  }
  .pl0px {
    padding-left: 0px;
  }
  .pt0px {
    padding-top: 0px;
  }
  .pb0px {
    padding-bottom: 0px;
  }
  .pt5px {
    padding-top: 5px !important;
  }
  .pt10px {
    padding-top: 10px !important;
  }
  .pt15px {
    padding-top: 15px !important;
  }
  .pt20px {
    padding-top: 20px !important;
  }
  .pt25px {
    padding-top: 25px !important;
  }
  .pt22px {
    padding-top: 22px !important;
  }
  .prl5 {
    padding-right: 5px;
    padding-left: 5px;
  }
  .pr5px {
    padding-right: 5px;
  }
  .pl5px {
    padding-left: 5px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt25 {
    margin-top: 25px;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .text-uper {
    text-transform: uppercase !important;
  }
  .center {
    text-align: center;
  }
  .mt0px {
    margin-top: 0px !important;
  }
  .mb0px {
    margin-bottom: 0px !important;
  }
  .row5 {
    margin-right: -5px;
    margin-left: -5px;
  }
  .pl0 {
    padding-left: 0;
  }
  .pr0 {
    padding-left: 0;
  }
  .pl5 {
    padding-left: 5px;
  }
  .pr5 {
    padding-right: 5px;
  }
  .p0 {
    padding: 0;
  }
  section {
    padding: 30px 0;
  }
  .mt50 {
    margin-top: 50px !important;
  }
  .mb50 {
    margin-bottom: 50px !important;
  }
  
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 26px;
  }
  h5 {
    font-size: 24px;
  }
  h6 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
  /* .container{max-width: 1208px;} */
  .home_screen section {
    padding: 50px 0;
  }
  img {
    max-width: 100%;
  }
  
  .top_header {
    background: #0058a5;
    color: #fff;
    padding: 8px;
  }
  
  .top_header a {
    color: #fff;
  }
  
  .cart_bttons li {
    display: inline-block;
    position: relative;
  }
  
  .menu_in {
    padding: 0px 0;
  }
  .menu_in .row{align-items: center;}
  .head_search input.form-control {
    padding: 7px 20px;
    box-sizing: border-box;
    outline: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    height:42px;
    background: #f3f3f3;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }
  
  .head_search select#inputGroupSelect02 {
    padding: 4px 20px;
    border-radius: 0;font-weight: 500;
    box-sizing: content-box;
    /* border: none; */color: #939393;
    border: 1px solid #ced4da;
  }
  
  .head_search button.btn {
    border: 1px solid #ced4da;
    border-left: 0;
    background: #f3f3f3;
    /* font-size: 32px; */
    /* justify-content: center; */
  }
  
  .cart_bttons {
    text-align: right;
  }
  .cart_bttons ul{margin-bottom: 0;}
  .cart_bttons li {
    display: inline-block;
    margin: 0 10px;
  }
  
  .head_search {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  
  .main_categories li {
    display: inline-block;
    padding-right: 25px;padding-top: 5px;padding-bottom: 5px;
  }
  
  .main_categories li a {
    /* display: flex; */
    color: #000;
    vertical-align: middle;
    align-items: center;
  }
  .main_categories li:hover a .cate_name{color: #0058a5;}
  ..main_categories li:hover a .cat_iamge{box-shadow: rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px;}
  .main_categories li a .cat_iamge {
    width:60px;
    height:60px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
    margin: auto;
  }
  .menu_list::before{    border-bottom: 10px solid #0058a5;
    content: ' ';
    width: 0;
    height: 0;
    right:27px;
    position: absolute;
    top: -13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;}
  .fixed_menu_icons:hover .menu_list{display: block;}
  .menu_list{position: absolute;border-top: 3px solid #0058a5;display: none;
    background: #fff;transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    min-width: 190px;
    right: -25px;
    top:35px;
    padding: 15px; box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
    border-radius: 5px;
    z-index: 999;}
  .menu_list ul{padding-left: 0px;}
  .menu_list ul li{    margin: 0;
    display: block;
    text-align: left;}
  .menu_list ul li a{letter-spacing: .5px;
    font-size: 14px;color: #000;
    font-weight: 500;}
  .menu_list ul li a:hover{color: #0058a5;}
  .main_categories li a .cate_name {
    width: 100px;
    /* text-align: left; */
    line-height: 120%;
    padding-left: 0px;    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-top: 0px;
    display: inline-block;
  }
  
  .main_categories {
    box-shadow: 0px 4px 5px #cccccc47;
    padding: 0px 0;transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    border-top: 1px solid rgb(204 204 204 / 28%);
    position: relative;
  }
  
  .head_search select#inputGroupSelect02:focus {
    outline: none;
    /* border: navajowhite; */
    box-shadow: none;
    border-color: #ccc;
  }
  
  .more_categoryes a i.fa {
    width: 20px;
    height: 20px;
    background: #0058a5;
    text-align: center;
    color: #fff;
    font-size: 11px;
    vertical-align: middle;
    padding: 5px;
    border-radius: 20px;
    margin-left: 5px;
  }
  
  .more_categoryes a {
    color: #0058a5 !important;
    transition: ease 0.5s all;
  }
  
  .more_categoryes a:hover i {
    transform: rotate(180deg);
    transition: ease 0.5s all;
  }
  
  .more_categoryes {
    padding: 10px 0px;
  }
  
  .owl-dots {
    text-align: center;
    padding: 10px 0;
  }
  
  .owl-dot {
    width: 10px;
    height: 10px;
    background: #6a6a6a;
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
  }
  
  .owl-dot.active {
    background: #0058a5;
  }
  
  .banner {
    padding-top:0px;
    padding-bottom: 00px;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  
  .Latest .slice-container {
    width: 98% !important;
  }
  
  .Latest .slice-container img {
    border-radius: 0.75rem;
  }
  
  .spotlight .slice-container {
    width: 80% !important;
  }
  
  .spotlight .slice-container .img-container {
    border-radius: 7px !important;
  }
  
  .spotlight .slice-container img {
    border-radius: 0.75rem;
  }
  
  .banner .slice-container {
    width: 98% !important;
  }
  
  .banner .slice-container img {
    border-radius: 0.75rem;
  }
  
  .banner img.wd100 {
    border-radius: 10px;
  }
  
  .headShake .slice-container {
    width: 98% !important;
  }
  
  .headShake .slice-container img {
    border-radius: 0.75rem;
  }
  
 
  
  .rquest_one h3 {
    font-size: 16px;
    margin-top: 10px;
    font-weight: normal;
    color: #fff;
  }
  
  
 
  
  .one_produuct {
    text-align: center;margin: 0px 5px;
    border: 1px solid #e3e7ef;
    border-radius: 10px 10px 0 0;
  }
  .one_produuct .produ_img {
    max-width: 80%;
    margin: 0 auto;
    padding: 11px;
    min-height: 190px;
  }
  
  .one_product_content h3 {
    font-size: 20px;
  }
  
  .one_product_content h5 {
    font-size: 15px;
    min-height: 38px;
    color: #414141;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .one_product_content h6 {
    font-weight: bold;
    /* padding-left: 15px; */
  }
  
  .one_product_content h6 span.mrp_one_prod {
    color: #8c8c8c;
    margin-right: 5px;
    font-weight: normal;
    font-size: 15px;
  }
  
  span.through_line {
    text-decoration: line-through;
  }
  
  .one_product_content h3 a {
    color: #0058a5;
  }
  
  .one_product_cart_btns {
    background: #0058a5;
    display: flex;
  }
  
  .one_product_cart_btns button.add_to_cart {
    width: 100%;
    border: none;
    text-align: center;
    background: none;
    color: #fff;
    text-align: center;
    border-left: 1px solid #fff;
    vertical-align: middle;
  }
  
  button.fvrt {
    background: none;
    color: #fff;
    border: none;
    padding: 8px 10px;
  }
  
  .fvrt img {
    margin: 0;
  }
  
  .one_product_cart_btns button:focus {
    outline: none;
  }
  
  .one_product_cart_btns button:hover {
    background: #273d51;
  }
  
  .add_to_cart:hover {
    background: #f03e54 !important;
  }
  
  .one_product_cart_btns button.add_to_cart {
    width: 100%;
    border: none;
    text-align: center;
    background: none;
    color: #fff;
    text-align: center;
    border-left: 1px solid #fff;
    vertical-align: middle;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    padding: 8px 10px;
  }
  
  .one_product_cart_btns button.add_to_cart img {
    width: 20px;
    vertical-align: middle;
    float: left;
    margin-left: 10px;
    padding-top: 5px;
  }
  
  .owl-nav {
    position: absolute;
    top: 35%;
    width: 100%;
  }
  .owl-nav .owl-prev,
  .owl-nav .owl-next {
    font-size: 0;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    color: #fff;
    box-shadow: 0px 0px 5px #ccc;
    /* text-align: center; */
    padding: 14px;
    position: absolute;
    left: -20px;
  }
  
  .owl-nav .owl-next {
    right: -20px;
    left: initial;
  }
  
  .owl-nav .owl-next:before {
    content: "";
    content: "\f178";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    color: #000;
  }
  
  .owl-nav .owl-prev:before {
    content: "";
    content: "\f177";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    color: #000;
  }
  
  h3.title {
    font-size: 30px;
    margin-bottom: 30px;
  }
  
  .home_screen section {
    padding: 50px 0;
  }
  .owl-prev.disabled,
  .owl-next.disabled {
    display: none;
  }
  
  .oneshipping {
    font-weight: bold;
    text-align: center;
  }
  
  .sync .item {
    margin: 5px;
    margin-top: 10px;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
  }
  
  .sync .synced .item {
  }
  
  .thumbail_img {
    border: 1px solid #cbcbcb;
    height: 92px;
    width: 144px;
    padding: 18px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 7px;
    margin: 0px auto;
    margin-bottom: 10px;
  }
  .thumbail_img img {
    width: initial !important;
    max-width: initial !important;
    height: 100%;
    margin: 0 auto;
  }
  
  .thumbnail h6 {
    font-size: 16px;
    padding: 0 10px;
    color: #000;
    font-weight: normal;
  }
  
  .thumbail_img span {
    overflow: hidden;
    display: inline-block;
    width: 100%;
    height: 57px;
  }
  
  #sync4 .owl-nav {
    top: 19%;
  }
  
  div#sync3 {
    margin-bottom: 35px;
  }
  .view_all a:hover{background: #0058a5;color: #fff;border-color: #0058a5;}
  .view_all a {
    display: inline-block;
    width: 30%;
    border: 1px solid #0058a5;
    padding: 12px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #0058a5;
    font-weight: 600;
  }
  
  .hvr-float-shadow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  
  .hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  
  .hvr-float-shadow:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
  }
  .hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
    opacity: 1;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  
  .view_all {
    margin: 30px 0;
    text-align: center;
  }
  
  .one_produuct_1 {
    border-radius: 10px;
    overflow: hidden;
  }
  
  /* .replay_small_section{
    padding: 40px 0;
  } */
  
  .replay_small_section .owl-nav {
    max-width: 1150px;
    margin: 0 auto;
    left: initial;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  
  section.replay_small_section {
    background: #f2f4f6;
  }
  
  .one_produuct_2 {
    text-align: center;
  }
  
  .spacial_1_img {
    overflow: hidden;
  }
  
  .spacial_1_img img {
    margin: auto;
    border-radius: 10px;
  }
  
  .one_produuct_2 h6 {
    font-size: 18px;
    color: #000;
    margin: 10px 0;
  }
  
  .why_us {
    text-align: center;
    background: #e0eef1;
    padding: 70px;
    border-radius: 15px;
  }
  
  .why_us p {
    width: 80%;
    margin: 20px auto;
    font-size: 18px;
    line-height: 150%;
  }
  
  .why_us img {
    margin: 20px 0;
  }
  
  .tab_liines .col-md-3:nth-child(1) .oneshipping {
    text-align: left;
  }
  .tab_liines .col-md-3:nth-child(2) .oneshipping {
  }
  .tab_liines .col-md-3:nth-child(3) .oneshipping {
    text-align: right;
  }
  .tab_liines .col-md-3:nth-child(4) .oneshipping {
    text-align: right;
  }
  
  footer {
    /* border-top: 25px solid #ccc; */
    border-top: 10px solid #f2f4f6;
    padding: 90px 0 0;
  }
  
  .contact_ss li {
    padding-left: 23px;
    margin-bottom: 20px;
  }
  
  footer h3 {
    font-size: 21px;
    text-transform: uppercase;
    color: #0058a5;
    margin-bottom: 15px;
  }
  
  footer p {
    color: #3a3a3a;
  }
  
  .scrip_news form {
    position: relative;
  }
  
  .scrip_news input[type="email"] {
    padding: 15px 31px;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;
    padding-left: 50px;
  }
  
  .scrip_news img {
    position: absolute;
    left: 15px;
    top: 20px;
  }
  
  .scrip_news form button {
    width: 100%;
    padding: 15px;
    margin-top: 11px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
    border: none;
    background: #165dad;
    color: #fff;
  }
  
  .footermenu h6 {
    font-size: 17px;
  }
  .footermenu ul{padding-left: 0px;}
  ul.contact_ss {
    padding-top: 6px;padding-left: 0px;
  }
  
  .contact_ss a {
    color: #3a3a3a;
    margin-bottom: 8px;
    display: block;
  }
  
  .contact_ss a img {
    margin-right: 7px;
  }
  
  .footermenu li a {
    color: #3a3a3a;
    margin-bottom: 8px;
    display: block;
  }
  
  .social_lists li a {
    display: block;
    border: 1px solid #212121;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    color: #212121;
    padding: 5px;
    margin: 0 5px;
  }
  
  .social_lists {
    border-top: 1px solid #ccc;
    padding: 20px;
    margin-top:50px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: baseline;
  }
  
  .social_lists li {
    display: inline-block;
  }
  
  .social_lists p {
    /* display: flex; */
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 15px;
  }
  
  .copyrights {
    background: #165dad;
    color: #fff;
    padding: 27px 0;
  }
  
  .copyrights p {
    font-size: 18px;text-align: center;
    color: #fff;
  }
  
  .footerlogo img {
    filter: brightness(0) invert(1);
  }
  
  .copyrights p {
    font-size: 17px;
    color: #fff;
    padding-top: 15px;
  }
  
  .cart_bttons span.pro-count.blue {
    position: absolute;
    right: -11px;
    top: -5px;
    color: #ffffff;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    background-color: #ff5b5b;
  }
  .cart-dropdown-wrap::before{border-bottom: 10px solid #0058a5;
    content: ' ';
    width: 0;
    height: 0;
    right: 27px;
    position: absolute;
    top: -13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;}
  .cart-dropdown-wrap {
    position: absolute;
    right: 0;margin-right: -25px;
    top: calc(100% + 20px);
    z-index: 99;
    width: 320px;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
    box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
    padding: 30px 20px 27px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 10px;
  }
  
  .cart-dropdown-wrap.cart-dropdown-hm2 {
    right: 0;
  }
  
  .cart_bttons li:hover .cart-dropdown-wrap {
    border-top: 3px solid #0058a5;
    opacity: 1;
    visibility: visible;
    top: calc(100% + 15px);
  }
  
  .cart-dropdown-wrap ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 0 20px;
  }
  
  .cart-dropdown-wrap ul li .shopping-cart-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    margin-right: 20px;
  }
  
  .cart-dropdown-wrap ul li .shopping-cart-img a {
    display: block;text-align: left;
  }
  
  .cart-dropdown-wrap ul li .shopping-cart-img a img {
    max-width: 60px;
  }
  
  .cart-dropdown-wrap ul li .shopping-cart-title {
    margin: 6px 0 0;
  }
  
  .cart-dropdown-wrap ul li .shopping-cart-title h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin: 0 0 9px;
  }
  
  .cart-dropdown-wrap ul li .shopping-cart-title h4 a {
    color: #0058a5;
  }
  
  .cart-dropdown-wrap ul li .shopping-cart-delete {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 100;
    -ms-flex-positive: 100;
    flex-grow: 100;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 6px 0 0;
  }
  
  .cart-dropdown-wrap ul li .shopping-cart-delete a {
    font-size: 18px;
    color: #696969;
  }
  
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total {
    border-top: 2px solid #f3f3f3;
    margin: 25px 0;
    padding: 17px 0 0;
  }
  
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total h4 {
    color: #9b9b9b;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
  }
  
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total h4 span {
    font-size: 18px;margin-left: 20px;
    float: right;
    color: #0058a5;
  }
  .fixed_menu_icons i{font-size: 22px;cursor: pointer;}
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a {
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    border-radius: 4px;
    line-height: 1;
    padding: 10px 20px;
    background-color: #0058a5;
    font-family: "Quicksand", sans-serif;
    border: 2px solid #0058a5;
  }
  
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a.outline {
    background-color: transparent;
    border: 2px solid #0058a5;
    color: #0058a5;
  }
  
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a {
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    border-radius: 4px;
    line-height: 1;
    padding: 10px 20px;
    background-color: #0058a5;
    font-family: "Quicksand", sans-serif;
    border: 2px solid #0058a5;
  }
  
  .categories-dropdown-active-large {
    list-style-type: none;
    position: absolute;
    top: 90%;
    right: 9%;
    z-index: 99;
    margin: 0;
    padding: 30px;
    background: #fff;
    border: 1px solid #b9cddf;
    border-radius: 10px;
    font-size: 16px;
    min-width: 270px;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    visibility: hidden;
    margin-top: 26px;
  }
  
  .header-style-1 .categories-dropdown-active-large {
    top: 100%;
    -webkit-box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
    box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  }
  
  .categories-dropdown-active-large.open {
    opacity: 1;
    visibility: visible;
  }
  
  .main-categori-wrap .categori-dropdown-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 412px;
  }
  
  .categories-dropdown-wrap ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 48px;
    border-radius: 5px;
    border: 1px solid #f2f3f4;
    padding: 9px 18px;
    margin: 0 15px 15px 0;
    height: 50px;
    transition: 0.3s;
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
  }
  
  .categories-dropdown-wrap ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    line-height: 1.5;
    color: #253d4e;
    font-size: 16px;
  }
  
  .categories-dropdown-wrap ul li a img {
    max-width: 30px;
    margin-right: 15px;
    border-radius: 50%;
  }
  
  .more_categoryes a {
    color: #0058a5;
    cursor: pointer;
  }
  
  .head_search button.btn img {
    width: 24px;
  }
  
  .head_search button.btn {
    padding: 0 11px;
    width: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .main_categories ul.main_list {
    display: flex;margin-bottom: 0px;padding-left: 0px;
  }
  .request_quotes {
    padding: 50px 0;
  }
  .pro_listing{border-bottom: none;}
  .copyrights p a {
    color: #fff;
  }
  
  .one_sposrtLine {
    margin-bottom: 40px;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
  }
  
  .one_sposrtLine img {
    border-radius: 10px;
  }
  
  .one_reply_list {
    display: none;
  }
  
  .one_reply_list:first-child {
    display: block;
  }
  
  .main_categories li a:hover {
    color: #0058a5;
  }
  
  .footermenu li a:hover {
    color: #fff;
    color: #0058a5;
  }
  
  .one_produuct:hover .produ_img img {
    transform: scale(1.1);
  
    transition: 0.3s ease all;
  }
  
  .one_produuct .produ_img img {
    transition: 0.3s ease all;
    margin: auto;min-height: 150px;
    object-fit: cover;
  }
  .cart_bttons li:hover .cart-dropdown-wrap ul{padding-left: 0px;}
  .why_section{    border: none;}
  .shiping-section{border: none;}
  .trading_section{padding-bottom: 80px;border-top: none;}
  .latest_section{padding-bottom: 80px;    border-top: none;}
  .tranding_products{
    padding: 0px 0;
  }
  
  .tranding_products .slick-dots li.slick-active button:before{
    color: #0058a5;
  }
  
  
  
  
  .tranding_products .slick-dots li button:before{
    font-size: 10px;
    opacity: 1 !important;
    color: #6a6a6a;
  }
  
  .tranding_products .slick-dots li button:hover::before{
    color: #6a6a6a
  }
  
  #tranding_products1 .owl-carousel .item:hover img,
  .one_reply_list .owl-carousel .item:hover img,
  #tranding_products1 .owl-carousel .item:hover img {
    transform: scale(1.1);
    transition: 0.3s ease all;
  }
  
  .owl-carousel .item img {
    transition: 0.3s ease all;
  }
  
  .rquest_one {
    width: 100%;
  }
  
  .footerfollow {
    text-align: center;
    display: flex;
    justify-content: center;
    vertical-align: middle;
  }
  
  .footerfollow p {
    margin-top: 8px;
  }
  
  .breadkcrums li a {
    color: #848484;
  }
  .side_bar_search form{    display: flex;
    align-items: center;
    justify-content: space-between;}
  .breadkcrums li {
    margin-right: 13px;
  }
  
  .breadkcrums li:after {
    content: "\f105";
    font-family: "FontAwesome";
    padding-left: 13px;
  }
  
  .breadkcrums li:last-child:after {
    display: none;
  }
  
  .breadkcrums ul {
    display: flex;padding-left: 0px;
    margin-bottom: 20px;
  }
  
  .list_side_bar {
    width: 100%;
  }
  
  .widget_side_bar {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding: 8px 15px;
    margin-bottom: 10px;
  }
  .one_check_out label{font-size: 15px;    display: flex;
    align-items: center;}
  .widget_side_bar h3 {
    font-size:18px;
    margin-bottom:8px;
  }
  
  .widget_side_bar .one_check_out input[type="checkbox"] {
    font-size: 16px;
    margin-right: 10px;
    width: 20px;
    height: 17px;
    vertical-align: middle;
  }
  
  .side_bar_search input[type="search"] {
    border: none;
  }
  
  .side_bar_search input[type="submit"] {
    border: none;
    background: no-repeat;
  }
  
  .side_bar_search button {
    border: none;
    background: no-repeat;
    font-size: 20px;
    float: right;
    font-size: 23px;
    color: #070707;
  }
  
  .widget_side_bar {
    width: 100%;
  }
  
  .price-range-slider {
    width: 100%;
    float: left;
  }
  .price-range-slider .range-value {
    margin: 0;
  }
  .price-range-slider .range-value input {
    width: 100%;
    background: none;
    color: #000;
    font-size: 16px;
    font-weight: initial;
    box-shadow: none;
    border: none;
    margin: 0 0 20px 0;
  }
  .price-range-slider .range-bar {
    border: none;
    background: #000;
    height: 3px;
    width: 96%;
    margin-left: 8px;
  }
  .price-range-slider .range-bar .ui-slider-range {
    background: #0058a5;
    height: 6px;
    top: -3px;
  }
  .price-range-slider .range-bar .ui-slider-handle {
    border: none;
    border-radius: 25px;
    background: 0058a5;
    border: 2px solid #0058a5;
    height: 17px;
    width: 17px;
    top: -0.52em;
    cursor: pointer;
  }
  .price-range-slider .range-bar .ui-slider-handle + span {
    background: #0058a5;
  }
  
  /*--- /.price-range-slider ---*/
  
  .price-range-slider_section .ui-widget.ui-widget-content {
    border: 3px solid #c5c5c5;
  }
  
  .checklisting {
    max-height: 239px;
    overflow: auto;
    padding-bottom: 20px;
  }
  
  .product_listing_main_1 {
    width: 100%;
  }
  
  #cat_lider .item {
    padding: 20px 0 0;
    margin-right: 8px;
  }
  .listing_section{border: none;}
  .side_category_slider {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  
  .side_category_slider::-webkit-scrollbar {
    display: none;
  }
  .onelisder:hover{background: #054896;color: #fff;}
  .onelisder {
    background: #d7eefc;
    border: 1px solid #e3e3e3;
    border-radius: 50px;
    display: flex;
    padding: 15px 15px;
    position: relative;
    min-width: 170px;
  }
  
  .onelisder h5 {
    font-size: 14px;
    margin: 0;
    padding: 0 0px;
    text-align: center;
    width: 100%;
    display: block;
    text-transform: uppercase;
    vertical-align: middle;
  }
  
  .onelisder .produ_img_barside {
    position: absolute;
    right: 9px;
    width: 39px;display: none;
    top: -12px;
  }
  
  .onelisder.all_items {
    background: #054896;
    color: #fff;
    text-align: center;
    display: block;
  }
  
  div#cat_lider {
    margin-top: -20px;
  }
  
  #cat_lider .owl-nav {
    top: 25px;
    right: -36px;
  }
  
  #cat_lider .owl-nav .owl-prev {
    display: none;
  }
  
  div#cat_lider {
    margin-top: -20px;
    width: 100%;
  }
  
  label.select-inline {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }
  
  label.select-inline label {
    padding-right: 10px;
  }
  
  .short_by_section {
    margin: 50px 0px;
  }
  
  .form-control:focus {
    border-color: #ced4da;
    outline: none;
    box-shadow: none;
  }
  
  .result_mains {
    text-align: right;
  }
  
  .listing_products .one_produuct {
    width: 31%;
    float: left;
    margin-right: 3%;
    margin-bottom: 33px;
  }
  
  .listing_products .one_produuct:nth-child(3n) {
  }
  
  .listing_products .one_produuct:nth-child(3n) {
    margin-right: 0;
  }
  
  .one_pricelist {
    display: flex;
    padding: 5px 14px;
  }
  
  .price_lavel {
    width: 32%;
  }
  
  .price_lavel {
    width: 32%;
    text-align: left;
    line-height: 1;
    font-weight: 500;
    font-size: 14px;
    padding: 8px 0;
    color: #0058a5;
  }
  
  .price_selection {
    width: 68%;
    text-align: left;
    position: relative;
  }
  
  .price_selection select {
    width: 100%;
    padding: 8px 6px;
    border: 1px solid #ccc;
    font-size: 13px;
    padding-left: 43px;
  }
  
  .price_list_of_listingPage {
    margin-bottom: 15px;
  }
  
  .price_selection span.price_pl {
    position: absolute;
    top: 7px;
    left: 9px;
    background: #fff;
    font-size: 13px;
  }
  
  .price_selection.qtnupra_bar button.increse_btn,
  .price_selection.qtnupra_bar button.descr_btn {
    position: absolute;
    background: none;
    border: none;
    left: 0;
    top: 0;
    width: 30px;
    padding: 4px 2px;
  }
  
  .price_selection.qtnupra_bar button.descr_btn {
    right: 0;
    left: inherit;
  }
  
  .price_selection input.qtnproc {
    width: 100%;
  }
  
  .price_selection.qtnupra_bar button.increse_btn:focus,
  .price_selection.qtnupra_bar button.descr_btn:focus {
    outline: none;
  }
  
  .price_selection input.qtnproc {
    width: 100%;
    padding: 4px 30px;
    text-align: center;
    border: 1px solid #ccc;
  }
  
  .one_one_listing_page button.view_details_bttn {
    width: 100%;
    border: none;
    text-align: center;
    background: none;
    color: #fff;
    text-align: center;
    border-left: 1px solid #fff;
    vertical-align: middle;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    padding: 8px 10px;
  }
  
  .one_one_listing_page button.view_details_bttn:hover {
    background: #f03e54 !important;
  }
  
  .inner_banner {
    position: relative;
  }
  .inner_banner .banner_content {
    position: absolute;
    bottom: 48px;
    left: 60px;
    color: #fff;
  }
  
  .inner_banner .banner_content h3 {
    font-size: 36px;
    text-transform: uppercase;
  }
  
  .new_listing_page .main_categories li a {
    display: inline-block;
  }
  
  .new_listing_page .main_categories li a .cat_iamge {
    width: 65px;
    height: 65px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
    margin: 0 auto 5px;
  }
  
  .new_listing_page .main_categories li a .cat_iamge img {
    max-width: 101%;
    width: 101%;
  }
  
  .new_listing_page .main_categories li a .cate_name {
    text-align: center;
  }
  
  .new_listing_page .main_categories li a .cat_iamge:hover {
    box-shadow: 0px 0 11px #9b9b9b;
    border: 1px solid #0058a5;
    transition: all ease 0.5s;
  }
  
  .new_listing_page .more_categoryes {
    padding: 32px 0px;
  }
  
  .new_listing_page .short_by_section label {
    padding-top: 0;
    margin-bottom: 0 !important;
  }
  .new_listing_page .result_mains {
    text-align: right;
    font-size: 14px;
  }
  .new_listing_page .short_by_section select.form-control {
    border-radius: 5px;
    outline: none;
    box-shadow: none;
  }
  
  .new_listing_page .one_produuct.one_one_listing_page {
    position: relative;
  }
  .new_listing_page .download_icons_top {
    position: absolute;
    width: 100%;
    top: 7px;
  }
  .new_listing_page .download_icons_top a {
    display: inline-block;
    width: 46%;
    text-align: left;
  }
  .new_listing_page .download_icons_top a:nth-child(2) {
    text-align: right;
  }
  .new_listing_page .price_selection select {
    padding-left: 70px;
  }
  
  .one_product_content h6 {
    font-size: 16px;
  }
  
  .one_product_content h5 {
    font-size: 15px;
    color: #414141;
  }
  .one_product_content p.brand_name {
    color: #0058a5;
  }
  
  .new_listing_page .price_selection input.qtnproc {
    text-align: left;
  }
  
  .new_listing_page .price_selection select,
  .price_selection input.qtnproc {
    border-radius: 5px;
    border-color: #dbdbdb;
  }
  .new_listing_page .price_selection span.price_pl {
    position: absolute;
    left: 9px;
    background: #fff;
    font-size: 13px;
    border-right: 1px solid #ccc;
    padding-right: 6px;
    line-height: 1.2;
    display: inline-block;
    padding: 3px 0;
    padding-right: 10px;
    z-index: 99;
  }
  
  .new_listing_page .price_selection select {
    appearance: none;
    position: relative;
  }
  .new_listing_page .price_selection:after {
    content: "";
    content: "";
    position: absolute;
    right: 11px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #0058a5 transparent transparent transparent;
    top: calc(50% - 4px);
  }
  
  .new_listing_page .price_selection.qtnupra_bar:after {
    display: none;
  }
  
  .new_listing_page .one_product_content h3 a {
    display: inline-block;
    position: relative;
    padding: 0 33px;
  }
  
  .new_listing_page .one_product_content h3 a:before {
    content: "";
    width: 24px;
    height: 24px;
    border: 1px solid #0058a5;
    position: absolute;
    right: 5px;
    border-radius: 5px;
  }
  
  .new_listing_page .one_product_content h3 a:after {
    content: "";
    content: "";
    position: absolute;
    right: 11px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #0058a5 transparent transparent transparent;
    top: calc(50% - 3px);
  }
  .new_listing_page .main_categories li {
    padding-right: 26px;
  }
  .new_listing_page .side_bar_search input[type="search"] {
    border: none;
    width: 80%;
  }
  h3.type_select {
    display: inline-block;
    position: relative;
  }
  
  h3.type_select select {
    font-size: 20px;
    color: #0058a5;
    border: none;
    font-weight: 600;
    appearance: none;
    padding: 0 33px;
    z-index: 99;
    position: relative;
    background: none;
  }
  
  h3.type_select:before {
    content: "";
    content: "";
    width: 24px;
    height: 24px;
    border: 1px solid #0058a5;
    position: absolute;
    right: 5px;
    border-radius: 5px;
  }
  
  h3.type_select:after {
    content: "";
    content: "";
    position: absolute;
    right: 11px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #0058a5 transparent transparent transparent;
    top: calc(50% - 3px);
  }
  
  h3.type_select:before {
    content: "";
  }
  
  h3.type_select select {
    font-size: 20px;
    color: #0058a5;
    border: none;
    font-weight: 600;
    appearance: none;
  }
  
  .slick-dots{
    bottom: -33px !important;
  }
  
  .slick-dots li {
    width: 10px !important;
    height: 10px !important;
    margin: 0px !important;
    margin-right: 5px !important;
  }
  
  
  
  .slick-dots li div {
    width: 10px !important;
    height: 10px !important;
    background: #6a6a6a;
    display: inline-block;
    margin: 0px !important;
    margin-right: 5px !important;
    border-radius: 50%;
  }
  
  .slick-dots .slick-active div {
    background: #0058a5;
  }
  
  .slick-next-arrow {
    font-size: 0;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    color: #fff;
    box-shadow: 0px 0px 5px #ccc;
    /* text-align: center; */
    padding: 14px;
    position: absolute;
    left: -20px;
  }
  
  .slick-previous-arrow {
    font-size: 0;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    color: #fff;
    box-shadow: 0px 0px 5px #ccc;
    /* text-align: center; */
    padding: 14px;
    position: absolute;
    left: -20px;
  }
  
  
  /* 08/02/2023 */
  
  
  .banner button.slick-arrow {
  display: none !important;
  }.slick-next:before, .slick-prev:before {
  color: #000 !important;
  font-family: auto !important;
  }.slick-next, .slick-prev {
  width: 40px !important;
  height: 40px;
  background: #fff !important;
  border-radius: 50%;
  color: #fff;
  box-shadow: 0px 0px 5px #ccc;
  z-index: 1;
  }.slick-next{
  right:0 !important;
  }.slick-prev{
  left:0 !important;
  }
  
  
  
  /* banner 1 */
  
  .banner{
    overflow:hidden;
    }.banner .slice-container img {
    width: 100%;
    }.banner button.slick-arrow {
    display: none !important;
    }
    .slick-next:before, .slick-prev:before {
    color: #000 !important;
    /* font-family:  */
    font: normal normal normal 14px/1 FontAwesome !important;
    }
    .slick-next, .slick-prev {
    width: 40px !important;
    height: 40px !important;
    background: #fff !important;
    border-radius: 50% !important;
    color: #fff;
    box-shadow: 0px 0px 5px #ccc;
    z-index: 1;
    display:flex !important;
    align-items:center !important;
    justify-content:center !important;
    }
    .slick-next:before{
    content: "\f178" !important;
    }
    .slick-prev:before{
    content: "\f177" !important;
    }
    .slick-next{
      right: -15px !important;
    }
    .slick-prev{
      left: -15px !important
    }
    .headShake .slick-next{
      right: 100px  !important;
    }
    .headShake .slick-prev{
      left: 85px  !important;
    }
    .spotlight .slick-next{
        top:60px !important;
        right:4px !important
    }
    .spotlight .slick-prev{
      top:60px !important
  }
  .main_head{box-shadow: 0px 4px 5px #cccccc47;    transition: all 0.25s ease 0s;}
  .cart_bttons li:last-child{visibility: hidden;}
  .main_head .cart_bttons li:last-child{visibility: visible;}
  .product_pricing a {text-align: center !important;}
  .product_pricing h4 { opacity: 0;display: none !important;}
  .quick_vi_pric{opacity:0}